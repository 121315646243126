import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiProgress,
  EuiSpacer,
} from "@elastic/eui";
import MMPage, { PageLink } from "components/layouts/page/page";
import txt from "helpers/text-helper";
import { adminNav } from "./admin";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import ConnectAPIHelper from "api/connect-api-helper";
import { useDispatch, useSelector } from "react-redux";
import { toastAdd } from "store/components/toast/toast";
import {
  AlertConfirmActionState,
  confirmAsk,
  confirmGet,
} from "store/components/alert/confirm";
import { LOCATION_EMPTY, Location } from "store/data/location/location";
import MMLocation from "./location";
import AuthenticationHelper from "helpers/authentication-helper";
import {
  INSURER_EMPTY,
  Insurer,
  UZOVI_CODE_EMPTY,
  UzoviCode,
} from "store/data/insurer/insurer";
import MMInsurer from "./insurer";
import MMAgency from "./agency";

function MMAdminAgency() {
  const api = new ConnectAPIHelper();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteRef] = useState("delete_referrer_" + uuid());
  const [buttonsToShow, setButtonsToShow] = useState<any[]>([]);
  const { agencyId } = useParams();
  const [agency, setAgency] = useState<UzoviCode | null>(null);

  const isNew: boolean = agencyId === "new";
  const [subPages, setSubPages] = useState<PageLink[]>([]);
  useEffect(() => {
    const getSubPages = async () => {
      setSubPages(await adminNav("/admin/insurers/agencies"));
    };

    getSubPages();
  }, []);

  useEffect(() => {
    const loadAgency = async () => {
      if (isNew) {
        setAgency(UZOVI_CODE_EMPTY);
      } else {
        const agencyIdParam: number = Number(agencyId);
        if (isNaN(agencyIdParam)) {
          navigate("/404");
        } else {
          const result = await api.getAdminUzoviCode(agencyIdParam);
          if (result.status === "OK" && result.result && result.result.id) {
            console.log("setting agency", result.result);
            setAgency(result.result);
          } else {
            navigate("/404");
          }
        }
      }
    };
    loadAgency();
  }, [agencyId]);

  const saveAgency = async (agency: UzoviCode) => {
    let result;
    if (agency.id) {
      result = await api.updateAdminUzoviCode(agency.id, agency);
    } else {
      result = await api.createAdminUzoviCode(agency);
    }
    if (result.status === "OK") {
      if (!agency.id) {
        navigate(`/admin/insurers/agencies/${result.result.uzovi_code_id}`);
      }

      dispatch(
        toastAdd(
          txt.get("generic.is_saved", txt.get("admin.insurers.agencies.name")),
          null,
          "success"
        )
      );
    } else {
      dispatch(
        toastAdd(
          result.message
            ? result.message
            : txt.get("admin.insurers.agencies.error_saving"),
          null,
          "danger"
        )
      );
    }
  };

  // const handleDelete = async () => {
  //   dispatch(
  //     confirmAsk(
  //       `${txt.uf("generic.delete_x", txt.get("admin.referrers.name"))}.`,
  //       txt.get("admin.referrers.delete_confirm"),
  //       deleteRef,
  //       null,
  //       null,
  //       `${txt.get("generic.yes")}, ${txt.lo(
  //         "generic.delete_x",
  //         txt.get("admin.referrers.name")
  //       )}`
  //     )
  //   );
  // };

  // const alertConfirm = useSelector(confirmGet);
  // useEffect(() => {
  //   if (
  //     alertConfirm.actionState === AlertConfirmActionState.Perform &&
  //     alertConfirm.actionKey === deleteRef
  //   ) {
  //     deleteLocation();
  //   }
  // }, [alertConfirm]);

  // const deleteLocation = async () => {
  //   if (referrerId && !isNaN(Number(referrerId))) {
  //     await api.deleteAdminReferrer(Number(referrerId));
  //     dispatch(
  //       toastAdd(
  //         txt.get("generic.is_deleted", txt.get("admin.referrers.name")),
  //         null,
  //         "success"
  //       )
  //     );
  //   }
  //   navigate("/admin/referrers");
  // };

  // useEffect(() => {
  //   const setInterfaceForPermissions = async () => {
  //     let buttons: any[] = [];
  //     if (
  //       !isNew &&
  //       (await AuthenticationHelper.hasPermission("referrers#delete_all"))
  //     ) {
  //       buttons.push(
  //         <EuiButton
  //           size="s"
  //           onClick={handleDelete}
  //           color="danger"
  //           iconType={"cross"}
  //         >
  //           {txt.uf("generic.delete_x", txt.get("admin.referrers.name"))}
  //         </EuiButton>
  //       );
  //     }

  //     setButtonsToShow(buttons);
  //   };
  //   setInterfaceForPermissions();
  // }, []);

  const buttons = () => {
    return buttonsToShow;
  };

  return (
    <MMPage
      title={`${txt.get("admin.page_title")} ${txt.lo(
        "admin.insurers.agencies.name"
      )}`}
      subTitle={`#${agencyId || txt.get("generic.new")}`}
      subPages={subPages}
      backTo="/admin/insurers/agencies"
      backToText={txt.uf(
        "generic.back_to",
        txt.get("admin.insurers.agencies.page_title")
      )}
      sideActions={buttons()}
    >
      <EuiFlexItem>
        <EuiSpacer />
        {agency ? (
          <EuiFlexGroup>
            <EuiFlexItem>
              <MMAgency handleSubmit={saveAgency} fields={{ agency: agency }} />
            </EuiFlexItem>
          </EuiFlexGroup>
        ) : (
          <EuiProgress size="s" color="accent" />
        )}
      </EuiFlexItem>
    </MMPage>
  );
}
export default MMAdminAgency;
