import StringHelper from "helpers/string-helper";
import txt from "helpers/text-helper";
import { Client } from "store/data/client/client";
import { indicationDescription } from "store/data/indication/indication";
import { Need, getNeed, getNeeds } from "store/data/need/need";
import {
  Order,
  OrderLine,
  orderLinesGroupedByConfection,
  orderLinesGroupedByHandFinger,
  orderLinesGroupedByType,
  orderLinesGroupedByUse,
} from "store/data/order/order";
import {
  genderedName,
  genderedPronoun,
} from "store/data/personal-details/personal-details";
import { productUseDescription, ProductUse } from "store/data/product/product";
import { handDescription } from "store/data/scan/scan";

const toTitle = (text: string) => `**${StringHelper.upfirst(text)}**`;

const toDescription = (text: string) => StringHelper.upfirst(text);

const toRationaleSection = (title: string, description: string) =>
  `${toTitle(title)}
${toDescription(description)}`;

const toNeedsList = (needs: Need[]) => {
  let list: string = "";
  for (let i = 0; i < needs.length; i++) {
    const need: Need = needs[i];
    if (need.answer === "true") {
      list += `${txt.get(`needs.questions.${need.question}`)}
`;
    } else if (need.answer && need.answer !== "false") {
      list += `${need.answer.replaceAll("\n", " ")}
`;
    }
  }
  list += `
  
`;

  return list;
};
const toNeedsSummary = (needs: Need[]) => {
  let summary: string = "";
  if (needs.length > 0) {
    summary += needs
      .map((need: Need, i: number) =>
        i === 0
          ? need.answer === "true"
            ? txt.lf(`needs.questions.${need.question}`)
            : StringHelper.lofirst(need.answer)
          : i < needs.length - 1
          ? `, ${
              need.answer === "true"
                ? txt.lf(`needs.questions.${need.question}`)
                : StringHelper.lofirst(need.answer)
            }`
          : ` ${txt.lo("orders.order.costing.rationale.and")} ${
              need.answer === "true"
                ? txt.lf(`needs.questions.${need.question}`)
                : StringHelper.lofirst(need.answer)
            }`
      )
      .join("");
    summary = summary.trim();
    summary += summary.endsWith(".") ? "" : ".";
  }
  return summary;
};
const toProductListDescriptions = (orderLines: OrderLine[]) => {
  let descriptions: string[] = [];
  let groupedByType: any = orderLinesGroupedByType(orderLines);
  let types: any[] = Object.keys(groupedByType);
  for (let i = 0; i < types.length; i++) {
    const type = types[i];
    let description: string =
      groupedByType[type].length > 1
        ? `${groupedByType[type].length}x ${type}`
        : `${txt.lo(
            "orders.order.costing.rationale.an"
          )} ${type} (${groupedByType[type]
            .map((line: OrderLine) =>
              handDescription(
                line.hand as string,
                "orders.order.costing.rationale"
              ).toLowerCase()
            )
            .filter(
              (value: string, index: number, array: string[]) =>
                array.indexOf(value) === index
            )
            .join(" & ")})`;
    if (types.length > 1 && i < types.length - 2) {
      description += `, `;
    } else if (types.length > 1 && i < types.length - 1) {
      description += ` ${txt.lo("orders.order.costing.rationale.and")}`;
    }
    descriptions.push(description);
  }
  return descriptions.join(" ");
};
const toRationaleProductDescription = (
  order: Order,
  client: string,
  pronoun: string
) => {
  let title: string = "";
  let productDescription: string = "";

  title += txt.get("orders.order.costing.rationale.product_description_title");

  let groupedByUse: any = orderLinesGroupedByUse(order.order_lines);
  let uses: any[] = Object.keys(groupedByUse);
  console.log(groupedByUse);

  if (uses.length === 0) {
    productDescription = txt.get("orders.order.product");
  } else if (uses.length > 0) {
    let descriptions: string = toProductListDescriptions(groupedByUse[uses[0]]);

    productDescription += `${txt.get(
      "orders.order.costing.rationale.product_description",
      client,
      txt.get(
        "orders.order.costing.rationale.product",
        descriptions,
        productUseDescription(
          uses[0] === "NONE" ? ProductUse.Day : uses[0],
          "orders.order.costing.rationale"
        ).toLowerCase()
      )
    )}.`;
  }
  if (uses.length > 1) {
    let descriptions: string = toProductListDescriptions(groupedByUse[uses[1]]);

    productDescription += ` ${txt.get(
      "orders.order.costing.rationale.product_description_also",
      txt.get(
        "orders.order.costing.rationale.product",
        descriptions,
        productUseDescription(
          uses[1] === "NONE" ? ProductUse.Day : uses[1],
          "orders.order.costing.rationale"
        ).toLowerCase()
      )
    )}.`;
  }
  return toRationaleSection(title, productDescription);
};

const toRationaleReason = (order: Order, client: string, pronoun: string) => {
  let title: string = "";
  let reasonDescription: string = "";
  title = txt.get("orders.order.costing.rationale.reason_title");

  reasonDescription += `${txt.get(
    "orders.order.costing.rationale.reason_default_intro",
    client
  )}.`;

  let indications: string = order?.referral?.indications
    ? indicationDescription(order.referral.indications)
    : "";
  let needs: string = getNeed(order.needs, "care_needs.needs");
  let groupedByUse: any = orderLinesGroupedByUse(order.order_lines);
  let uses: any = Object.keys(groupedByUse);

  if (indications && needs) {
    reasonDescription += ` ${txt.get(
      "orders.order.costing.rationale.reason_indications_needs",
      pronoun,
      indications,
      needs
    )}`;
  } else if (indications) {
    reasonDescription += ` ${txt.get(
      "orders.order.costing.rationale.reason_indications",
      pronoun,
      indications
    )}`;
  } else if (needs) {
    reasonDescription += ` ${txt.get(
      "orders.order.costing.rationale.reason_needs",
      pronoun,
      needs
    )}`;
  }

  let anatomicalDescription: string = toNeedsSummary(
    getNeeds(order.needs, "anatomy.")
  );

  if (anatomicalDescription) {
    reasonDescription += `${txt.get(
      "orders.order.costing.rationale.reason_anatomy",
      client,
      anatomicalDescription
    )}`;
  }

  if (uses.length === 0) {
    reasonDescription += `
${txt.get(
  "orders.order.costing.rationale.reason_default_intro_use",
  productUseDescription(
    ProductUse.Day,
    "orders.order.costing.rationale"
  ).toLowerCase()
)}.`;
    reasonDescription += ` ${txt.get(
      "orders.order.costing.rationale.reason_default_details_day",
      pronoun
    )}.`;
  }
  if (uses.length > 0) {
    reasonDescription += `
${txt.get(
  "orders.order.costing.rationale.reason_default_intro_use",
  productUseDescription(
    uses[0] === "NONE" ? ProductUse.Day : uses[0],
    "orders.order.costing.rationale"
  ).toLowerCase()
)}.`;
    reasonDescription += ` ${txt.get(
      `orders.order.costing.rationale.reason_default_details_${(uses[0] ===
      "NONE"
        ? ProductUse.Day
        : uses[0]
      )
        .toString()
        .toLowerCase()}`,
      pronoun
    )}.`;
  }
  if (uses.length > 1) {
    reasonDescription += ` ${txt.get(
      "orders.order.costing.rationale.reason_default_intro_use_also",
      productUseDescription(
        uses[1] === "NONE" ? ProductUse.Day : uses[1],
        "orders.order.costing.rationale"
      ).toLowerCase()
    )}.`;
    reasonDescription += ` ${txt.get(
      `orders.order.costing.rationale.reason_default_details_${(uses[1] ===
      "NONE"
        ? ProductUse.Day
        : uses[1]
      )
        .toString()
        .toLowerCase()}`,
      client
    )}.`;
  }

  return toRationaleSection(title, reasonDescription);
};

const toRationaleGoal = (order: Order, client: string, pronoun: string) => {
  let title: string = "";
  let goalDescription: string = "";
  title = txt.get("orders.order.costing.rationale.goal_title");

  let activities: string = getNeed(order.needs, "care_needs.activities");
  let groupedByUse: any = orderLinesGroupedByUse(order.order_lines);
  let uses: any = Object.keys(groupedByUse);

  if (activities) {
    goalDescription += `${txt.get(
      "orders.order.costing.rationale.goal_details",
      client,
      activities
    )}.
`;
  }

  goalDescription += `${txt.get("orders.order.costing.rationale.goal_intro")}.
`;

  if (uses.length === 0) {
    goalDescription += ` ${txt.get(
      "orders.order.costing.rationale.goal_result",
      pronoun,
      txt.get("orders.order.costing.rationale.goal_result_day")
    )}.`;
  }
  if (uses.length > 0) {
    goalDescription += ` ${txt.get(
      "orders.order.costing.rationale.goal_result",
      pronoun,
      txt.lf(
        `orders.order.costing.rationale.goal_result_${(uses[0] === "NONE"
          ? ProductUse.Day
          : uses[0]
        )
          .toString()
          .toLowerCase()}`
      )
    )}.`;
  }
  if (uses.length > 1) {
    goalDescription += ` ${txt.get(
      "orders.order.costing.rationale.goal_result_also",
      pronoun,
      txt.lf(
        `orders.order.costing.rationale.goal_result_${(uses[1] === "NONE"
          ? ProductUse.Day
          : uses[1]
        )
          .toString()
          .toLowerCase()}`
      )
    )}.`;
  }

  return toRationaleSection(title, goalDescription);
};

const toRationaleRequirements = (
  order: Order,
  client: string,
  pronoun: string
) => {
  let title: string = "";
  let requirementsDescription: string = "";
  title = txt.get("orders.order.costing.rationale.requirements_title");

  let groupedByHandFinger: any = orderLinesGroupedByHandFinger(
    order.order_lines
  );
  console.log("groupedByHandFinger", groupedByHandFinger);
  let groups: any[] = Object.keys(groupedByHandFinger);
  let productsName: string =
    order.order_lines.length <= 1
      ? txt.lf("orders.order.costing.rationale.product_generic")
      : txt.lf("orders.order.costing.rationale.products_generic");

  if (groups.length === 0) {
    requirementsDescription += `${txt.get(
      "orders.order.costing.rationale.requirements_intro",
      productsName,
      txt.lf("orders.order.costing.rationale.requirements_default")
    )}.`;
  }
  if (groups.includes("SPECIAL")) {
    requirementsDescription += `${txt.get(
      "orders.order.costing.rationale.requirements_intro",
      productsName,
      txt.lf("orders.order.costing.rationale.requirements_default")
    )}`;
  } else if (groups.length === 1) {
    requirementsDescription += `${txt.get(
      "orders.order.costing.rationale.requirements_intro",
      productsName,
      txt.lf(
        `orders.order.costing.rationale.requirements_${(groups[0] === "NONE"
          ? "FINGER"
          : groups[0]
        ).toLowerCase()}`
      )
    )}`;
  } else if (groups.length > 1) {
    requirementsDescription += `${txt.get(
      "orders.order.costing.rationale.requirements_intro",
      productsName,
      `${txt.lf("orders.order.costing.rationale.requirements_hand")} ${txt.lf(
        "orders.order.costing.rationale.also"
      )} ${txt.lf("orders.order.costing.rationale.requirements_finger")}`
    )}`;
  }
  requirementsDescription += ".";

  requirementsDescription += `
${txt.get(
  "orders.order.costing.rationale.requirements_overall",
  productsName
)}.`;

  let charNeeds: Need[] = getNeeds(order.needs, "characteristics.");
  if (
    charNeeds.filter((need: Need) =>
      need.question.startsWith("characteristics.allergies")
    ).length > 1
  ) {
    charNeeds = charNeeds.filter(
      (need: Need) => need.question !== "characteristics.allergies"
    );
  }
  let characteristicsDescription: string = toNeedsSummary(charNeeds);

  if (characteristicsDescription) {
    requirementsDescription += `
${txt.get(
  "orders.order.costing.rationale.requirements_characteristics",
  client,

  characteristicsDescription
)}`;
  }

  let usageDescription: string = toNeedsSummary(
    getNeeds(order.needs, "usage.")
  );

  if (usageDescription) {
    requirementsDescription += `
${txt.get(
  "orders.order.costing.rationale.requirements_context",
  client,
  productsName,
  usageDescription
)}`;
  }

  return toRationaleSection(title, requirementsDescription);
};

const toRationaleCriteria = (order: Order, client: string, pronoun: string) => {
  let title: string = "";
  let criteriaDescription: string = "";
  title = txt.get("orders.order.costing.rationale.criteria_title");

  let oldProductUsed: string = getNeed(order.needs, "old_product.used");
  let oldProductDescription: string =
    oldProductUsed === "true"
      ? getNeed(order.needs, "old_product.description")
      : getNeed(order.needs, "old_product.not_used_description");
  oldProductDescription = oldProductDescription.trim();
  if (oldProductDescription.endsWith("."))
    oldProductDescription = oldProductDescription.substring(
      0,
      oldProductDescription.length - 1
    );
  if (oldProductUsed === "true" || oldProductUsed === "false") {
    criteriaDescription += `${txt.get(
      "orders.order.costing.rationale.criteria_before",
      client,
      oldProductUsed === "true"
        ? txt.lo("orders.order.costing.rationale.did")
        : txt.lo("orders.order.costing.rationale.did_not")
    )}`;
    criteriaDescription = criteriaDescription.trim();
    if (!criteriaDescription.endsWith(".")) criteriaDescription += ". ";
  }
  if (oldProductDescription) {
    criteriaDescription += ` ${txt.lf(
      "orders.order.costing.rationale.criteria_before_description",
      oldProductDescription
    )} `;
  } else if (criteriaDescription) {
    criteriaDescription += ` `;
  }

  let groupedByConfection: any = orderLinesGroupedByConfection(
    order.order_lines
  );
  let groups: any[] = Object.keys(groupedByConfection);
  if (groups.length === 0) {
    criteriaDescription += `${txt.get(
      "orders.order.costing.rationale.criteria_choice",
      txt.lf("orders.order.costing.rationale.criteria_custom").trim()
    )}`;
    criteriaDescription = criteriaDescription.trim();
    if (!criteriaDescription.endsWith(".")) criteriaDescription += ". ";
  }
  if (groups.length > 0) {
    criteriaDescription += `${txt.get(
      "orders.order.costing.rationale.criteria_choice",
      txt.lf(
        `orders.order.costing.rationale.criteria_${(groups[0] === "NONE"
          ? "CUSTOM"
          : groups[0]
        ).toLowerCase()}`
      )
    )}`;
    criteriaDescription = criteriaDescription.trim();
    if (!criteriaDescription.endsWith(".")) criteriaDescription += ". ";
  }

  if (groups.length > 1) {
    criteriaDescription += `${txt.get(
      "orders.order.costing.rationale.criteria_choice_also",
      txt.lf(
        `orders.order.costing.rationale.criteria_${(groups[1] === "NONE"
          ? "CUSTOM"
          : groups[1]
        ).toLowerCase()}`
      )
    )}`;
    criteriaDescription = criteriaDescription.trim();
    if (!criteriaDescription.endsWith(".")) criteriaDescription += ". ";
  }

  let productsName: string =
    order.order_lines.length <= 1
      ? txt.lf("orders.order.costing.rationale.product_generic")
      : txt.lf("orders.order.costing.rationale.products_generic");

  let productMotivations: string[] = order.order_lines
    .map((orderLine: OrderLine) => orderLine.argumentation)
    .filter((motivation: string) => motivation && motivation.trim() !== "");

  productMotivations = StringHelper.arrayUnique(productMotivations);

  if (productMotivations && productMotivations.length > 0) {
    criteriaDescription += `
${txt.get(
  "orders.order.costing.rationale.criteria_motivation",
  productsName,
  StringHelper.lofirst(
    productMotivations.length > 1
      ? `
${productMotivations.join(`
`)}`
      : productMotivations.join(`
`)
  )
)}`;
  }
  //Vanwege de flexibiliteit en de zachte randen gekozen voor siliconen.
  return toRationaleSection(title, criteriaDescription);
};

export const toBlankRationale = (
  order?: Order | null,
  client?: Client | null
): string => {
  let result = "";

  const clientDescription: string = client
    ? genderedName(client, "orders.order.costing.rationale")
    : txt.lo("orders.order.costing.rationale.client");

  const products: string = order
    ? toProductListDescriptions(order.order_lines)
    : "";

  result += toRationaleSection(
    txt.get("orders.order.costing.rationale.product_description_title"),
    `${clientDescription}
    ${products}

`
  );
  result += toRationaleSection(
    txt.get("orders.order.costing.rationale.reason_title"),
    `

`
  );
  result += toRationaleSection(
    txt.get("orders.order.costing.rationale.goal_title"),
    `

`
  );
  result += toRationaleSection(
    txt.get("orders.order.costing.rationale.requirements_title"),
    `

`
  );
  result += toRationaleSection(
    txt.get("orders.order.costing.rationale.criteria_title"),
    `

`
  );

  return result;
};

export const toCleanRationale = (
  order?: Order | null,
  client?: Client | null
): string => {
  let result = "";

  const clientDescription: string = client
    ? genderedName(client, "orders.order.costing.rationale")
    : txt.lo("orders.order.costing.rationale.client");

  const products: string = order
    ? toProductListDescriptions(order.order_lines)
    : "";

  result += toRationaleSection(
    txt.get("orders.order.costing.rationale.application_title"),
    `${clientDescription}
${products}

`
  );

  result += toRationaleSection(
    txt.get("orders.order.costing.rationale.care_needs_title"),
    `${toNeedsList(getNeeds(order?.needs || [], "care_needs"))}

`
  );

  result += toRationaleSection(
    txt.get("orders.order.costing.rationale.care_characteristics_title"),
    `${toNeedsList(getNeeds(order?.needs || [], "characteristics"))}

`
  );
  result += toRationaleSection(
    txt.get("orders.order.costing.rationale.care_usage_title"),
    `${toNeedsList(getNeeds(order?.needs || [], "usage"))}

`
  );
  result += toRationaleSection(
    txt.get("orders.order.costing.rationale.product_selection_title"),
    `
${products}

`
  );

  return result;
};

export const toRationale = (
  order?: Order | null,
  client?: Client | null
): string => {
  if (!order) {
    return "";
  }
  const clientDescription: string = client
    ? genderedName(client, "orders.order.costing.rationale")
    : txt.lo("orders.order.costing.rationale.client");

  const pronoun: string = client
    ? genderedPronoun(client, "orders.order.costing.rationale")
    : txt.lo("orders.order.costing.rationale.client");
  let rationaleParts: string[] = [];
  rationaleParts.push(
    toRationaleProductDescription(order, clientDescription, pronoun)
  );
  rationaleParts.push(toRationaleReason(order, clientDescription, pronoun));
  rationaleParts.push(toRationaleGoal(order, clientDescription, pronoun));
  rationaleParts.push(
    toRationaleRequirements(order, clientDescription, pronoun)
  );
  rationaleParts.push(toRationaleCriteria(order, clientDescription, pronoun));
  return rationaleParts.join(`

`);
};
