import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface RouteState {
  path : string;
}

const initialState: RouteState = {
  path : '',
}

export const routeSlice = createSlice({
  name: 'route',
  initialState,  
  reducers: {
    pathSave: (state, action:PayloadAction<string>) => {
      state.path = action.payload;
    },
  },

});

export const { pathSave } = routeSlice.actions;

export default routeSlice.reducer;
