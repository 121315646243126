import { EuiButton, EuiFlexItem, EuiSpacer } from "@elastic/eui";
import MMPage, { PageLink } from "components/layouts/page/page";
import txt from "helpers/text-helper";
import { adminNav } from "./admin";
import MMLocationsList from "./locations-list";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import AuthenticationHelper from "helpers/authentication-helper";
import FileUploadHelper, { FileResult } from "helpers/file-upload-helper";
import { toastAdd } from "store/components/toast/toast";
import MMContractsList from "./contracts-list";
import DateHelper from "helpers/date-helper";
import ConnectAPIHelper from "api/connect-api-helper";


function MMAdminContracts() {
  const [buttonsToShow, setButtonsToShow] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const api = new ConnectAPIHelper();
  const [subPages, setSubPages] = useState<PageLink[]>([]);
  useEffect(() => {
    const getSubPages = async () => {
      setSubPages(await adminNav("/admin/insurers/contracts"));
    };

    getSubPages();
  }, []);

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      let buttons: any[] = [];
      setButtonsToShow(buttons);
    };
    setInterfaceForPermissions();
  }, []);


  const handleImportContracts = async () => {
    setIsLoading(true);
    let fileUploadHelper = new FileUploadHelper();
    const path: string = "contracts/import";
    const key: string = "import_file";
    try {
      await fileUploadHelper.uploadFile(path, key, null);
      dispatch(toastAdd(txt.get("admin.contracts.imported"), null, "success"));
      navigate("/admin/insurers/contracts");
    } catch (e: any) {
      console.error("Something went wrong importing contracts", e);
      dispatch(
        toastAdd(txt.get("admin.contracts.import_error"), null, "danger")
      );
    }
    setIsLoading(false);
  };

  const handleExportContracts = async () => {
    setIsLoading(true);
    const result = await api.getContractsXls("xls");
    // const buffer =  Buffer.from(result, "base64").toString();
    const url = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${result}`;

    FileUploadHelper.triggerDownloadFile(
      url,
      `contracts_${DateHelper.toSortable()}.xlsx`
    );
    setIsLoading(false);
  };

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      let buttons: any[] = [];

      // Export Contracts Button
      if (await AuthenticationHelper.hasPermission(["contracts#edit_all"])) {
        buttons.push(
          <EuiButton
            isLoading={isLoading}
            key="export_contracts"
            size="s"
            iconType="exportAction"
            onClick={() => handleExportContracts()}
          >
            {txt.get("admin.contracts.export")}
          </EuiButton>
        );
      }

      // Import Contracts Button
      if (await AuthenticationHelper.hasPermission(["contracts#edit_all"])) {
        buttons.push(
          <EuiButton
            isLoading={isLoading}
            key="import_contracts"
            size="s"
            iconType="importAction"
            onClick={() => handleImportContracts()}
          >
            {txt.get("admin.contracts.import")}
          </EuiButton>
        );
      }

      setButtonsToShow(buttons);
    };
    setInterfaceForPermissions();
  }, [isLoading]);

  const buttons = () => {
    return buttonsToShow;
  };



  return (
    <MMPage
      title={`${txt.get("admin.page_title")} ${txt.lo(
        "admin.contracts.page_title"
      )}`}
      subPages={subPages}
      backTo="/admin"
      backToText={txt.uf("generic.back_to", txt.get("admin.page_title"))}
      sideActions={buttons()}
    >
      <EuiFlexItem>
        <EuiSpacer />
        <MMContractsList />
      </EuiFlexItem>
    </MMPage>
  );
}
export default MMAdminContracts;
