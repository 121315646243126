import MMNavigation from "components/layouts/navigation/navigation";
import MMFooter from "components/layouts/footer/footer";

import { BrowserRouter } from "react-router-dom";
import MMHeader from "components/layouts/header/header";
import { EuiPageTemplate } from "@elastic/eui";
import MMToasts from "components/layouts/toasts/toasts";
import MMTours from "components/layouts/tours/tours";
import MMAlertConfirm from "components/layouts/alert/confirm";
import MMNotifications from "components/layouts/notifications/notifications";
import MMDemoBanner from "components/layouts/demo-banner/demo-banner";
import MMOrderPopover from "components/orders/order-popover";
import MMBetaBar from "components/layouts/demo-banner/beta-bar";

function MMMain() {
  return (
    <BrowserRouter>
      <EuiPageTemplate>
        <MMBetaBar />
        <MMHeader />
        <MMNavigation />
        <MMFooter />
        <MMOrderPopover />
        <MMNotifications />
        <MMToasts />
        <MMTours />
        <MMAlertConfirm />
        <MMDemoBanner />
      </EuiPageTemplate>
    </BrowserRouter>
  );
}

export default MMMain;
