import MMPage from "components/layouts/page/page";

import { EuiButton, EuiSpacer } from "@elastic/eui";
import txt from "helpers/text-helper";
import MMAppointmentsList from "components/appointments/appointments-list";
import MMProductionAgendaImportVlot from "components/production/production-agenda-import-vlot";
import { useEffect, useState } from "react";
import ConnectAPIHelper from "api/connect-api-helper";
import DateHelper from "helpers/date-helper";

function MMProductionAgendaImport(props: any) {
  const api: ConnectAPIHelper = new ConnectAPIHelper();
  const [buttons, setButtons] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadResult, setUploadResult] = useState<string>("");
  const handleRemoveAppointments = async () => {
    setIsLoading(true);
    setUploadResult("");
    let result: any = api.removeAppointments();

    if (typeof result === "string") {
      console.log("something went wrong", result);
    } else {
      result = "appointments removed from sheet";
    }
    setUploadResult(result);
    setIsLoading(false);
  };

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      let buttons: any = [];
      buttons.push(
        <EuiButton
          color="danger"
          onClick={(e: any) => {
            handleRemoveAppointments();
          }}
        >
          {txt.get("appointments.sheet_sync.remove")}
        </EuiButton>
      );
      setButtons(buttons);
    };
    setInterfaceForPermissions();
  }, []);
  return (
    <MMPage
      title={txt.get("production.agenda.import.page_title")}
      sideActions={buttons}
    >
      <EuiSpacer />
      {/* <MMProductionAgendaImportVlot /> */}
      <MMAppointmentsList />
    </MMPage>
  );
}

export default MMProductionAgendaImport;
