import {
  EuiFilterButton,
  EuiFilterGroup,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFormRow,
} from "@elastic/eui";
import {
  MMOrderLineFieldProps,
  MMOrderLineProductInput,
} from "../order-line-entry";
import { Digit } from "store/data/scan/scan";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import StringHelper from "helpers/string-helper";

export interface DigitVariation {
  min: number;
  max: number;
  together: boolean;
}

function MMOrderDigits(props: MMOrderLineFieldProps) {
  const selectableDigits: any[] = Object.values(Digit);
  const [enabledDigits, setEnabledDigits] = useState<number[]>([]); //from configuration
  const [allowedDigits, setAllowedDigits] = useState<number[]>([]); //from selecting
  const [digitVariation, setDigitVariation] = useState<DigitVariation>();
  const [digits, setDigits] = useState<Digit[]>(
    props.selection && props.selection.digits
      ? props.selection.digits.map((digit: any) => `${digit}` as Digit)
      : []
  );

  useEffect(() => {
    setDigitVariation(
      props.selection.variation && props.selection.variation.digit_selection
        ? {
            min: parseInt(
              (props.selection.variation.digit_selection + "").charAt(0),
              10
            ),
            max: StringHelper.endsWith(
              props.selection.variation.digit_selection + "",
              "+"
            )
              ? selectableDigits.length
              : parseInt(
                  (props.selection.variation.digit_selection + "").charAt(0),
                  10
                ),
            together: StringHelper.endsWith(
              props.selection.variation.digit_selection + "",
              "^"
            ),
          }
        : undefined
    );
    setDigits(
      props.selection && props.selection.digits
        ? props.selection.digits.map((digit: any) => `${digit}` as Digit)
        : []
    );
  }, [props.selection]);

  useEffect(() => {
    let allowedDigits = [...enabledDigits];
    if (
      digitVariation &&
      digitVariation.max &&
      digitVariation.max === digits.length
    ) {
      allowedDigits = allowedDigits.filter((d: number) =>
        digits.includes(d.toString() as Digit)
      );
    }
    setAllowedDigits(allowedDigits);
  }, [enabledDigits, digitVariation, digits]);

  useEffect(() => {
    setEnabledDigits(
      props.selection.variation && props.selection.variation.digits
        ? props.selection.variation.digits
        : []
    );
  }, [props.selection]);

  const isDigitEnabled = (digit: Digit) => {
    return enabledDigits.includes(parseInt(digit));
  };

  const isDigitAllowed = (digit: Digit) => {
    return allowedDigits.includes(parseInt(digit));
  };

  const isMaxDigitValue = (digits: Digit[], digit: Digit): boolean =>
    Math.max(...digits.map((d: Digit) => parseInt(d))) === parseInt(digit);

  const maxDigitValue = (digits: Digit[]): number =>
    Math.max(...digits.map((d: Digit) => parseInt(d)));

  const minDigitValue = (digits: Digit[]): number =>
    Math.min(...digits.map((d: Digit) => parseInt(d)));

  const isDigitConnectedToNext = (digit: Digit) =>
    digitVariation &&
    digitVariation.together &&
    digits.includes(digit) &&
    !isMaxDigitValue(digits, digit);

  const handleDigitClicked = (digit: Digit) => {
    console.log("handleDigitClicked");

    // if (!isDigitAllowed(digit)) {

    // }

    const selectedDigits: Digit[] = !isDigitAllowed(digit) ? [] : [...digits];
    let updatedSelectedDigits: Digit[] = selectedDigits.includes(digit)
      ? selectedDigits.filter((selectedDigit) => selectedDigit !== digit)
      : [...Array.from(new Set(selectedDigits.concat([digit])))];
    updatedSelectedDigits.sort();
    if (
      digitVariation &&
      digitVariation.together &&
      selectedDigits.length < updatedSelectedDigits.length &&
      updatedSelectedDigits.length < digitVariation.min
    ) {
      const padRight: number =
        digitVariation.min - updatedSelectedDigits.length;
      let padLeft: number = 0;
      for (let i = 0; i < padRight; i++) {
        let digitToAdd = maxDigitValue(updatedSelectedDigits) + 1;
        if (allowedDigits.includes(digitToAdd)) {
          updatedSelectedDigits.push(digitToAdd.toString() as Digit);
        } else {
          padLeft++;
        }
      }
      for (let i = padLeft; i > 0; i--) {
        let digitToAdd = minDigitValue(updatedSelectedDigits) - 1;
        if (allowedDigits.includes(digitToAdd)) {
          updatedSelectedDigits.push(digitToAdd.toString() as Digit);
        } else {
          padLeft++;
        }
      }
    } else if (
      digitVariation &&
      digitVariation.together &&
      selectedDigits.length > updatedSelectedDigits.length
    ) {
      updatedSelectedDigits = [];
    }
    updatedSelectedDigits.sort();

    if (props.onChange) {
      props.onChange({ ...props.selection, digits: updatedSelectedDigits });
    } else {
      setDigits(updatedSelectedDigits);
    }
  };

  return !props.hideNonOptions ||
    (enabledDigits && enabledDigits.length > 0) ? (
    <EuiFlexGrid gutterSize="none">
      <EuiFlexGroup gutterSize="xs">
        <EuiFormRow
          display="rowCompressed"
          className="product-input"
          label={props.showTitle ? txt.get("orders.order.fingers") : undefined}
        >
          <EuiFilterGroup
            contentEditable={false}
            compressed={true}
            // style={{ minWidth: `${26 * selectableDigits.length}px` }}
          >
            {selectableDigits.map((digit: Digit) => (
              <EuiFilterButton
                isDisabled={!isDigitEnabled(digit)}
                withNext={isDigitConnectedToNext(digit)}
                style={{ minWidth: "26px" }}
                className={
                  !isDigitAllowed(digit) ? "digit-selection-subdued" : undefined
                }
                key={`digit-${digit}`}
                hasActiveFilters={digits.includes(digit)}
                isSelected={digits.includes(digit)}
                onClick={(e: any) => handleDigitClicked(digit)}
              >
                {digit}
              </EuiFilterButton>
            ))}
          </EuiFilterGroup>
        </EuiFormRow>
      </EuiFlexGroup>
    </EuiFlexGrid>
  ) : (
    <></>
  );
}

export default MMOrderDigits;
