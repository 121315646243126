import {
  EuiButton,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiMarkdownEditor,
  EuiText,
} from "@elastic/eui";
import txt from "helpers/text-helper";
import { useCallback, useEffect, useState } from "react";
import { ORDER_COSTING_EMPTY, OrderCosting } from "store/data/costing/costing";
import { updateField } from "hoc/helper-hooks";
import { Order } from "store/data/order/order";
import { MMCostingDetailsProps } from "../costing-inputs";
import { Client } from "store/data/client/client";
import _ from "lodash";
import { toBlankRationale, toRationale } from "../costing-rationale-generators";

function MMCostingRationale(props: MMCostingDetailsProps) {
  const [order, setOrder] = useState<Order | null | undefined>(props.order);
  const [client, setClient] = useState<Client | null | undefined>(props.client);
  const [rationale, setRationale] = useState<string>(
    props.order?.costing?.rationale || ""
  );
  // const [isEditing, setIsEditing] = useState<boolean>(false);

  // const [list, setList] = useState(makeList(3));

  const saveRationale = (changedRationale: string, close: boolean = false) => {
    setRationale(changedRationale);
    if (props.onChange) {
      props.onChange(changedRationale, close);
    }
  };

  useEffect(() => {
    setOrder(props.order);
    setClient(props.client);
    setRationale(props.order?.costing?.rationale || "");
  }, [props.order, props.client]);

  return (
    <EuiFlexGrid>
      {props.hasValidation ? (
        <EuiText color="danger">
          {txt.get(
            "generic.fill_out_x",
            txt.get("orders.order.costing.rationale.name")
          )}
        </EuiText>
      ) : (
        <></>
      )}
      <EuiFlexItem grow={false}>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiButton
              onClick={(e: any) => {
                saveRationale(toRationale(order, client));
              }}
            >
              {txt.get("orders.order.costing.rationale.generate")}
            </EuiButton>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButton
              onClick={(e: any) => {
                saveRationale(toBlankRationale(order, client));
              }}
            >
              {txt.get("orders.order.costing.rationale.blank")}
            </EuiButton>
          </EuiFlexItem>
          {/* <EuiFlexItem grow={false}>
            <EuiButton
              onClick={(e: any) => {
                saveRationale(toCleanRationale(order, client));
              }}
            >
              {txt.get("orders.order.costing.rationale.clean")}
            </EuiButton>
          </EuiFlexItem> */}
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem
        style={{
          maxHeight: "500px",
          height: props.hasValidation
            ? "calc(100vh - 540px)"
            : "calc(100vh - 490px)",
          width: "850px",
          marginBottom: "20px",
        }}
      >
        <EuiFlexGroup gutterSize="xs">
          <EuiFlexItem>
            <EuiMarkdownEditor
              style={{
                maxHeight: "500px",
              }}
              aria-label="EUI markdown editor with full height "
              value={rationale}
              onChange={(changedRationale: string) => {
                saveRationale(changedRationale, false);
              }}
              onBlur={(e: any) => {
                saveRationale(e.target.value, false);
              }}
              height={"full"}
              initialViewMode="editing"
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFlexGrid> </EuiFlexGrid>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup justifyContent="flexEnd">
          <EuiFlexItem grow={false}>
            <EuiButton
              onClick={(e: any) => {
                saveRationale(rationale, true);
              }}
            >
              {txt.get("generic.close")}
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGrid>
  );
}

export default MMCostingRationale;
