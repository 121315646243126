import {
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiDatePicker,
  EuiFieldText,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiIcon,
  EuiPanel,
  EuiSelect,
  EuiSpacer,
} from "@elastic/eui";
import MMTitle from "components/layouts/title/title";
import DateHelper from "helpers/date-helper";
import MomentHelper from "helpers/moment-helper";
import txt from "helpers/text-helper";
import { onStringValue, updateField } from "hoc/helper-hooks";
import { useEffect, useState } from "react";
import { Client, Sex } from "store/data/client/client";
import { ContactMethodType } from "store/data/contactmethod/contactmethod";

export interface MMContactInfoProps {
  client: Client | null;
  //   isPersonalIdShown?: boolean;
  isEditable?: boolean;
  isHeaderShown?: boolean;
}

function MMContactInfo(props: MMContactInfoProps) {
  const [client, setClient] = useState<Client>();
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isHeaderShown, setIsHeaderShown] = useState<boolean>(false);
  const [isPersonalIDShown, setIsPersonalIdShown] = useState<boolean>(false);

  const contactMethodOptions: any[] = [
    { value: ContactMethodType.Email, text: txt.get("clients.email") },
    {
      value: ContactMethodType.MobilePhone,
      text: txt.get("clients.phone_number_mobile"),
    },
    { value: ContactMethodType.Phone, text: txt.get("clients.phone_number") },
  ];

  useEffect(() => {
    setIsEditable(!!props.isEditable);
    if (props.client) {
      setClient(props.client);
    } else {
      setClient(undefined);
    }
  }, [props.client, props.isEditable, props.isHeaderShown]);

  const onClient = (client: Client) => {
    setClient(client);
  };

  return client ? (
    <EuiFlexGrid gutterSize="s" className="subtle-editing">
      <EuiFlexItem>
        <EuiFlexGroup justifyContent="spaceBetween">
          <MMTitle text={txt.get("clients.contact_info.name")} />
          <EuiButtonIcon aria-label="add" iconType={"plus"} />
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiPanel className="subtle-panel">
        <EuiFlexItem>
          <EuiFlexGroup gutterSize="xs" style={{ maxWidth: "400px" }}>
            <EuiFlexItem
              grow={3}
              style={!isEditable ? { display: "none" } : {}}
            >
              <EuiFormRow
                display="rowCompressed"
                label={
                  isHeaderShown
                    ? txt.get("clients.contact_methods.name")
                    : undefined
                }
                title={txt.get("clients.contact_methods.name")}
                className="disabled-as-label"
              >
                <EuiSelect
                  disabled={true || !isEditable}
                  aria-placeholder={txt.get("clients.contact_methods.choose")}
                  compressed={true}
                  options={contactMethodOptions}
                  value={ContactMethodType.Email}
                  onChange={(e) => {}}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={6}>
              <EuiFormRow
                display="rowCompressed"
                label={isHeaderShown ? txt.get("clients.email") : undefined}
                title={txt.get("clients.email")}
              >
                <EuiFieldText
                  append={
                    <EuiButtonEmpty
                      disabled={!isEditable}
                      iconType="check"
                      size="xs"
                      color="success"
                    />
                  }
                  placeholder={txt.get("clients.email")}
                  compressed={true}
                  disabled={!isEditable}
                  name="email"
                  value={client.email || ""}
                  onChange={(e) =>
                    onClient(updateField(client, "email", onStringValue(e)))
                  }
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexGroup gutterSize="xs" style={{ maxWidth: "400px" }}>
            <EuiFlexItem
              grow={3}
              style={!isEditable ? { display: "none" } : {}}
            >
              <EuiFormRow
                display="rowCompressed"
                label={
                  isHeaderShown
                    ? txt.get("clients.contact_methods.name")
                    : undefined
                }
                title={txt.get("clients.contact_methods.name")}
                className="disabled-as-label"
              >
                <EuiSelect
                  disabled={true || !isEditable}
                  // placeholder={txt.get("clients.contact_methods.choose")}
                  compressed={true}
                  options={contactMethodOptions}
                  value={ContactMethodType.Phone}
                  onChange={(e) => {}}
                  aria-label={txt.get("clients.contact_methods.name")}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={6}>
              <EuiFormRow
                display="rowCompressed"
                label={
                  isHeaderShown ? txt.get("clients.phone_number") : undefined
                }
                title={txt.get("clients.phone_number")}
              >
                <EuiFieldText
                  append={
                    <EuiButtonEmpty
                      disabled={!isEditable}
                      iconType="check"
                      size="xs"
                      color="success"
                    />
                  }
                  placeholder={txt.get("clients.phone_number")}
                  compressed={true}
                  disabled={!isEditable}
                  name="phone_number"
                  value={client.phone_number || ""}
                  onChange={(e) =>
                    onClient(
                      updateField(client, "phone_number", onStringValue(e))
                    )
                  }
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiPanel>
    </EuiFlexGrid>
  ) : (
    <></>
  );
}

export default MMContactInfo;
