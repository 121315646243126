import AuthenticationHelper from "helpers/authentication-helper";
import APIHelper from "./api-helper";
import env from "helpers/env-helper";
import { AuthGroup } from "store/data/auth/group";
import { AuthUser } from "store/data/auth/user";
import { AuthRole } from "store/data/auth/role";

export const HIDDEN_ROLES: string[] = [
  "uma_authorization",
  "default-roles-manometric",
  "rabbitmq-management",
  "rabbitmq.tag:administrator",
  "rabbitmq.tag:management",
  "processing-sys",
  "production-sys",
  "scanner-sys",
  "rabbitmq.configure:*/*",
  "rabbitmq.read:*/*",
  "rabbitmq.write:*/*",
  "rabbitmq.read:*/*/*",
  "rabbitmq.write:*/*/*",
  "offline_access",
  "super_admin",
];

class AuthAdminAPIHelper extends APIHelper {
  private ACCESS_TOKEN_KEY = "cloud.manometric.auth.access_token";
  private CLIENTS_WITH_AUTHORIZATION = env(
    "REACT_APP_API_AUTHORIZABLE_CLIENTS"
  );

  private API_ROOT = `${env("REACT_APP_API_AUTH")}admin/realms/${env(
    "REACT_APP_API_AUTH_REALM"
  )}`;

  async preHook() {}

  async postHook() {}

  getRoot() {
    return this.API_ROOT;
  }

  getAccesstokenKey() {
    return this.ACCESS_TOKEN_KEY;
  }

  getUserCredentialsKey() {
    return null;
  }

  async checkAuthentication() {
    await AuthenticationHelper.refreshAuthentication();
    this.setAccessToken(AuthenticationHelper.getToken());
  }

  async getGroups() {
    const path: string = "groups";
    const params: any = {
      briefRepresentation: false,
    };
    await this.checkAuthentication();
    const result: any = await this.get(path, params);
    return this.handleResult(result);
  }

  async createOrganisationGroup(newGroup: any) {
    const apiPath: string = `groups`;
    const body: any = newGroup;
    await this.checkAuthentication();
    const result: any = await this.post(apiPath, null, body);
    console.log("createOrganisationGroup", newGroup, result);
    //get the newly created group by searching for the name... kinda weird
    const group: any = (await this.getGroups()).find(
      (group: any) => group.name === newGroup.name
    );
    if (group) {
      await this.updateGroupsPolicyForOrganisationGroup(group.id);
    }
    return this.handleResult(result);
  }

  async saveOrganisationGroup(group: AuthGroup) {
    const apiPath: string = `groups/${group.id}`;
    const body: any = group;
    await this.checkAuthentication();
    const result: any = await this.put(apiPath, null, body);
    console.log("saveOrganisationGroup", group, result);
    await this.updateGroupsPolicyForOrganisationGroup(group.id);
    return this.handleResult(result);
  }

  async updateGroupsPolicyForOrganisationGroup(
    organisationGroupId: string,
    addGroup: boolean = true
  ) {
    console.log("updateGroupsPolicyForOrganisationGroup", organisationGroupId);
    const clients: any[] = await this.getAvailableClients();
    let policyResults: any[] = [];
    for (let i = 0; i < clients.length; i++) {
      const client = clients[i];
      const policies: any[] = await this.getClientPermissionPolicies(client.id);
      const groupsPolicy: any = policies.find(
        (policy: any) => policy.name === "groups"
      );
      console.log(
        "updateGroupsPolicyForOrganisationGroup found policies",
        groupsPolicy,
        policies
      );
      if (groupsPolicy) {
        const groups = JSON.parse(groupsPolicy.config.groups);
        console.log(
          "updateGroupsPolicyForOrganisationGroup found sub groups",
          groups
        );
        if (
          addGroup &&
          groups.findIndex((group: any) => group.id === organisationGroupId) < 0
        ) {
          //if the group is not in here and should be
          //add it
          const apiPath: string = `clients/${client.id}/authz/resource-server/policy/group/${groupsPolicy.id}`;
          const body: any = {
            id: groupsPolicy.id,
            name: "groups",
            groups: groups.concat([
              { id: organisationGroupId, extendChildren: true },
            ]),
            description: "",
            groupsClaim: "",
            logic: "POSITIVE",
          };
          policyResults.push(await this.put(apiPath, null, body));
        } else if (
          !addGroup &&
          groups.findIndex((group: any) => group.id === organisationGroupId) >=
            0
        ) {
          //if the group is in here and should not be
          //remove it it

          const apiPath: string = `clients/${client.id}/authz/resource-server/policy/group/${groupsPolicy.id}`;
          const body: any = {
            id: groupsPolicy.id,
            name: "groups",
            groups: groups.filter(
              (group: any) => group.id !== organisationGroupId
            ),
            description: "",
            groupsClaim: "",
            logic: "POSITIVE",
          };
          policyResults.push(await this.put(apiPath, null, body));
        }
      }
    }
    return this.handleResult(policyResults);
  }
  async getUsers() {
    const path: string = "users";
    const params: any = {
      briefRepresentation: false,
    };
    await this.checkAuthentication();
    const result: any = await this.get(path, params);
    return this.handleResult(result);
  }

  async getUser(username: string) {
    const path: string = `users/`;
    await this.checkAuthentication();
    const params: any = {
      username,
    };
    let result: any = await this.get(path, params);

    if (result && result.length > 0) {
      result = result[0];
      const credentialsPath = `users/${result.id}/credentials`;
      const credentialsResult = await this.get(credentialsPath);
      result.credentials = this.handleResult(credentialsResult);

      const socialLoginsPath = `users/${result.id}/federated-identity`;
      const socialLoginsResult = await this.get(socialLoginsPath);
      result.socialLogins = this.handleResult(socialLoginsResult);

      const groupsPath = `users/${result.id}/groups`;
      const groupsResult = await this.get(groupsPath);
      result.groups = this.handleResult(groupsResult);

      const rolesPath = `users/${result.id}/role-mappings/realm`;
      const rolesResult = await this.get(rolesPath);
      result.roles = this.handleResult(rolesResult);
    }

    return this.handleResult(result);
  }

  async createUser(user: any) {
    const apiPath: string = `users/`;
    const body: any = user;
    await this.checkAuthentication();
    const result: any = await this.post(apiPath, null, body);
    return this.handleResult(result);
  }

  async saveUser(
    user: AuthUser,
    addRoles?: AuthRole[],
    removeRoles?: AuthRole[],
    addGroups?: AuthGroup[],
    removeGroups?: AuthGroup[]
  ) {
    console.log("saveUser", user, addRoles, removeRoles);
    const apiPath: string = `users/${user.id}`;
    const body: any = user;
    await this.checkAuthentication();
    const result: any = await this.put(apiPath, null, body);

    if (removeRoles && removeRoles.length > 0) {
      console.log("removing roles", removeRoles);
      const rolesPath: string = `${apiPath}/role-mappings/realm`;
      const rolesBody: any = removeRoles;
      const rolesResult: any = await this.delete(rolesPath, null, rolesBody);
    }
    if (addRoles && addRoles.length > 0) {
      console.log("adding roles", addRoles);
      const rolesPath: string = `${apiPath}/role-mappings/realm`;
      const rolesBody: any = addRoles;
      const rolesResult: any = await this.post(rolesPath, null, rolesBody);
    }

    if (removeGroups && removeGroups.length > 0) {
      console.log("removing groups", removeGroups);
      for (let i = 0; i < removeGroups.length; i++) {
        const group = removeGroups[i];
        const groupPath: string = `${apiPath}/groups/${group.id}`;
        const groupResult: any = await this.delete(groupPath);
      }
    }
    if (addGroups && addGroups.length > 0) {
      console.log("add groups", addGroups);
      for (let i = 0; i < addGroups.length; i++) {
        const group = addGroups[i];
        const groupPath: string = `${apiPath}/groups/${group.id}`;
        const groupResult: any = await this.put(groupPath);
      }
    }

    return this.handleResult(result);
  }

  async resetPassword(userId: string) {
    const apiPath: string = `users/${userId}/execute-actions-email`;
    const body: any = ["UPDATE_PASSWORD"];
    await this.checkAuthentication();
    const result: any = await this.put(apiPath, null, body);
    return this.handleResult(result);
  }
  async getClients() {
    const apiPath: string = `clients`;
    await this.checkAuthentication();
    const result: any = await this.get(apiPath);
    return this.handleResult(result);
  }

  async getAvailableClients() {
    let availableClients: any[] = await this.getClients();
    let clients = this.CLIENTS_WITH_AUTHORIZATION;
    if (clients) clients = clients.split(",");
    availableClients = availableClients.filter((availableClient: any) =>
      clients.includes(availableClient.clientId)
    );
    return availableClients;
  }

  //the things that we want to have policies for, which we believe are groups and realm roles for now
  async getPoliciables() {
    let result: any[] = [];
    // Geraldi 2024-07-24: temporarily hide groups from Admin-permissions view.
    /*
    let groups = await this.getGroups();
    */
    let roles = await this.getRoles();
    result = result.concat(
      // Geraldi 2024-07-24: temporarily hide groups from Admin-permissions view.
      /*
      groups.map((group: any) => ({
        id: group.id,
        name: group.name,
        type: "group",
      })),
      */
      roles.map((role: any) => ({ id: role.id, name: role.name, type: "role" }))
    );
    return result;
  }
  async getPermissions() {
    let result: any = {};
    let clients: any[] = await this.getAvailableClients();
    for (let i = 0; i < clients.length; i++) {
      const client: any = clients[i];
      result[client.clientId] = {
        id: client.id,
        resources: await this.getClientPermissionResources(client.id),
        scopes: await this.getClientPermissionScopes(client.id),
        policies: await this.getClientPermissionPolicies(client.id),
        permissions: await this.getClientPermissionPermissions(client.id),
      };
    }
    return result;
  }

  async getRoles() {
    const apiPath: string = `roles?first=0&max=1000`;
    await this.checkAuthentication();
    let result: any = await this.get(apiPath);
    result = result.filter((role: any) => !HIDDEN_ROLES.includes(role.name));
    return this.handleResult(result);
  }

  async getClientPermissionResources(id: string) {
    return this.getClientPermissionRes(id, "resource");
  }

  async getClientPermissionScopes(id: string) {
    return this.getClientPermissionRes(id, "scope");
  }

  async getClientPermissionPolicies(id: string) {
    return this.getClientPermissionRes(id, "policy");
  }

  async createPermissionRolePolicy(
    clientId: string,
    name: string,
    roleId: string
  ) {
    let result: any;

    const apiPath: string = `clients/${clientId}/authz/resource-server/policy/role/`;
    await this.checkAuthentication();
    const body: any = {
      name: name,
      roles: [{ id: roleId, required: true }],
      description: "",
      logic: "POSITIVE",
    };
    result = await this.post(apiPath, null, body);

    return result;
  }

  async createPermissionGroupPolicies(name: string, groupId: string) {
    let result: any;

    const clients: any[] = await this.getAvailableClients();
    for (let i = 0; i < clients.length; i++) {
      const client: any = clients[i];
      const apiPath: string = `clients/${client.id}/authz/resource-server/policy/group/`;
      await this.checkAuthentication();
      const body: any = {
        name: name,
        groups: [{ id: groupId, extendChildren: false }],
        description: "",
        groupsClaim: "",
        logic: "POSITIVE",
      };
      result = await this.post(apiPath, null, body);
    }
    return result;
  }

  async deletePermissionPolicy(clientId: string, policyId: string) {
    let result: any;
    const apiPath: string = `clients/${clientId}/authz/resource-server/policy/${policyId}`;
    await this.checkAuthentication();
    const body: any = {};
    result = await this.delete(apiPath, null, body);
    return result;
  }
  async createPermissionGroupPolicy(
    clientId: string,
    name: string,
    groupId: string
  ) {
    let result: any;

    const apiPath: string = `clients/${clientId}/authz/resource-server/policy/group/`;
    await this.checkAuthentication();
    const body: any = {
      name: name,
      groups: [{ id: groupId, extendChildren: false }],
      description: "",
      groupsClaim: "",
      logic: "POSITIVE",
    };
    result = await this.post(apiPath, null, body);
    return result;
  }

  async getClientPermissionPermission(id: string, permissionId: string) {
    const apiPath: string = `clients/${id}/authz/resource-server/permission/scope/${permissionId}`;
    await this.checkAuthentication();
    const result: any = await this.get(apiPath);
    result.details = await this.getClientPermissionPermissionDetails(
      id,
      permissionId
    );
    return result;
  }

  async getClientPermissionPermissions(id: string) {
    let result: any[] = await this.getClientPermissionRes(id, "permission");
    for (let i = 0; i < result.length; i++) {
      const permission = result[i];
      result[i].details = await this.getClientPermissionPermissionDetails(
        id,
        permission.id
      );
    }
    return result;
  }

  async createClientPermissionPermission(
    id: string,
    policies: any[],
    resources: string[],
    scopes: string[]
  ) {
    const apiPath: string = `clients/${id}/authz/resource-server/permission/scope`;
    await this.checkAuthentication();
    const body: any = {
      decisionStrategy: "AFFIRMATIVE",
      description: "",
      name: `${policies.map((policy: any) => policy.name).join(",")}:${scopes
        .map((scope: any) => scope.name)
        .join(",")}:${resources
        .map((resource: any) => resource.name)
        .join(",")}`,
      policies: policies.map((policy: any) => policy.id),
      scopes: scopes.map((scope: any) => scope.id),
      resources: resources.map((resource: any) => resource._id),
      // type: "scope",
    };
    //returns created object, with ids only
    const result: any = await this.post(apiPath, null, body);
    return this.handleResult(result);
  }

  async updateClientPermissionPermission(
    id: string,
    permissionId: string,
    policies: string[],
    resources: string[],
    scopes: string[]
  ) {
    const apiPath: string = `clients/${id}/authz/resource-server/permission/scope/${permissionId}`;
    await this.checkAuthentication();
    const body: any = {
      decisionStrategy: "AFFIRMATIVE",
      description: "",
      id: permissionId,
      logic: "POSITIVE",
      name: `${policies.map((policy: any) => policy.name).join(",")}:${scopes
        .map((scope: any) => scope.name)
        .join(",")}:${resources
        .map((resource: any) => resource.name)
        .join(",")}`,
      policies: policies.map((policy: any) => policy.id),
      scopes: scopes.map((scope: any) => scope.id),
      resources: resources.map((resource: any) => resource._id),
      type: "scope",
    };
    console.log(apiPath, body);
    const result: any = await this.put(apiPath, null, body);
    return this.handleResult(result);
  }

  async deleteClientPermissionPermission(id: string, permissionId: string) {
    const apiPath: string = `clients/${id}/authz/resource-server/permission/scope/${permissionId}`;
    await this.checkAuthentication();
    const body: any = {};
    //returns created object, with ids only
    const result: any = await this.delete(apiPath, null, body);
    return this.handleResult(result);
  }

  async getClientPermissionPermissionDetails(id: string, permissionId: string) {
    const result: any = {
      scopes: await this.getClientPermissionPermissionRes(
        id,
        permissionId,
        "scopes"
      ),
      resources: await this.getClientPermissionPermissionRes(
        id,
        permissionId,
        "resources"
      ),
      policies: await this.getClientPermissionPermissionRes(
        id,
        permissionId,
        "associatedPolicies"
      ),
    };
    return result;
  }
  async getClientPermissionPermissionRes(
    id: string,
    permissionId: string,
    resource: string
  ) {
    const apiPath: string = `clients/${id}/authz/resource-server/policy/${permissionId}/${resource}`;
    const result: any = await this.get(apiPath);
    return this.handleResult(result);
  }

  async getClientPermissionRes(id: string, resource: string) {
    const apiPath: string = `clients/${id}/authz/resource-server/${resource}?first=0&max=1000&deep=true&permission=false`;
    await this.checkAuthentication();
    const result: any = await this.get(apiPath);
    // console.log("getClientPermissionRes", id, resource, result);
    return this.handleResult(result);
  }

  async createClientPermissionScope(id: string, name: string) {
    const body: any = {
      displayName: "",
      iconUri: "",
      name: name,
    };
    return this.createClientPermissionRes(id, "scope", body);
  }

  async updateClientPermissionScope(id: string, scopeId: string, name: string) {
    const body: any = {
      id: scopeId,
      displayName: "",
      iconUri: "",
      name: name,
    };
    return this.updateClientPermissionRes(id, "scope", scopeId, body);
  }

  async createClientPermissionRes(id: string, resource: string, body: any) {
    const apiPath: string = `clients/${id}/authz/resource-server/${resource}`;
    await this.checkAuthentication();
    const result: any = await this.post(apiPath, null, body);
    console.log("createClientPermissionRes", id, resource, result);
    return this.handleResult(result);
  }

  async updateClientPermissionRes(
    id: string,
    resource: string,
    resourceId: string,
    body: any
  ) {
    const apiPath: string = `clients/${id}/authz/resource-server/${resource}/${resourceId}`;
    await this.checkAuthentication();
    const result: any = await this.put(apiPath, null, body);
    console.log("updateClientPermissionRes", id, resource, resourceId, result);
    return this.handleResult(result);
  }

  async getClientPermissions(id: string) {
    const apiPath: string = `clients/${id}`;
    await this.checkAuthentication();
    const result: any = await this.get(apiPath);
    return this.handleResult(result);
  }

  handleResult(result: any) {
    if (result.error) {
      console.error("error getting auth admin result", result.error);
      return {};
    } else {
      return result;
    }
  }
}

export default AuthAdminAPIHelper;
