import MMPage from "components/layouts/page/page";
import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiPanel,
  EuiSpacer,
} from "@elastic/eui";
import txt from "helpers/text-helper";
import { Client, clientName } from "store/data/client/client";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ClientsAPIHelper from "api/clients-api.helper";
import { ApiResponse, ApiResponseStatus } from "api/api-helper";
import StringHelper from "helpers/string-helper";
import MMPersonalInfo from "./dossier/personal-info";
import AuthenticationHelper from "helpers/authentication-helper";
import MMContactInfo from "./dossier/contact-info";
import MMAddressInfo from "./dossier/address-info";
import MMIdInfo from "./dossier/id-info";
import { Referral } from "store/data/referral/referral";
import MMCareInfo from "./dossier/care-info";
import ConnectAPIHelper from "api/connect-api-helper";
import MMCommunicationInfo from "./dossier/communication-info";
import MMInsuranceInfo from "./dossier/insurance-info";

function MMClientDossier() {
  const [isLoading, setIsLoading] = useState(false);
  const [buttonsToShow, setButtonsToShow] = useState([]);

  const api: ConnectAPIHelper = new ConnectAPIHelper();
  const clientApi: ClientsAPIHelper = new ClientsAPIHelper();
  const navigate = useNavigate();
  const { clientId } = useParams();
  const [client, setClient] = useState<Client | null>(null);
  const [referrals, setReferrals] = useState<Referral[]>([]);
  const [referralId, setReferralId] = useState<number>();

  const [insuranceDetails, setInsuranceDetails] = useState<any>();
  const [clientError, setClientError] = useState("");

  const [isClientEditAllowed, setIsClientEditAllowed] =
    useState<boolean>(false);
  const [
    isClientCommunicationDetailsShown,
    setIsClientCommunicationDetailsShown,
  ] = useState<boolean>(false);
  const [isClientInsuranceDetailsShown, setIsClientInsuranceDetailsShown] =
    useState<boolean>(false);
  const [
    isClientCommunicationEditAllowed,
    setIsClientCommunicationEditAllowed,
  ] = useState<boolean>(false);
  const [isClientInsuranceEditAllowed, setIsClientInsuranceEditAllowed] =
    useState<boolean>(false);

  const [insuranceDetailsError, setInsuranceDetailsError] = useState("");

  useEffect(() => {
    const loadClient = async (clientId: number) => {
      setIsLoading(true);
      const result: ApiResponse = await clientApi.getClient(clientId);
      if (result && result.status === ApiResponseStatus.OK) {
        delete result.result.vlot_data;
        setClient(result.result);
      } else {
        console.log("Something went wrong, no client?", result);
        setClientError(result.message);
        setIsLoading(false);
        return;
      }
      const insuranceResult: ApiResponse = await clientApi.getInsuranceDetails(
        clientId
      );
      if (insuranceResult && insuranceResult.status === ApiResponseStatus.OK) {
        setInsuranceDetails(insuranceResult.result);
      } else {
        console.log("Something went wrong, no client?", result);
        setInsuranceDetailsError(insuranceResult.message);
        setIsLoading(false);
        return;
      }

      if (result && result.result) {
        //todo: figure out paging  / load more interface for referrals.
        const referralsResult: ApiResponse = await api.getReferrals(
          { client_code: result.result.client_code },
          5
        );
        if (
          referralsResult &&
          referralsResult.status === ApiResponseStatus.OK
        ) {
          setReferrals(referralsResult.result);
          if (referralsResult.result.length > 0 && !referralId) {
            setReferralId(referralsResult.result.id);
          } else {
            setReferralId(undefined);
          }
        } else {
          console.log("referralResult error", referralsResult.message);
          setReferrals([]);
        }
      }
    };

    if (clientId) {
      loadClient(parseInt(clientId as string));
    } else {
      console.log("no client id?", clientId);
    }
  }, [clientId]);

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      let buttons: any = [];

      buttons.push(
        <EuiButton
          aria-label={txt.get("clients.page_title")}
          size="s"
          fill={true}
          style={{ width: "40px", minInlineSize: "40px" }}
          iconType="faceHappy"
          onClick={() => navigate("/clients")}
        />
      );

      buttons.push(
        client ? (
          <EuiButton
            aria-label={txt.get("clients.page_title")}
            size="s"
            onClick={() => navigate(`/clients/${client.id}`)}
          >
            {clientName(client, true)} #{client.client_code}
          </EuiButton>
        ) : (
          <></>
        )
      );
      setButtonsToShow(buttons);

      setIsClientEditAllowed(
        false &&
          (await AuthenticationHelper.hasPermission([
            "client_details#edit_all",
            "client_details#edit_org",
            "client_details#edit",
          ]))
      );

      setIsClientCommunicationDetailsShown(
        await AuthenticationHelper.hasPermission([
          "client_communications#read_all",
          "client_communications#read_org",
          "client_communications#read",
        ])
      );
      setIsClientInsuranceDetailsShown(
        await AuthenticationHelper.hasPermission([
          "client_insurances#read_all",
          "client_insurances#read_org",
          "client_insuranced#read",
        ])
      );
      setIsClientCommunicationEditAllowed(
        await AuthenticationHelper.hasPermission([
          "client_communications#edit_all",
          "client_communications#edit_org",
          "client_communications#edit",
        ])
      );
      setIsClientInsuranceEditAllowed(
        await AuthenticationHelper.hasPermission([
          "client_insurances#edit_all",
          "client_insurances#edit_org",
          "client_insuranced#edit",
        ])
      );
    };

    setInterfaceForPermissions();
  }, [client]);

  const toEuiDescriptionList = (object: any) => {
    return Object.keys(object).map((key: string) => ({
      title: StringHelper.capitalize(key),
      description: object[key] ? object[key] : "-",
    }));
  };
  return (
    <MMPage
      title={txt.get("clients.client.page_title")}
      topActions={buttonsToShow}
      hideTitle={true}
      className="subtle-editing"
    >
      <EuiSpacer />

      <EuiFlexGroup gutterSize="xl">
        <EuiFlexItem grow={3}>
          <MMPersonalInfo client={client} isEditable={isClientEditAllowed} />
          <EuiSpacer />
          <MMContactInfo client={client} isEditable={isClientEditAllowed} />
          <EuiSpacer />
          <MMAddressInfo client={client} isEditable={isClientEditAllowed} />
          <EuiSpacer />
          <MMInsuranceInfo client={client} isEditable={isClientEditAllowed} />
          <EuiSpacer />
          <MMIdInfo client={client} isEditable={isClientEditAllowed} />
        </EuiFlexItem>

        <EuiFlexItem grow={3}>
          {clientId ? (
            <MMCommunicationInfo
              clientId={parseInt(clientId as string)}
              client={client}
            />
          ) : (
            <></>
          )}
        </EuiFlexItem>
        <EuiFlexItem grow={3}>
          {clientId ? (
            <MMCareInfo
              clientId={parseInt(clientId as string)}
              client={client}
            />
          ) : (
            <></>
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
    </MMPage>
  );
}

export default MMClientDossier;
