import {
  EuiButtonIcon,
  EuiCheckbox,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSpacer,
  EuiText,
  EuiTextArea,
  EuiTitle,
  EuiToolTip,
} from "@elastic/eui";
import txt from "helpers/text-helper";
import { MMOrderEntryInputProps } from "../order-entry";
import { useEffect, useState } from "react";
import { ORDER_EMPTY } from "store/data/order/order";
import { NEED_EMPTY, Need } from "store/data/need/need";
import { Input } from "helpers/input-helper";
import AuthenticationHelper from "helpers/authentication-helper";
import ConnectAPIHelper from "api/connect-api-helper";
import { ApiResponse, ApiResponseStatus } from "api/api-helper";
import MMAITextToSentence from "components/layouts/aitools/text-to-sentence";
import env from "helpers/env-helper";
import { Feature, feat } from "feats";

const TEXTAREA_SIZE_SMALL: number = 2;
const TEXTAREA_SIZE_NORMAL: number = 4;
const TEXTAREA_SIZE_LARGE: number = 6;
const enum ValueType {
  Boolean = "BOOLEAN",
  String = "STRING",
}

function MMOrderNeeds(props: MMOrderEntryInputProps) {
  const [needs, setNeeds] = useState<Need[]>([]);
  const [isTextToSentenceShown, setIsTextToSentenceShown] =
    useState<boolean>(false);

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      setIsTextToSentenceShown(
        await AuthenticationHelper.hasPermission([
          "contracts#read_all",
          "contracts#edit_all",
        ])
      );
    };
    setInterfaceForPermissions();
  }, []);

  useEffect(() => {
    if (props.order && props.order.needs) {
      setNeeds(props.order.needs);
    } else {
      setNeeds([]);
    }
  }, [props.order?.needs]);

  const onNeedsChanged = (
    updatedNeeds: Need[],
    updateOrder: boolean = true
  ) => {
    if (props.onChange && updateOrder) {
      props.onChange({
        ...ORDER_EMPTY,
        ...props.order,
        needs: updatedNeeds,
      });
    } else {
      setNeeds((needs: Need[]) => updatedNeeds);
    }
  };

  const onNeedChange = (
    question: string,
    answer: string,
    updateOrder: boolean = true,
    subNeeds: string[] = []
  ) => {
    let updatedNeeds: Need[] = [];
    let isNeedFound: boolean = false;
    for (let i = 0; i < needs.length; i++) {
      const need: Need = needs[i];
      if (need.question === question || subNeeds.includes(need.question)) {
        isNeedFound = true;
        if (answer !== "false") {
          updatedNeeds.push({ ...need, answer });
        }
      } else {
        updatedNeeds.push({ ...need });
      }
    }
    if (!isNeedFound) {
      updatedNeeds.push({ ...NEED_EMPTY, question, answer });
    }

    onNeedsChanged(updatedNeeds, updateOrder);
  };

  const errorMessage = (question: string) => {
    if (!props.hasValidation) return "";

    //temporarily make
    if (!AuthenticationHelper.isInternalUser()) {
      if (
        question === "old_product.description" ||
        question === "old_product.not_used_description"
      ) {
        let oldProductUsed: boolean = !!(
          props.order &&
          props.order?.needs.findIndex(
            (need: Need) =>
              need.question === "old_product.used" && need.answer === "true"
          ) >= 0
        );
        let oldProductUsedDescribed: boolean = !!(
          props.order &&
          props.order?.needs.findIndex(
            (need: Need) =>
              need.question === "old_product.description" && !!need.answer
          ) >= 0
        );
        let oldProductNotUsedDescribed: boolean = !!(
          props.order &&
          props.order?.needs.findIndex(
            (need: Need) =>
              need.question === "old_product.not_used_description" &&
              !!need.answer
          ) >= 0
        );
        if (
          (oldProductUsed && !oldProductUsedDescribed) ||
          (!oldProductUsed && !oldProductNotUsedDescribed)
        ) {
          return txt.get("validations.not_empty");
        }
      }
    }

    const fields: any = { ...props.inputs?.needs };
    const input: Input | null = fields[question]
      ? (fields[question] as Input)
      : null;
    if (!input?.mandatory) return "";

    if (
      props.order &&
      props.order?.needs.findIndex(
        (need: Need) => need.question === question && !!need.answer
      ) >= 0
    ) {
      return "";
    }

    return txt.get("validations.not_empty");
  };

  const valueOfNeed = (question: string, valueType: ValueType) => {
    const need: Need | undefined = needs.find(
      (need: Need) => need.question === question
    );
    if (need) {
      return valueType === ValueType.Boolean
        ? need.answer === "true"
        : need.answer;
    }
    return valueType === ValueType.Boolean ? false : "";
  };

  const renderTitle = (questions: string) => (
    <EuiFlexItem>
      <EuiTitle size="xs">
        <EuiText color="grey">
          {txt.get(`needs.questions.${questions}.title`)}
        </EuiText>
      </EuiTitle>
      <EuiSpacer size="s" />
    </EuiFlexItem>
  );

  const renderCheckNeed = (
    question: string,
    more_text?: string,
    padded: boolean = false,
    subNeeds: string[] = []
  ) => {
    const error: string = errorMessage(question);

    return (
      <EuiFlexItem>
        <EuiFormRow
          display="rowCompressed"
          fullWidth={true}
          style={padded ? { marginLeft: "25px" } : {}}
          isInvalid={!!error}
          error={error}
          isDisabled={!props.isEditable}
        >
          <EuiCheckbox
            disabled={!props.isEditable}
            readOnly={!props.isEditable}
            compressed={true}
            id={question}
            name={question}
            label={
              more_text ? (
                <EuiToolTip content={txt.get(`needs.questions.${more_text}`)}>
                  <EuiText size="s">
                    {txt.get(`needs.questions.${question}`)}
                  </EuiText>
                </EuiToolTip>
              ) : (
                <EuiText size="s">
                  {txt.get(`needs.questions.${question}`)}
                </EuiText>
              )
            }
            checked={valueOfNeed(question, ValueType.Boolean) as boolean}
            onChange={(e) => {
              onNeedChange(
                question,
                e.target.checked ? "true" : "false",
                true,
                !e.target.checked ? subNeeds : []
              );
            }}
          />
        </EuiFormRow>
        <EuiSpacer size="xs" />
      </EuiFlexItem>
    );
  };

  const renderTextNeed = (
    question: string,
    rows: number = TEXTAREA_SIZE_SMALL,
    toSentenceIntent?: "need" | "descriptive" | "motivation"
  ) => {
    const error: string = errorMessage(question);

    return (
      <EuiFlexItem style={{ position: "relative" }}>
        <EuiFormRow
          display="rowCompressed"
          fullWidth={true}
          isInvalid={!!error}
          error={error}
          isDisabled={!props.isEditable}
          // className={!props.isEditable ? "readonly-input" : ""}
        >
          <EuiTextArea
            disabled={!props.isEditable}
            readOnly={!props.isEditable}
            rows={rows}
            isInvalid={!!error}
            cols={40}
            fullWidth={true}
            onChange={(e: any) => onNeedChange(question, e.target.value, false)}
            onBlur={(e: any) => onNeedChange(question, e.target.value)}
            value={valueOfNeed(question, ValueType.String) as string}
            placeholder={txt.get(`needs.questions.${question}`)}
          />
        </EuiFormRow>
        {isTextToSentenceShown ? (
          <MMAITextToSentence
            style={{ position: "absolute", top: "5px", right: "15px" }}
            text={valueOfNeed(question, ValueType.String) as string}
            intent={toSentenceIntent}
            onResult={(sentence: string) => {
              onNeedChange(question, sentence);
            }}
          />
        ) : (
          <></>
        )}
        <EuiSpacer size="s" />
      </EuiFlexItem>
    );
  };

  const isNeed = (question: string, answer?: string) => {
    const need: Need | undefined = needs.find(
      (need: Need) => need.question === question
    );
    return need && (answer === undefined || need.answer === answer);
  };

  return (
    <EuiFlexGroup direction="column" alignItems="flexStart">
      <EuiFlexGroup
        gutterSize="xl"
        justifyContent="spaceAround"
        alignItems="flexStart"
      >
        <EuiFlexItem>
          <EuiFlexGroup
            direction="column"
            gutterSize="s"
            justifyContent="flexStart"
            alignItems="flexStart"
          >
            {renderTitle("care_needs")}

            {renderTextNeed("care_needs.needs", TEXTAREA_SIZE_NORMAL, "need")}
            {renderTextNeed(
              "care_needs.activities",
              TEXTAREA_SIZE_NORMAL,
              "descriptive"
            )}

            {renderCheckNeed("old_product.used")}
            {isNeed("old_product.used", "true")
              ? renderTextNeed(
                  "old_product.description",
                  TEXTAREA_SIZE_SMALL,
                  "descriptive"
                )
              : renderTextNeed(
                  "old_product.not_used_description",
                  TEXTAREA_SIZE_SMALL,
                  "descriptive"
                )}
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexGroup
            direction="column"
            gutterSize="s"
            justifyContent="flexStart"
            alignItems="flexStart"
          >
            {renderTitle("anatomy")}

            {renderCheckNeed("anatomy.pain")}
            {renderCheckNeed("anatomy.strength")}
            {renderCheckNeed("anatomy.mobility")}
            {renderCheckNeed("anatomy.sensibility")}
            {renderTextNeed(
              "anatomy.other",
              TEXTAREA_SIZE_NORMAL,
              "descriptive"
            )}
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexGroup
            direction="column"
            gutterSize="s"
            justifyContent="flexStart"
            alignItems="flexStart"
          >
            {renderTitle("characteristics")}

            {renderCheckNeed(
              "characteristics.allergies",
              "characteristics.allergies_more",
              false,
              [
                "characteristics.allergies_silver",
                "characteristics.allergies_silicone",
                "characteristics.allergies_nylon",
              ]
            )}
            {isNeed("characteristics.allergies", "true") ? (
              renderCheckNeed(
                "characteristics.allergies_silver",
                undefined,
                true
              )
            ) : (
              <></>
            )}
            {isNeed("characteristics.allergies", "true") ? (
              renderCheckNeed(
                "characteristics.allergies_silicone",
                undefined,
                true
              )
            ) : (
              <></>
            )}
            {isNeed("characteristics.allergies", "true") ? (
              renderCheckNeed(
                "characteristics.allergies_nylon",
                undefined,
                true
              )
            ) : (
              <></>
            )}
            {/* {renderCheckNeed("characteristics.abnormalities")} */}
            {renderCheckNeed("characteristics.skin_conditions")}
            {renderCheckNeed("characteristics.sweating")}
            {renderTextNeed(
              "characteristics.other",
              TEXTAREA_SIZE_SMALL,
              "descriptive"
            )}
            <EuiSpacer size="s" />
            {renderTitle("usage")}

            {renderCheckNeed("usage.load", "usage.load_more")}
            {renderCheckNeed("usage.hygiene")}
            {renderCheckNeed("usage.chemicals")}
            {renderTextNeed("usage.other", TEXTAREA_SIZE_SMALL, "need")}
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiFlexGroup>
  );
}

export default MMOrderNeeds;
