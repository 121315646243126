import { EuiButtonIcon } from "@elastic/eui";
import ConnectAPIHelper from "api/connect-api-helper";
import { ApiResponse, ApiResponseStatus } from "api/api-helper";
import { useState } from "react";
import { Feature, feat } from "feats";

export interface MMAITextToSentenceProps {
  text: string;
  onResult: Function;
  style?: any;
  color?: any;
  intent?: "need" | "motivation" | "descriptive";
}

const MIN_TEXT_TO_SENTENCE_LENGTH: number = 3;

function MMAITextToSentence(props: MMAITextToSentenceProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const api = new ConnectAPIHelper();

  const textToSentence = async (text: string, onResult: Function) => {
    setIsLoading(true);
    const result: ApiResponse = await api.getSentence(text, props.intent);
    if (result && result.status === ApiResponseStatus.OK) {
      onResult(result.result);
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
      }, 5000);
    } else {
      console.log("textToSentence failed", result.message);
      setIsError(true);
      setTimeout(() => {
        setIsError(false);
      }, 5000);
    }
    setIsLoading(false);
  };

  return feat(Feature.TextToSentence) ? (
    <EuiButtonIcon
      aria-label="generate"
      isDisabled={
        !props.text || props.text.length < MIN_TEXT_TO_SENTENCE_LENGTH
      }
      isLoading={isLoading}
      style={props.style ? { ...props.style } : {}}
      iconType={"bolt"}
      color={
        isSuccess ? "success" : isError ? "danger" : props.color || undefined
      }
      onClick={(e: any) => {
        textToSentence(props.text, props.onResult);
      }}
    />
  ) : (
    <></>
  );
}

export default MMAITextToSentence;
