import {
  EuiButton,
  EuiButtonIcon,
  EuiComboBox,
  EuiDragDropContext,
  EuiDraggable,
  EuiDroppable,
  EuiFieldNumber,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiIcon,
  EuiPanel,
  EuiSpacer,
  EuiSwitch,
  EuiText,
  euiDragDropCopy,
  euiDragDropReorder,
  htmlIdGenerator,
} from "@elastic/eui";
import ConnectAPIHelper from "api/connect-api-helper";
import txt from "helpers/text-helper";
import { useStateWithCallback } from "hoc/helper-hooks";
import { useEffect, useState } from "react";
import { Workflow } from "store/data/production/workflow";
import { WorkflowStep } from "store/data/production/workflow-step";

export interface MMWorkflowStepsProps {
  fields: {
    workflow: Workflow | null;
    editAllowed?: boolean;
  };
  onChange?: Function;
}

const makeId = htmlIdGenerator();

const stepsToDraggableList = (steps: WorkflowStep[]) =>
  steps.map((step: WorkflowStep) => ({ content: step, id: makeId() }));

function MMWorkflowSteps(props: MMWorkflowStepsProps) {
  const api = new ConnectAPIHelper();
  const [workflow, setWorkflow] = useStateWithCallback(props.fields.workflow);
  const [workflowSteps, setWorkflowSteps] = useState<WorkflowStep[]>(
    props.fields.workflow ? props.fields.workflow.workflow_steps : []
  );
  const [editAllowed, setEditAllowed] = useState(props.fields.editAllowed);
  const [availableSteps, setAvailableSteps] = useState<WorkflowStep[]>([]);

  const [isItemRemovable, setIsItemRemovable] = useState(false);
  const lists: any = {
    DROPPABLE_AREA_COPY_1: availableSteps,
    DROPPABLE_AREA_COPY_2: workflowSteps,
  };
  const actions: any = {
    DROPPABLE_AREA_COPY_1: setAvailableSteps,
    DROPPABLE_AREA_COPY_2: setWorkflowSteps,
  };

  useEffect(() => {
    const loadAvailableWorkflowSteps = async () => {
      const availableSteps: WorkflowStep[] =
        await api.getAvailableWorkflowSteps();
      availableSteps.push({ name: txt.get("generic.new") } as WorkflowStep);
      setAvailableSteps(availableSteps);
    };
    loadAvailableWorkflowSteps();
  }, [workflow]);

  useEffect(() => {
    if (props.onChange) {
      props.onChange(workflowSteps);
    }
  }, [workflowSteps]);
  const remove = (droppableId: any, index: number) => {
    const list = Array.from(lists[droppableId]);
    list.splice(index, 1);

    actions[droppableId](list);
  };

  const onDragUpdate = (dragInfo: any) => {
    const source: any = dragInfo.source;
    const destination: any = dragInfo.destination;
    const shouldRemove =
      !destination && source.droppableId === "DROPPABLE_AREA_COPY_2";
    setIsItemRemovable(shouldRemove);
  };

  const onNameChange = (name: string, index: number) => {
    setWorkflowSteps((steps) =>
      steps.map((step: WorkflowStep, i) => ({
        ...step,
        name: index === i ? name : step.name,
      }))
    );
  };

  const onCheckChange = (production_check: string, index: number) => {
    setWorkflowSteps((steps) =>
      steps.map((step: WorkflowStep, i) => ({
        ...step,
        production_check:
          index === i ? production_check : step.production_check,
      }))
    );
  };

  const onDurationChange = (duration: string, index: number) => {
    setWorkflowSteps((steps) =>
      steps.map((step: WorkflowStep, i) => ({
        ...step,
        duration: index === i ? duration : step.duration,
      }))
    );
  };

  const onDeliveryDayChange = (delivery_day: number, index: number) => {
    setWorkflowSteps((steps) =>
      steps.map((step: WorkflowStep, i) => ({
        ...step,
        delivery_day: index === i ? delivery_day : step.delivery_day,
      }))
    );
  };

  const onDragEnd = (dragInfo: any) => {
    const source: any = dragInfo.source;
    const destination: any = dragInfo.destination;

    if (source && destination) {
      if (source.droppableId === destination.droppableId) {
        const items = euiDragDropReorder(
          lists[destination.droppableId],
          source.index,
          destination.index
        );

        actions[destination.droppableId](items);
      } else {
        const sourceId = source.droppableId;
        const destinationId = destination.droppableId;
        const result = euiDragDropCopy(
          lists[sourceId],
          lists[destinationId],
          source,
          destination,
          {
            property: "id",
            modifier: () => 0,
          }
        );

        actions[sourceId](result[sourceId]);
        actions[destinationId](result[destinationId]);
      }
    } else if (!destination && source.droppableId === "DROPPABLE_AREA_COPY_2") {
      remove(source.droppableId, source.index);
    }
  };

  return (
    <EuiDragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
      <EuiFlexGroup>
        <EuiFlexItem style={{ width: "450px" }}>
          <EuiDroppable droppableId="DROPPABLE_AREA_COPY_2" withPanel grow>
            {workflowSteps.length ? (
              stepsToDraggableList(workflowSteps).map(
                ({ content, id }, idx) => (
                  <EuiDraggable
                    key={idx}
                    index={idx}
                    draggableId={id}
                    spacing="l"
                    isRemovable={isItemRemovable}
                  >
                    <EuiPanel>
                      <EuiFlexGroup gutterSize="none" alignItems="center">
                        <EuiFlexItem grow={false}>
                          <EuiIcon type="grab" aria-label="Drag Handle" />
                        </EuiFlexItem>
                        <EuiFlexItem
                          style={{ marginLeft: "10px", marginRight: "10px" }}
                        >
                          <EuiFlexGroup gutterSize="xs">
                            <EuiFlexItem style={{ width: "100%" }}>
                              <EuiFieldText
                                placeholder={txt.get("generic.name")}
                                name="name"
                                value={content.name}
                                onChange={(e) =>
                                  onNameChange(e.target.value, idx)
                                }
                              />
                            </EuiFlexItem>
                            <EuiFlexItem style={{ width: "100%" }}>
                              <EuiFieldText
                                placeholder={txt.get(
                                  "admin.workflows.checkbox"
                                )}
                                name="production_check"
                                value={content.production_check}
                                onChange={(e) =>
                                  onCheckChange(e.target.value, idx)
                                }
                              />
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                              <EuiFieldNumber
                                style={{ width: "80px" }}
                                placeholder={"Dur."}
                                name="duration"
                                value={content.duration}
                                onChange={(e) =>
                                  onDurationChange(e.target.value, idx)
                                }
                              />
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                              <EuiFieldNumber
                                style={{ width: "80px" }}
                                placeholder={"DD"}
                                name="delivery_day"
                                value={content.delivery_day}
                                onChange={(e) =>
                                  onDeliveryDayChange(
                                    parseInt(e.target.value),
                                    idx
                                  )
                                }
                              />
                            </EuiFlexItem>
                          </EuiFlexGroup>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                          {isItemRemovable ? (
                            <EuiIcon type="trash" color="danger" />
                          ) : (
                            <EuiButtonIcon
                              iconType="cross"
                              aria-label="Remove"
                              onClick={() =>
                                remove("DROPPABLE_AREA_COPY_2", idx)
                              }
                            />
                          )}
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    </EuiPanel>
                  </EuiDraggable>
                )
              )
            ) : (
              <EuiFlexGroup alignItems="center" justifyContent="spaceAround">
                <EuiFlexItem
                  style={{
                    minHeight: "100px",
                    height: "100px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <EuiText>{txt.get("admin.workflows.add_steps")}</EuiText>
                </EuiFlexItem>
              </EuiFlexGroup>
            )}
          </EuiDroppable>
        </EuiFlexItem>
        <EuiFlexItem style={{ width: "50%" }}>
          <EuiPanel>
            <EuiDroppable
              droppableId="DROPPABLE_AREA_COPY_1"
              cloneDraggables={true}
              spacing="l"
              grow
            >
              {stepsToDraggableList(availableSteps).map(
                ({ content, id }, idx) => (
                  <EuiDraggable
                    key={id}
                    index={idx}
                    draggableId={id}
                    spacing="l"
                  >
                    <EuiPanel>
                      <EuiFlexItem>
                        {
                          <EuiFieldText
                            disabled={true}
                            style={{ cursor: "pointer", pointerEvents: "none" }}
                            value={content.name}
                          />
                        }
                      </EuiFlexItem>
                    </EuiPanel>
                  </EuiDraggable>
                )
              )}
            </EuiDroppable>
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiDragDropContext>
  );
}
export default MMWorkflowSteps;
