import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiProgress,
  EuiSpacer,
} from "@elastic/eui";
import MMPage, { PageLink } from "components/layouts/page/page";
import txt from "helpers/text-helper";
import { adminNav } from "./admin";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import ConnectAPIHelper from "api/connect-api-helper";
import { useDispatch, useSelector } from "react-redux";
import { toastAdd } from "store/components/toast/toast";
import {
  AlertConfirmActionState,
  confirmAsk,
  confirmGet,
} from "store/components/alert/confirm";
import { LOCATION_EMPTY, Location } from "store/data/location/location";
import MMLocation from "./location";
import AuthenticationHelper from "helpers/authentication-helper";
import { REFERRER_EMPTY, Referrer } from "store/data/referrer/referrer";
import MMReferrer from "./referrer";

function MMAdminReferrer() {
  const api = new ConnectAPIHelper();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteRef] = useState("delete_referrer_" + uuid());
  const [buttonsToShow, setButtonsToShow] = useState<any[]>([]);
  const { referrerId } = useParams();

  const [referrer, setReferrer] = useState<Referrer | null>(null);
  const [specialties, setSpecialties] = useState<string[]>([]);

  const isNew: boolean = referrerId === "new";
  const [subPages, setSubPages] = useState<PageLink[]>([]);
  useEffect(() => {
    const getSubPages = async () => {
      setSubPages(await adminNav("/admin/referrers"));
    };

    getSubPages();
  }, []);

  useEffect(() => {
    const loadReferrer = async () => {
      if (isNew) {
        setReferrer(REFERRER_EMPTY);
      } else {
        const referrerIdParam: number = Number(referrerId);
        if (isNaN(referrerIdParam)) {
          navigate("/404");
        } else {
          const result = await api.getAdminReferrer(referrerIdParam);
          if (result.status === "OK" && result.result && result.result.id) {
            console.log("setting referrer", result.result);
            setReferrer(result.result);
          } else {
            navigate("/404");
          }
        }
      }

      const specialties: string[] = await api.getSpecialties();
      setSpecialties(specialties);
    };
    loadReferrer();
  }, [referrerId]);

  const saveReferrer = async (referrer: Referrer) => {
    let result;
    if (referrer.id) {
      result = await api.updateAdminReferrer(referrer.id, referrer);
    } else {
      result = await api.createAdminReferrer(referrer);
    }
    if (result.status === "OK") {
      if (!referrer.id) {
        navigate(`/admin/referrers/${result.result.referrer_id}`);
      }

      dispatch(
        toastAdd(
          txt.get("generic.is_saved", txt.get("admin.referrers.name")),
          null,
          "success"
        )
      );
    } else {
      dispatch(
        toastAdd(
          result.message
            ? result.message
            : txt.get("admin.referrers.error_saving"),
          null,
          "danger"
        )
      );
    }
  };

  const handleDelete = async () => {
    dispatch(
      confirmAsk(
        `${txt.uf("generic.delete_x", txt.get("admin.referrers.name"))}.`,
        txt.get("admin.referrers.delete_confirm"),
        deleteRef,
        null,
        null,
        `${txt.get("generic.yes")}, ${txt.lo(
          "generic.delete_x",
          txt.get("admin.referrers.name")
        )}`
      )
    );
  };

  const alertConfirm = useSelector(confirmGet);
  useEffect(() => {
    if (
      alertConfirm.actionState === AlertConfirmActionState.Perform &&
      alertConfirm.actionKey === deleteRef
    ) {
      deleteLocation();
    }
  }, [alertConfirm]);

  const deleteLocation = async () => {
    if (referrerId && !isNaN(Number(referrerId))) {
      await api.deleteAdminReferrer(Number(referrerId));
      dispatch(
        toastAdd(
          txt.get("generic.is_deleted", txt.get("admin.referrers.name")),
          null,
          "success"
        )
      );
    }
    navigate("/admin/referrers");
  };

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      let buttons: any[] = [];
      if (
        !isNew &&
        (await AuthenticationHelper.hasPermission("referrers#delete_all"))
      ) {
        buttons.push(
          <EuiButton
            size="s"
            onClick={handleDelete}
            color="danger"
            iconType={"cross"}
          >
            {txt.uf("generic.delete_x", txt.get("admin.referrers.name"))}
          </EuiButton>
        );
      }

      setButtonsToShow(buttons);
    };
    setInterfaceForPermissions();
  }, []);

  const buttons = () => {
    return buttonsToShow;
  };

  return (
    <MMPage
      title={`${txt.get("admin.page_title")} ${txt.lo("admin.referrers.name")}`}
      subTitle={`#${referrerId || txt.get("generic.new")}`}
      subPages={subPages}
      backTo="/admin/referrers"
      backToText={txt.uf(
        "generic.back_to",
        txt.get("admin.referrers.page_title")
      )}
      sideActions={buttons()}
    >
      <EuiFlexItem>
        <EuiSpacer />
        {referrer ? (
          <EuiFlexGroup>
            <EuiFlexItem>
              <MMReferrer
                handleSubmit={saveReferrer}
                fields={{ referrer, specialties }}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        ) : (
          <EuiProgress size="s" color="accent" />
        )}
      </EuiFlexItem>
    </MMPage>
  );
}
export default MMAdminReferrer;
