import {
  EuiBasicTable,
  EuiButtonEmpty,
  EuiCheckboxGroup,
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiSpacer,
  EuiText,
  EuiToolTip,
} from "@elastic/eui";
import { Fragment, useEffect, useState } from "react";

import { useLocalStorage } from "store/local-storage";
import txt from "helpers/text-helper";
import ConnectAPIHelper from "api/connect-api-helper";
import { columnString } from "hoc/helper-hooks";
import { useDebounce } from "use-debounce";
import { Note } from "store/data/note/note";
import { Workflow } from "store/data/production/workflow";
import { WorkflowStep } from "store/data/production/workflow-step";
import DateHelper from "helpers/date-helper";
import { useSearchParams } from "react-router-dom";
import UrlHelper from "helpers/url-helper";
import MMColumnFormatted from "components/layouts/table/column-formatted";
import { ApiResponse, ApiResponseStatus } from "api/api-helper";
import { renderCurrentProductionStep } from "store/data/production/production";
import { OrderLineStatus } from "store/data/order/order";

export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100]; //,0]; //0 would mean without limit
export const DEFAULT_PAGE_SIZE = 10;
export const SEARCH_DEBOUNCE_DELAY = 700;
export const DEFAULT_SORT_BY = "order_date";
export const DEFAULT_SORT_ORDER = "desc";

function MMProductionsList() {
  const api = new ConnectAPIHelper();

  const [productions, setProductions] = useState([]);
  const [workflows, setWorkflows] = useState([]);
  const [checks, setChecks] = useState([]);
  const [workflowLookup, setWorkflowLookup] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useLocalStorage(
    "productions_limit",
    DEFAULT_PAGE_SIZE
  );
  const [offset, setOffset] = useLocalStorage("productions_offset", 0);
  const [total, setTotal] = useState(0);
  const [sortBy, setSortBy] = useLocalStorage(
    "productions_sort_by",
    DEFAULT_SORT_BY
  );
  const [sortOrder, setSortOrder] = useLocalStorage(
    "productions_sort_order",
    DEFAULT_SORT_ORDER
  );
  const [searchFieldValue, setSearchFieldValue] = useLocalStorage(
    "productions_search",
    ""
  );
  const [search] = useDebounce(searchFieldValue, SEARCH_DEBOUNCE_DELAY);
  const [checkboxIdToSelectedMap, setCheckboxIdToSelectedMap] = useLocalStorage(
    "productions_search_in",
    {}
  );
  const [error, setError] = useState("");

  let [searchParams, setSearchParams] = useSearchParams();

  const handleOrderNavigate = (orderId: string | number) => {
    let params = UrlHelper.queryParams();
    params.order = orderId;
    params.order_tab = "measurements";
    setSearchParams(params, { replace: true });
  };

  useEffect(() => {
    const loadWorkflows = async () => {
      setIsLoading(true);

      const result = await api.getWorkflows();

      if (result.status === "OK") {
        setWorkflows(result.result);
        let lookup: any = {};
        result.result.forEach((workflow: Workflow) => {
          if (workflow.id) {
            lookup[workflow.id] = workflow;
          }
        });
        setWorkflowLookup(lookup);
      } else {
        setWorkflows([]);
        setWorkflowLookup({});
      }
      setIsLoading(false);
    };

    const loadChecks = async () => {
      const result = await api.getProductionChecks();
      setChecks(result);
    };
    loadWorkflows();
    loadChecks();
  }, []);

  useEffect(() => {
    console.log("checkboxIdToSelectedMap", checkboxIdToSelectedMap);
    const loadProductions = async () => {
      setIsLoading(true);

      let filters: any = {};
      if (search) {
        filters.search = search;
      }

      filters.status = [
        // OrderLineStatus.Drafted,
        // OrderLineStatus.Submitted,
        OrderLineStatus.Accepted,
        OrderLineStatus.Rejected,
        // OrderLineStatus.Cancelled,
        OrderLineStatus.Produced,
        OrderLineStatus.Packaged,
        OrderLineStatus.Routed,
        OrderLineStatus.Shipped,
        OrderLineStatus.Delivered,
        OrderLineStatus.Finalized,
      ];

      const search_in = Object.keys(checkboxIdToSelectedMap).reduce(
        (result: any[], key: any) =>
          checkboxIdToSelectedMap[key] && checkboxIdToSelectedMap[key] === true
            ? result.concat([key])
            : result,
        []
      );
      if (search_in.length > 0) {
        filters.search_in = search_in;
      }

      const result: ApiResponse = await api.getProductions(
        filters,
        limit,
        offset,
        productionsFieldToSortKey(sortBy),
        sortOrder
      );

      if (result.status === ApiResponseStatus.OK) {
        setProductions(result.result);
        setTotal(result.meta_data.result_set.total);
        setError("");
      } else {
        setProductions([]);
        setTotal(0);
        setError(`${result.status} (${result.code}): ${result.message}`);
      }
      setIsLoading(false);
    };

    loadProductions();
  }, [sortOrder, sortBy, limit, offset, search, checkboxIdToSelectedMap]);

  const resultCountInfo = () =>
    total === 0
      ? txt.uf(
          "generic.found_no_x",
          txt.get("production.productions.name_plural")
        )
      : txt.uf(
          "generic.showing_x_of_y_found_z",
          limit === 0
            ? txt.get("generic.all")
            : `${offset + 1}-${Math.min(total, offset + limit)}`,
          total,
          txt.get("production.productions.name_plural")
        ) + ".";

  const limitOffsetToPage = (limit: number, offset: number) => {
    //pages in EUI are zero based
    const page = limit > 0 ? Math.max(0, offset / limit) : 0;
    return page;
  };

  const pagination: any = {
    pageIndex: limitOffsetToPage(limit, offset),
    pageSize: limit,
    totalItemCount: total,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showPerPageOptions: true,
  };

  const sorting: any = {
    sort: {
      field: sortBy,
      direction: sortOrder,
    },
    enableAllColumns: false,
    // readOnly: false,
  };

  const productionsFieldToSortKey = (field: string) => {
    switch (field) {
      case "order":
        return "order.location";
      default:
        return field;
    }
  };

  const onProductionsChange = ({ page = {} as any, sort = {} as any }) => {
    if (page.size) {
      const newLimit = page.size;
      const newOffset = Math.max(0, page.index * page.size);
      if (limit !== newLimit) setLimit(newLimit);
      if (offset !== newOffset) setOffset(newOffset);
    }
    if (sort.field) {
      setSortBy(sort.field);
      setSortOrder(sort.direction ?? "asc");
    }
  };

  const columns = [
    {
      name: txt.get("production.productions.code"),
      field: "code",
      sortable: api.productionIsSortableBy(productionsFieldToSortKey("code")),
      render: (val: string) => (
        <MMColumnFormatted value={val} noWrap={false} highlight={search} />
      ),
    },
    {
      name: txt.get("orders.order.name"),
      field: "order_id",
      sortable: api.productionIsSortableBy(
        productionsFieldToSortKey("order_id")
      ),

      render: (val: string) => (
        <EuiButtonEmpty onClick={() => handleOrderNavigate(val)}>
          <MMColumnFormatted value={val} noWrap={true} highlight={search} />
        </EuiButtonEmpty>
      ),
    },
    {
      name: txt.get("orders.order.order_line.name"),
      field: "order_line_id",
      sortable: api.productionIsSortableBy(
        productionsFieldToSortKey("order_line_id")
      ),
      render: (val: string) => (
        <MMColumnFormatted value={val} noWrap={true} highlight={search} />
      ),
    },
    {
      name: txt.get("orders.order.client_code"),
      field: "client",
      sortable: api.productionIsSortableBy(productionsFieldToSortKey("client")),
      render: (val: string) => (
        <MMColumnFormatted value={val} noWrap={true} highlight={search} />
      ),
    },
    {
      name: txt.get("orders.order.practitioner"),
      field: "technician",
      sortable: api.productionIsSortableBy(
        productionsFieldToSortKey("technician")
      ),
      render: (val: string) => (
        <MMColumnFormatted value={val} noWrap={true} highlight={search} />
      ),
    },
    {
      name: txt.get("admin.organisations.name"),
      field: "company",
      sortable: api.productionIsSortableBy(
        productionsFieldToSortKey("company")
      ),
      render: (val: string) => (
        <MMColumnFormatted value={val} noWrap={true} highlight={search} />
      ),
    },
    {
      name: txt.get("orders.order.delivery_location"),
      field: "location",
      sortable: api.productionIsSortableBy(
        productionsFieldToSortKey("order.location")
      ),
      render: (val: string, production: any) => (
        <MMColumnFormatted
          value={val || production.delivery_location}
          noWrap={false}
          highlight={search}
        />
      ),
    },
    {
      name: txt.get("orders.order.delivery"),
      field: "order.delivery_method",
      sortable: api.productionIsSortableBy(
        productionsFieldToSortKey("order.delivery_method")
      ),
      render: columnString,
    },

    {
      name: txt.get("production.productions.planning"),
      sortable: api.productionIsSortableBy(
        productionsFieldToSortKey("order_date")
      ),
      type: "date",
      field: "order_date",
      style: { minWidth: "90px" },
      render: (order_date: string, production: any) => {
        const lastStep = workflowLookup[production.workflow?.id]
          ? workflowLookup[production.workflow?.id].workflow_steps[
              workflowLookup[production.workflow?.id].workflow_steps.length - 1
            ]
          : null;
        return (
          <EuiFlexGroup style={{ gap: "2px" }} direction="column">
            <EuiFlexItem>
              <EuiFlexGroup>
                <EuiFlexItem>
                  <MMColumnFormatted
                    value={`${txt.get("production.productions.ordered")}:`}
                    noWrap={true}
                  />
                </EuiFlexItem>
                <EuiFlexItem>
                  <MMColumnFormatted
                    value={DateHelper.toDate(order_date)}
                    noWrap={true}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFlexGroup>
                <EuiFlexItem>
                  <MMColumnFormatted
                    value={`${txt.get("production.productions.due")}:`}
                    noWrap={true}
                  />
                </EuiFlexItem>
                <EuiFlexItem>
                  <MMColumnFormatted
                    value={`${
                      lastStep
                        ? DateHelper.toDate(
                            DateHelper.addBusinessDays(
                              lastStep.delivery_day,
                              order_date
                            )
                          )
                        : "?"
                    }`}
                    noWrap={true}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiFlexGroup>
                <EuiFlexItem>
                  <MMColumnFormatted
                    value={`${txt.get("production.productions.finished")}:`}
                    noWrap={true}
                  />
                </EuiFlexItem>
                <EuiFlexItem>
                  <MMColumnFormatted
                    value={`${
                      production.finish_date
                        ? DateHelper.toDate(production.finish_date)
                        : "-"
                    }`}
                    noWrap={true}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        );
      },
    },
    {
      name: txt.get("admin.workflows.name"),
      sortable: false,
      field: "workflow.name",
      render: (val: string, production: any) => (
        <EuiFlexGroup style={{ gap: "2px" }} direction="column">
          <EuiFlexItem>
            {" "}
            <MMColumnFormatted value={val} noWrap={true} highlight={search} />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFlexGroup className="workflow-step-indicators">
              {workflowLookup[production.workflow?.id] ? (
                workflowLookup[production.workflow?.id].workflow_steps.map(
                  (step: WorkflowStep) => {
                    const log = step.production_check
                      ? production.production_check_logs.find((log: any) =>
                          step.production_check === "Final quality check"
                            ? [
                                /* Production log mapping with production check status for workflows is still dynamic - temp fix for now to match on new and old checkbox */
                                "Final quality check",
                                "Combined checkbox for planning",
                              ].includes(log.check)
                            : log.check === step.production_check
                        )
                      : null;

                    return (
                      <EuiFlexItem
                        className={`workflow-step-indicator ${
                          log ? (log.value ? "checked" : "unchecked") : ""
                        }`}
                        key={`step-${step.id}`}
                      >
                        <EuiToolTip
                          content={`${step.name} | ${step.production_check}`}
                        >
                          <span>&nbsp;</span>
                        </EuiToolTip>
                      </EuiFlexItem>
                    );
                  }
                )
              ) : (
                <></>
              )}
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiToolTip
              position="bottom"
              content={production.production_check_logs.map(
                (log: any, i: number) => (
                  <EuiText
                    size="xs"
                    key={`log-${i}`}
                    style={{
                      fontFamily: "courier new",
                      fontSize: "10px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {DateHelper.toDate(log.date)} | {log.check} |{" "}
                    {log.value === true ? "v" : "-"}
                  </EuiText>
                )
              )}
            >
              <EuiText size="xs">
                {
                  production.production_check_logs.filter(
                    (log: any) => log.value
                  ).length
                }{" "}
                {txt.get("production.productions.checked")} <br />
                {
                  production.production_check_logs.filter(
                    (log: any) => !log.value
                  ).length
                }{" "}
                {txt.get("production.productions.unchecked")}
              </EuiText>
            </EuiToolTip>
          </EuiFlexItem>
        </EuiFlexGroup>
      ),
    },
    {
      name: txt.get("production.productions.current_step"),
      render: (production: any) =>
        renderCurrentProductionStep(production, checks),
    },
    {
      name: txt.get("production.productions.order_notes"),
      sortable: false,
      field: "order_line",
      render: (order_line: any) => (
        <EuiText size="xs" style={{ maxHeight: "180px", overflowX: "auto" }}>
          {order_line &&
          order_line.measurements &&
          order_line.measurements.notes
            ? order_line.measurements.notes
            : "-"}
        </EuiText>
      ),
      style: {
        minWidth: "120px",
        maxWidth: "250px",
      },
    },
    {
      name: txt.get("production.productions.production_notes"),
      sortable: false,
      field: "notes",
      style: { minWidth: "120px", maxWidth: "250px" },
      render: (notes: Note[]) =>
        notes.length > 0 ? (
          <div style={{ display: "block" }}>
            {notes.map((note: Note, i: number) => (
              <Fragment key={`note-${i}`}>
                -
                <span
                  style={{ display: "inline", margin: "0px", fontSize: "11px" }}
                >
                  {`${note.tags?.join(" | ")}`}
                </span>
                -
                <br />
                {note.body}
                <br />
              </Fragment>
            ))}
          </div>
        ) : (
          "-"
        ),
    },
  ];

  const onSearchChange = (event: any) => {
    setSearchFieldValue(event.target.value);
  };

  const checkboxes = [
    {
      id: "production",
      label: txt.get("production.productions.code"),
    },
    {
      id: "client",
      label: txt.get("orders.order.client_code"),
    },
    {
      id: "order",
      label: txt.get("orders.order.name"),
    },
    {
      id: "line",
      label: txt.get("orders.order.order_line.name"),
    },
    {
      id: "practitioner",
      label: txt.get("orders.order.practitioner"),
    },
    {
      id: "company",
      label: txt.get("admin.organisations.name"),
    },
    {
      id: "location",
      label: txt.get("orders.order.delivery_location"),
    },
  ];

  const onChange = (optionId: any) => {
    const newCheckboxIdToSelectedMap = {
      ...checkboxIdToSelectedMap,
      ...{
        [optionId]: !checkboxIdToSelectedMap[optionId],
      },
    };
    setCheckboxIdToSelectedMap(newCheckboxIdToSelectedMap);
  };

  return (
    <Fragment>
      <EuiFlexGroup alignItems="flexStart">
        <EuiFlexItem grow={false}>
          <EuiFieldSearch
            compressed={true}
            placeholder={txt.get("generic.search")}
            value={searchFieldValue}
            isLoading={isLoading}
            isClearable={!isLoading}
            contentEditable={!isLoading}
            onChange={(event: any) => onSearchChange(event)}
            aria-label={txt.get("generic.search")}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCheckboxGroup
            style={{ display: "flex", alignItems: "center", gap: "15px" }}
            options={checkboxes}
            idToSelectedMap={checkboxIdToSelectedMap}
            onChange={(id) => onChange(id)}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup alignItems="flexEnd">
        <EuiFlexItem>
          <EuiText textAlign="right" size="xs">
            {resultCountInfo()}
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="s" />
      <EuiHorizontalRule margin="none" style={{ height: 1 }} />
      <EuiBasicTable
        loading={isLoading}
        tableLayout="auto"
        itemId="id"
        items={productions}
        columns={columns}
        pagination={pagination}
        sorting={sorting}
        noItemsMessage={
          error
            ? error
            : txt.uf(
                "generic.found_no_x",
                txt.get("production.productions.name_plural")
              )
        }
        onChange={onProductionsChange}
      />
    </Fragment>
  );
}

export default MMProductionsList;
