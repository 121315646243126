import { Hand } from "../scan/scan";

export enum IndicationSide {
  Left = "LEFT",
  Right = "RIGHT",
  NotApplicable = "NOT_APPLICABLE",
}

export enum IndicationBodyPart {
  Hand = "HAND",
  Arm = "ARM",
  Elbow = "ELBOW",
  Shoulder = "SHOULDER",
  Foot = "FOOT",
  Ankle = "ANKLE",
  Knee = "KNEE",
  Leg = "LEG",
  Back = "BACK",
}

export enum IndicationLocation {
  IP1 = "IP 1",
  MCP1 = "MCP 1",
  CMC1 = "CMC 1",
  DIP2 = "DIP 2",
  DIP3 = "DIP 3",
  DIP4 = "DIP 4",
  DIP5 = "DIP 5",
  PIP2 = "PIP 2",
  PIP3 = "PIP 3",
  PIP4 = "PIP 4",
  PIP5 = "PIP 5",
  MCP2 = "MCP 2",
  MCP3 = "MCP 3",
  MCP4 = "MCP 4",
  MCP5 = "MCP 5",
  STT = "STT",
  Trapezoid = "TRAPEZOID",
  Trapezium = "TRAPEZIUM",
  Hamate = "HAMATE",
  Capitate = "CAPITATE",
  IMC3 = "IMC 3",
  IMC4 = "IMC 4",
  IMC5 = "IMC 5",
}

export const INDICATION_GROUPED_EMPTY: IndicationGrouped = {
  sides: [],
  indication: "",
  indications: [],
};
export const INDICATION_EMPTY: Indication = {
  id: null,
  side: null,
  body_part: null,
  location: null,
  indication: "",
  referral_id: null,
};

export interface IndicationGrouped {
  sides: IndicationSide[];
  indication: string;
  indications: Indication[];
}
export interface Indication {
  id: number | null;
  side: IndicationSide | null;
  body_part: IndicationBodyPart | null;
  location: IndicationLocation | null;
  indication: string | null;
  referral_id: number | null;
}

export const indicationDescription = (
  indications: Indication[],
  side?: Hand | IndicationSide | string
) => {
  let indicationDescriptions: string[] = indications
    .filter((indication: Indication) =>
      side && indication.side
        ? indication.side?.toString().toLowerCase().substring(0, 1) ===
          side.toString().toLowerCase().substring(0, 1)
        : true
    )
    .map((indication: Indication) => indication.indication || "")
    .filter(
      (value, index, array) => value !== "" && array.indexOf(value) === index
    );

  return indicationDescriptions.join(", ");
};

export const indicationsGrouped = (indications: Indication[]) => {
  let groupedIndications: IndicationGrouped[] = [];
  for (let i = 0; i < indications.length; i++) {
    const indication: Indication = indications[i];
    let groupedIndication: IndicationGrouped | undefined =
      groupedIndications.find(
        (current: IndicationGrouped) =>
          current.indication === (indication.indication || "")
      );
    console.log("found", i, groupedIndications.length, {
      ...groupedIndication,
    });
    if (!groupedIndication) {
      groupedIndication = {
        sides: indication.side ? [indication.side] : [],
        indication: indication.indication || "",
        indications: [{ ...indication }],
      };
      groupedIndications.push(groupedIndication);
    } else {
      if (indication.side) {
        groupedIndication.sides.push(indication.side);
      }
      groupedIndication.indications.push(indication);
    }
  }
  // console.log("indicationsGrouped", indications, groupedIndications);
  return groupedIndications;
};

export const indicationsUngrouped = (
  groupedIndications: IndicationGrouped[]
) => {
  let indications: Indication[] = [];
  for (let i = 0; i < groupedIndications.length; i++) {
    const groupedIndication: IndicationGrouped = groupedIndications[i];
    if (groupedIndication.indications.length === 0) {
      indications.push({
        ...INDICATION_EMPTY,
        indication: groupedIndication.indication,
      });
    } else {
      indications = indications.concat(groupedIndication.indications);
    }
  }
  // console.log("indicationsUngrouped", groupedIndications, indications);
  return indications;
};
