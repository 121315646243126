import {
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiFilterButton,
  EuiFilterGroup,
  EuiFlexGrid,
  EuiFlexItem,
  EuiFormRow,
  EuiIcon,
  EuiPopover,
  EuiText,
} from "@elastic/eui";
import { useEffect, useState } from "react";
import { ORDER_EMPTY, Order } from "store/data/order/order";
import { MMOrderEntryInputProps } from "./order-entry";
import txt from "helpers/text-helper";
import { updateField } from "hoc/helper-hooks";

function MMOrderCompletion(props: MMOrderEntryInputProps) {
  const [isComplete, setIsComplete] = useState<boolean | null>(null);
  const [order, setOrder] = useState<Order | null>(props.order || null);
  const [selectedReasons, setSelectedReasons] = useState<any[]>([]);
  const [reasons, setReasons] = useState<any[]>(props.incompleteReasons || []);

  const [isEditCompletionOpen, setIsEditCompletionOpen] =
    useState<boolean>(false);

  useEffect(() => {
    setOrder(props.order || null);
    if (props.order && props.order.is_complete !== undefined) {
      setIsComplete(props.order.is_complete);
    } else {
      setIsComplete(null);
    }
  }, [props.order]);

  useEffect(() => {
    setSelectedReasons(
      order && order.incomplete_reasons
        ? order.incomplete_reasons
            .split(",")
            .map((reason: string) => ({ label: reason }))
        : []
    );

    if (props.incompleteReasons && props.incompleteReasons.map) {
      setReasons(
        props.incompleteReasons.map((reason: string) => ({ label: reason }))
      );
    } else {
      setReasons([]);
    }
  }, [props.incompleteReasons, order]);

  const onNewReason = (
    searchValue: string,
    flattenedOptions: EuiComboBoxOptionOption<unknown>[]
  ) => {
    const normalizedSearchValue = searchValue.trim().toLowerCase();

    if (!normalizedSearchValue) {
      return;
    }

    const newOption = {
      //   value: searchValue,
      label: searchValue,
    };
    if (
      flattenedOptions.findIndex(
        (option) => option.label.trim().toLowerCase() === normalizedSearchValue
      ) === -1
    ) {
      //   setSelectedReasons([...selectedReasons, newOption]);
      onSelectedReasonsChanged([...selectedReasons, newOption]);
      setReasons([...reasons, newOption]);
    }
  };

  const onIsCompleteChange = (newIsComplete: boolean) => {
    let changedIsComplete: null | boolean =
      isComplete === newIsComplete ? null : newIsComplete;

    console.log("onIsCompleteChange", {
      isComplete,
      newIsComplete,
      changedIsComplete,
    });
    if (props.onChange) {
      props.onChange(
        updateField(order || ORDER_EMPTY, "is_complete", changedIsComplete)
      );
    } else {
      setIsComplete(changedIsComplete);
    }
  };

  const onSelectedReasonsChanged = (selected: any[]) => {
    if (props.onChange) {
      const selectedString: string = selected
        .map((selection: any) => selection["data-id"] || selection.label)
        .join(",");
      console.log("onSelectedReasonsChanged", selectedString);

      props.onChange(
        updateField(
          {
            ...ORDER_EMPTY,
            ...props.order,
          },
          "incomplete_reasons",
          selectedString
        )
      );
    } else {
      setSelectedReasons(selected);
    }
  };
  return order && order.id > 0 ? (
    <EuiPopover
      id="save-status"
      button={
        <EuiFlexGrid
          className="order-completion"
          gutterSize="s"
          onClick={() => setIsEditCompletionOpen(!isEditCompletionOpen)}
        >
          <EuiFlexItem grow={true}>
            <EuiText
              size="s"
              color={
                isComplete === true
                  ? "success"
                  : isComplete === false
                  ? "danger"
                  : "text"
              }
            >
              {isComplete === true
                ? txt.get("orders.order.completion.is_complete")
                : isComplete === false
                ? txt.get("orders.order.completion.is_incomplete")
                : txt.get("orders.order.completion.completion_unknown")}
              &nbsp;
              <EuiIcon
                size="s"
                type={
                  isComplete === true
                    ? "check"
                    : isComplete === false
                    ? "cross"
                    : "plus"
                }
              />
            </EuiText>
          </EuiFlexItem>
          {order.incomplete_reasons ? (
            <EuiFlexGrid gutterSize="none">
              {order.incomplete_reasons
                .split(",")
                .map((reason: string, i: number) => (
                  <EuiText
                    key={`reason-${i}`}
                    size="xs"
                    color={isComplete === false ? "danger" : "text"}
                  >
                    <EuiIcon size="s" type="errorFilled" />
                    &nbsp;{reason}
                  </EuiText>
                ))}
            </EuiFlexGrid>
          ) : (
            <></>
          )}
        </EuiFlexGrid>
      }
      isOpen={isEditCompletionOpen}
      closePopover={() => setIsEditCompletionOpen(false)}
      panelPaddingSize="none"
      anchorPosition="downLeft"
      //   style={{ height: "100%" }}
    >
      <EuiFlexGrid gutterSize="s" style={{ padding: "10px" }}>
        <EuiFlexItem grow={true}>
          <EuiFormRow fullWidth={true}>
            <EuiFilterGroup
              contentEditable={false}
              compressed={true}
              fullWidth={true}
            >
              <EuiFilterButton
                className="danger"
                grow={true}
                isDisabled={!props.isEditable}
                hasActiveFilters={isComplete === false}
                onClick={(e: any) => {
                  onIsCompleteChange(false);
                }}
              >
                <EuiIcon type="cross" />
                &nbsp;{txt.get("orders.order.completion.is_incomplete")}
              </EuiFilterButton>
              <EuiFilterButton
                grow={true}
                isDisabled={!props.isEditable}
                hasActiveFilters={isComplete === true}
                onClick={(e: any) => {
                  onIsCompleteChange(true);
                }}
              >
                <EuiIcon type="check" />
                &nbsp;{txt.get("orders.order.completion.is_complete")}
              </EuiFilterButton>
            </EuiFilterGroup>
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem grow={true}>
          <EuiFormRow>
            <EuiComboBox
              isDisabled={!props.isEditable}
              style={{ width: "270px" }}
              // singleSelection={{ asPlainText: true }}
              // isLoading={isLoading}
              color="accent"
              id="order-incomplete-reasons"
              fullWidth
              placeholder={txt.get("orders.order.completion.reasons")}
              options={reasons}
              selectedOptions={selectedReasons}
              onChange={(selected) => onSelectedReasonsChanged(selected)}
              // onCreateOption={onNewReason}
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGrid>
    </EuiPopover>
  ) : (
    <></>
  );
}

export default MMOrderCompletion;
