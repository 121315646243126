import StringHelper from "helpers/string-helper";
import { Addressee } from "../adress/address";
import { ContactMethod } from "../contactmethod/contactmethod";
import { Organisation } from "../organisation/organisation";
import { AssignedDevice } from "../scan/scanner";

export enum InvoicingOption {
  Single = "SINGLE",
  Combined = "COMBINED",
  Unknown = "UNKNOWN",
}

export enum PlanningByOption {
  Unknown = "UNKNOWN",
  Both = "BOTH",
  Location = "LOCATION",
  Manometric = "MANOMETRIC",
  LocationExclusively = "LOCATION_EXCLUSIVELY",
  ManometricLocationExclusive = "MANOMETRIC_LOCATION_EXCLUSIVE",
}

export const LOCATION_EMPTY: Location = {
  id: null,
  name: "",
  city: "",
  is_active: true,
  legacy_naming: {},
  organisation_id: null,
  addressees: [],
  contact_methods: [],
  invoicing: InvoicingOption.Unknown,
  vat_number: "",
  coc_number: "",
  planning_by: PlanningByOption.Unknown,
  assigned_devices: [],
};

export interface Location {
  id: number | null;
  name: string;
  city?: string;
  details?: string;
  organisation_id: number | null;
  is_active: boolean;
  legacy_naming: {
    master_sheet?: string;
    vlot?: string;
  };
  invoicing: InvoicingOption;
  vat_number: string;
  coc_number: string;
  planning_by: PlanningByOption;
  organisation?: Organisation;
  contact_methods: ContactMethod[];
  addressees: Addressee[];
  assigned_devices: AssignedDevice[];
}

export const viewName = (location: Location) => {
  let result = "-";
  if (location.name && location.city && location.details) {
    if (location.name.toLowerCase().indexOf(location.city.toLowerCase()) >= 0) {
      result = `${location.name} ${location.details}`;
    } else {
      result = `${location.name} ${location.details} ${location.city}`;
    }
  } else if (location.name && location.city) {
    if (location.name.toLowerCase().indexOf(location.city.toLowerCase()) >= 0) {
      result = `${location.name}`;
    } else {
      result = `${location.name} ${location.city}`;
    }
  } else if (location.name) {
    result = location.name;
  }
  return result;
};

export const formatLocation = (location: any) => {
  if (typeof location === "string") {
    const parts = location.split("_");
    if (parts.length >= 3) {
      return `${parts[0]} (${parts[1]}, ${[parts[2]]})`;
    }
    if (parts.length >= 2) {
      return `${parts[0]} (${parts[1]})`;
    }
    if (parts.length >= 1) {
      return parts[0];
    }
    return location;
  }
  if (location.details && location.city && location.name) {
    return `${location.name} (${location.city}, ${location.details})`;
  }
  if (location.city && location.name) {
    return StringHelper.endsWith(location.name, location.city)
      ? `${location.name.substring(
          0,
          location.name.lastIndexOf(location.city)
        )} (${location.city})`
      : `${location.name} (${location.city})`;
    // return `${location.name} (${location.city})`;
  }
  return location.name;
};

export const formatLocationValue = (location: any) => {
  if (location.legacy_naming && location.legacy_naming.master_sheet) {
    return location.legacy_naming.master_sheet;
  }

  if (location.details && location.city && location.name) {
    return `${location.name}_${location.city}_${location.details}`;
  }
  if (location.city && location.name) {
    return `${location.name}_${location.city}`;
  }
  return location.name;
};
