

declare const window: any;
const env = (key:string):any => {
    if (window.env && window.env[key]) return window.env[key].trim();
    if (process.env && process.env[key]) return process.env[key]?.trim();
    return '';

}

export default env;
  