import {
  EuiBasicTable,
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiLink,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";
import { Fragment, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "store/local-storage";
import DateHelper from "helpers/date-helper";
import txt from "helpers/text-helper";
import ConnectAPIHelper from "api/connect-api-helper";
import { Organisation } from "store/data/organisation/organisation";
import { MMLink } from "components/layouts/navigation/link";
import { columnDateShort } from "hoc/helper-hooks";
import { useDebounce } from "use-debounce";
import MMColumnFormatted from "components/layouts/table/column-formatted";

export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100]; //,0]; //0 would mean without limit
export const DEFAULT_PAGE_SIZE = 10;
export const SEARCH_DEBOUNCE_DELAY = 700;
export const DEFAULT_SORT_BY = "name";
export const DEFAULT_SORT_ORDER = "asc";

function MMOrganisationsList() {
  const navigate = useNavigate();
  const api = new ConnectAPIHelper();

  const [organisations, setOrganisations] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useLocalStorage(
    "organisation_limit",
    DEFAULT_PAGE_SIZE
  );
  const [offset, setOffset] = useLocalStorage("organisation_offset", 0);
  const [total, setTotal] = useState(0);
  const [sortBy, setSortBy] = useLocalStorage(
    "organisation_sort_by",
    DEFAULT_SORT_BY
  );
  const [sortOrder, setSortOrder] = useLocalStorage(
    "organisation_sort_order",
    DEFAULT_SORT_ORDER
  );
  const [searchFieldValue, setSearchFieldValue] = useLocalStorage(
    "organisation_search",
    ""
  );
  const [search] = useDebounce(searchFieldValue, SEARCH_DEBOUNCE_DELAY);
  const [error, setError] = useState("");

  useEffect(() => {
    const loadOrganisations = async () => {
      let filters: any = {};
      if (search) {
        filters.search = search;
      }

      setIsLoading(true);
      const result = await api.getAdminOrganisations(
        filters,
        limit,
        offset,
        organisationFieldToSortKey(sortBy),
        sortOrder
      );
      if (result.status === "OK") {
        setOrganisations(result.result);
        setTotal(result.meta_data.result_set.total);
        setError("");
      } else {
        setOrganisations([]);
        setTotal(0);
        setError(`${result.status} (${result.code}): ${result.message}`);
      }
      setIsLoading(false);
    };

    loadOrganisations();
  }, [sortOrder, sortBy, limit, offset, search]);

  const resultCountInfo = () =>
    total === 0
      ? txt.uf("generic.found_no_x", txt.get("admin.organisations.page_title"))
      : txt.uf(
          "generic.showing_x_of_y_found_z",
          limit === 0
            ? txt.get("generic.all")
            : `${offset + 1}-${Math.min(total, offset + limit)}`,
          total,
          txt.get("admin.organisations.page_title")
        ) + ".";

  const limitOffsetToPage = (limit: number, offset: number) => {
    //pages in EUI are zero based
    const page = limit > 0 ? Math.max(0, offset / limit) : 0;
    return page;
  };

  const pagination: any = {
    pageIndex: limitOffsetToPage(limit, offset),
    pageSize: limit,
    totalItemCount: total,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showPerPageOptions: true,
  };

  const sorting: any = {
    sort: {
      field: sortBy,
      direction: sortOrder,
    },
    enableAllColumns: false,
    // readOnly: false,
  };

  const organisationFieldToSortKey = (field: string) => {
    switch (field) {
      default:
        return field;
    }
  };

  const onOrganisationsChange = ({ page = {} as any, sort = {} as any }) => {
    if (page.size) {
      const newLimit = page.size;
      const newOffset = Math.max(0, page.index * page.size);
      if (limit !== newLimit) setLimit(newLimit);
      if (offset !== newOffset) setOffset(newOffset);
    }
    if (sort.field) {
      setSortBy(sort.field);
      setSortOrder(sort.direction ?? "asc");
    }
  };

  const columns = [
    {
      name: txt.get("admin.organisations.id"),
      field: "id",
      sortable: api.organisationIsSortableBy(organisationFieldToSortKey("id")),
    },
    {
      name: txt.get("admin.organisations.name"),
      field: "name",
      sortable: api.organisationIsSortableBy(
        organisationFieldToSortKey("name")
      ),
      render: (name: string, organisation: Organisation) =>
        name ? (
          <MMLink href={`/admin/organisations/${organisation.id}`}>
            <MMColumnFormatted value={name} noWrap={true} highlight={search} />
          </MMLink>
        ) : (
          "-"
        ),
    },
    {
      name: txt.get("admin.organisations.acronym"),
      field: "acronym",
      type: "string",
      sortable: api.organisationIsSortableBy(
        organisationFieldToSortKey("acronym")
      ),
      render: (acronym: string) =>
        acronym ? (
          <MMColumnFormatted value={acronym} noWrap={true} highlight={search} />
        ) : (
          "-"
        ),
    },
    {
      name: txt.get("admin.organisations.client_reference"),
      sortable: api.organisationIsSortableBy(
        organisationFieldToSortKey("client_reference")
      ),
      type: "string",
      field: "client_reference",
      render: (client_reference: string) =>
        client_reference ? `"${txt.get(client_reference)}"` : "-",
    },
    {
      name: txt.get("admin.organisations.client_naming"),
      sortable: api.organisationIsSortableBy(
        organisationFieldToSortKey("client_naming")
      ),
      type: "string",
      field: "client_naming",
      render: (client_naming: string) =>
        client_naming ? `"${txt.get(`${client_naming}.name`)}"` : "-",
    },
    {
      name: txt.get("admin.organisations.ordering"),
      sortable: api.organisationIsSortableBy(
        organisationFieldToSortKey("ordering")
      ),
      type: "string",
      field: "ordering",
      render: (ordering: string) =>
        txt.get(
          `admin.organisations.ordering_option.${ordering.toLowerCase()}`
        ),
    },
    {
      name: txt.get("generic.created_at"),
      sortable: api.organisationIsSortableBy(
        organisationFieldToSortKey("created_at")
      ),
      type: "date",
      field: "created_at",
      render: columnDateShort,
    },
    {
      name: txt.get("generic.updated_at"),
      sortable: api.organisationIsSortableBy(
        organisationFieldToSortKey("updated_at")
      ),
      type: "date",
      field: "updated_at",
      render: columnDateShort,
    },
  ];

  const getRowProps = (organisation: any) => {
    const { id } = organisation;
    return {
      "data-id": `row-${id}`,
      onClick: (e: any) => {
        if (
          e.target.tagName !== "BUTTON" &&
          e.target.tagName !== "INPUT" &&
          e.target.tagName !== "A"
        ) {
          navigate(`/admin/organisations/${id}`);
        }
      },
    };
  };

  const onSearchChange = (event: any) => {
    setSearchFieldValue(event.target.value);
  };
  return (
    <Fragment>
      <EuiFlexGroup alignItems="flexStart">
        <EuiFlexItem grow={false}>
          <EuiFieldSearch
            compressed={true}
            placeholder={txt.get("generic.search")}
            value={searchFieldValue}
            isLoading={isLoading}
            isClearable={!isLoading}
            contentEditable={!isLoading}
            onChange={(event: any) => onSearchChange(event)}
            aria-label={txt.get("generic.search")}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup alignItems="flexEnd">
        <EuiFlexItem>
          <EuiText textAlign="right" size="xs">
            {resultCountInfo()}
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="s" />
      <EuiHorizontalRule margin="none" style={{ height: 1 }} />
      <EuiBasicTable
        loading={isLoading}
        tableLayout="auto"
        itemId="id"
        items={organisations}
        columns={columns}
        pagination={pagination}
        sorting={sorting}
        rowProps={getRowProps}
        noItemsMessage={
          error
            ? error
            : txt.uf(
                "generic.found_no_x",
                txt.get("admin.organisations.page_title")
              )
        }
        onChange={onOrganisationsChange}
      />
    </Fragment>
  );
}

export default MMOrganisationsList;
