import { Person } from "../adress/address";
import { Organisation } from "../organisation/organisation";

export const REFERRER_EMPTY: Referrer = {
  id: null,
  person_id: null,
  organisation_id: null,
  organisation: null,
  person: null,
  specialty: "",
  alternative_organisation: "",
  agb_code: "",
  organisation_agb_code: "",
  note: "",
  is_active: true,
};

export interface Referrer {
  id: number | null;
  person_id: number | null;
  organisation_id: number | null;
  organisation: Organisation | null;
  person: Person | null;
  specialty: string;
  alternative_organisation: string;
  agb_code: string;
  note: string;
  organisation_agb_code: string;
  is_active: boolean;
}
