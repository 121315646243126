import MMPage from "components/layouts/page/page";
import {
  EuiBadge,
  EuiDescriptionList,
  EuiFlexGroup,
  EuiFlexItem,
  EuiProgress,
  EuiSpacer,
  EuiText,
  euiPaletteForStatus,
} from "@elastic/eui";
import { Fragment, useEffect, useState } from "react";
import txt from "helpers/text-helper";
import ConnectAPIHelper from "api/connect-api-helper";
import { OrganisationOrdering } from "store/data/organisation/organisation";
import {
  AccessorFn,
  Axis,
  BarSeries,
  Chart,
  LineSeries,
  PartialTheme,
  ScaleType,
  Settings,
} from "@elastic/charts";
import { EUI_CHARTS_THEME_LIGHT } from "@elastic/eui/dist/eui_charts_theme";

function MMSales() {
  const api = new ConnectAPIHelper();
  const [salesFigures, setSalesFigures] = useState<any>([]);

  useEffect(() => {
    const loadSalesFigures = async () => {
      const result = await api.getOrderCounts();
      setSalesFigures(result);
    };
    loadSalesFigures();
  }, []);

  const totalOrderCount = (
    counts: any[],
    counter: any = { counter: "count", aggregator: "sum" }
  ): number => {
    let count: number = 0;
    let detr: number = 0;
    let prom: number = 0;
    const agg: string =
      counter && counter.aggregator ? counter.aggregator : "sum";
    const cnt: string = counter && counter.counter ? counter.counter : "count";

    const result: number = salesFigures.reduce(
      (total: number, orderCount: any) => {
        const toAdd: any = counts.find((count: any) => {
          const keys = Object.keys(count);
          let result: boolean = true;
          keys.forEach((key) => {
            result = result && count[key].includes(orderCount[key]);
          });
          return result;
        });
        if (toAdd) {
          if (agg === "nps") {
            const value: number = parseInt(orderCount[cnt]);
            const promoters: number = parseInt(orderCount.promoters);
            const detractors: number = parseInt(orderCount.detractors);

            if (!isNaN(value)) {
              count += parseInt(orderCount.count);
              total += value;
            }
            if (!isNaN(promoters)) {
              prom += promoters;
            }
            if (!isNaN(detractors)) {
              detr += detractors;
            }
          } else {
            const value: number = parseInt(orderCount[cnt]);
            if (!isNaN(value)) {
              count += parseInt(orderCount.count);
              total += agg === "avg" ? orderCount.count * value : value;
            }
          }
        } else {
          return isNaN(total) ? 0 : total;
        }
        return total;
      },
      0
    );
    if (agg === "avg") {
      return count > 0 ? result / count : result;
    } else if (agg === "nps") {
      const promoterPercentage: number = (count > 0 ? prom / result : 0) * 100;
      const detractorPercentage: number = (count > 0 ? detr / result : 0) * 100;
      const nps: number = promoterPercentage - detractorPercentage;
      return isNaN(nps) ? 0 : Math.floor(nps);
    }
    return result;
  };

  const orderCount = (ordering: OrganisationOrdering) => {
    return totalOrderCount([
      {
        organisation_ordering: ordering,
      },
    ]);
  };

  const reviewCount = (ordering: OrganisationOrdering) => {
    return totalOrderCount(
      [
        {
          organisation_ordering: ordering,
        },
      ],
      { counter: "count_score", aggregator: "sum" }
    );
  };

  const reviewAvg = (ordering: OrganisationOrdering) => {
    return totalOrderCount(
      [
        {
          organisation_ordering: ordering,
        },
      ],
      { counter: "average_score", aggregator: "avg" }
    );
  };

  const nps = (ordering: OrganisationOrdering) => {
    return totalOrderCount(
      [
        {
          organisation_ordering: ordering,
        },
      ],
      { counter: "count_score", aggregator: "nps" }
    );
  };

  const promoters = (ordering: OrganisationOrdering) => {
    return totalOrderCount(
      [
        {
          organisation_ordering: ordering,
        },
      ],
      { counter: "promoters", aggregator: "sum" }
    );
  };

  const detractors = (ordering: OrganisationOrdering) => {
    return totalOrderCount(
      [
        {
          organisation_ordering: ordering,
        },
      ],
      { counter: "detractors", aggregator: "sum" }
    );
  };

  const renderNPSScores = () => {
    if (!salesFigures || salesFigures.length === 0) {
      return <EuiProgress size="s" color="accent" />;
    }
    const mmNps = nps(OrganisationOrdering.Internal);
    const dwNps = nps(OrganisationOrdering.External);
    const b2bNps = nps(OrganisationOrdering.B2B);
    return (
      <EuiFlexGroup>
        <EuiDescriptionList
          // type="responsiveColumn"
          listItems={[
            {
              title: "Manometric",
              description: (
                <EuiFlexGroup direction="column" gutterSize="none">
                  <EuiFlexItem>
                    <EuiText>
                      {txt.get("orders.page_title")}:&nbsp;
                      {orderCount(OrganisationOrdering.Internal)}
                    </EuiText>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiText>
                      {txt.get("generic.evaluated")}:&nbsp;
                      {reviewCount(OrganisationOrdering.Internal)}
                    </EuiText>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiText>
                      {txt.get("sales.nps.average")}:&nbsp;
                      {reviewAvg(OrganisationOrdering.Internal)}
                    </EuiText>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiText>
                      <EuiBadge
                        color={
                          mmNps >= 50
                            ? "success"
                            : mmNps >= 0
                            ? "warning"
                            : "danger"
                        }
                      >
                        {txt.get("sales.nps.acronym")}:&nbsp;{mmNps}
                      </EuiBadge>
                    </EuiText>
                  </EuiFlexItem>
                </EuiFlexGroup>
              ),
            },
          ]}
        />
        <EuiDescriptionList
          // type="responsiveColumn"
          listItems={[
            {
              title: "Direct Workflow",
              description: (
                <EuiFlexGroup direction="column" gutterSize="none">
                  <EuiFlexItem>
                    <EuiText>
                      {txt.get("orders.page_title")}:&nbsp;
                      {orderCount(OrganisationOrdering.External)}
                    </EuiText>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiText>
                      {txt.get("generic.evaluated")}:&nbsp;
                      {reviewCount(OrganisationOrdering.External)}
                    </EuiText>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiText>
                      {txt.get("sales.nps.average")}:&nbsp;
                      {reviewAvg(OrganisationOrdering.External)}
                    </EuiText>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiText>
                      <EuiBadge
                        color={
                          dwNps >= 50
                            ? "success"
                            : dwNps >= 0
                            ? "warning"
                            : "danger"
                        }
                      >
                        {txt.get("sales.nps.acronym")}:&nbsp;{dwNps}
                      </EuiBadge>
                    </EuiText>
                  </EuiFlexItem>
                </EuiFlexGroup>
              ),
            },
          ]}
        />
        <EuiDescriptionList
          // type="responsiveColumn"
          listItems={[
            {
              title: "B2B",
              description: (
                <EuiFlexGroup direction="column" gutterSize="none">
                  <EuiFlexItem>
                    <EuiText>
                      {txt.get("orders.page_title")}:&nbsp;
                      {orderCount(OrganisationOrdering.B2B)}
                    </EuiText>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiText>
                      {txt.get("generic.evaluated")}:&nbsp;
                      {reviewCount(OrganisationOrdering.B2B)}
                    </EuiText>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiText>
                      {txt.get("sales.nps.average")}:&nbsp;
                      {reviewAvg(OrganisationOrdering.B2B)}
                    </EuiText>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiText>
                      <EuiBadge
                        color={
                          b2bNps >= 50
                            ? "success"
                            : b2bNps >= 0
                            ? "warning"
                            : "danger"
                        }
                      >
                        {txt.get("sales.nps.acronym")}:&nbsp;{b2bNps}
                      </EuiBadge>
                    </EuiText>
                  </EuiFlexItem>
                </EuiFlexGroup>
              ),
            },
          ]}
        />{" "}
      </EuiFlexGroup>
    );
  };

  const theme: PartialTheme = {
    barSeriesStyle: {
      displayValue: {
        fontSize: 10,
        fontFamily: "'Open Sans', Helvetica, Arial, sans-serif",
        fontStyle: "normal",
        padding: 0,
        fill: "#000",
        offsetX: 0,
        offsetY: 0,
      },
    },
  };

  const displayValueSettings = {
    showValueLabel: true,
    isAlternatingValueLabel: false,

    // overflowConstraints: true,
  };

  const yAccessorFn: AccessorFn = (d: any) => d.y || 0;

  const splitAccessorFn: AccessorFn = (d: any) => `${d.g}`;
  // const splitAccessorFn: AccessorFn = (d: any) => `${d.g} (${d.count})`;

  const renderEvaluationsChart = () => (
    <Chart
      title={txt.get("sales.nps.evaluations")}
      size={{ height: 200, width: 400 }}
    >
      <Settings
        theme={theme}
        // baseTheme={EUI_CHARTS_THEME_LIGHT.theme}
        // // showLegend={true}
        // // showLegendExtra
        // legendPosition="right"
        // showLegendDisplayValue={false}
      />
      <BarSeries
        id="bars"
        displayValueSettings={displayValueSettings}
        xScaleType={ScaleType.Ordinal}
        yScaleType={ScaleType.Linear}
        xAccessor="x"
        yAccessors={[yAccessorFn]}
        stackAccessors={["x"]}
        splitSeriesAccessors={[splitAccessorFn]}
        data={[
          {
            x: "mm",
            count: reviewCount(OrganisationOrdering.Internal),
            y:
              reviewCount(OrganisationOrdering.Internal) /
              orderCount(OrganisationOrdering.Internal),
            g: "evaluated",
          },
          {
            x: "mm",
            count:
              orderCount(OrganisationOrdering.Internal) -
              reviewCount(OrganisationOrdering.Internal),
            y:
              (orderCount(OrganisationOrdering.Internal) -
                reviewCount(OrganisationOrdering.Internal)) /
              orderCount(OrganisationOrdering.Internal),
            g: "not evaluated",
          },

          {
            x: "dw",
            count:
              orderCount(OrganisationOrdering.External) -
              reviewCount(OrganisationOrdering.External),
            y:
              (orderCount(OrganisationOrdering.External) -
                reviewCount(OrganisationOrdering.External)) /
              orderCount(OrganisationOrdering.External),
            g: "not evaluated",
          },
          {
            x: "dw",
            count: reviewCount(OrganisationOrdering.External),
            y:
              reviewCount(OrganisationOrdering.External) /
              orderCount(OrganisationOrdering.External),
            g: "evaluated",
          },
          {
            x: "b2b",
            count: reviewCount(OrganisationOrdering.B2B),
            y:
              reviewCount(OrganisationOrdering.B2B) /
              orderCount(OrganisationOrdering.B2B),
            g: "evaluated",
          },
          {
            x: "b2b",
            count:
              orderCount(OrganisationOrdering.B2B) -
              reviewCount(OrganisationOrdering.B2B),
            y:
              (orderCount(OrganisationOrdering.B2B) -
                reviewCount(OrganisationOrdering.B2B)) /
              orderCount(OrganisationOrdering.B2B),
            g: "not evaluated",
          },
        ]}
      />
      <Axis id="bottom-axis" position="bottom" />
      <Axis
        id="left-axis"
        position="left"
        // showGridLines
        tickFormat={(d: any) => `${Number(d * 100).toFixed(0)} %`}
      />
    </Chart>
  );

  const renderNpsChart = () => (
    <Chart
      title={`${txt.get("sales.nps.promoters")} vs. ${txt.get(
        "sales.nps.detractors"
      )}`}
      size={{ height: 200, width: 400 }}
    >
      <Settings
        theme={[{ colors: { vizColors: euiPaletteForStatus(3) } }, theme]}

        // baseTheme={EUI_CHARTS_THEME_LIGHT.theme}
        // // showLegend={true}
        // // showLegendExtra
        // legendPosition="right"
        // showLegendDisplayValue={false}
      />
      <BarSeries
        id="bars"
        displayValueSettings={displayValueSettings}
        xScaleType={ScaleType.Ordinal}
        yScaleType={ScaleType.Linear}
        xAccessor="x"
        yAccessors={[yAccessorFn]}
        stackAccessors={["x"]}
        splitSeriesAccessors={[splitAccessorFn]}
        data={[
          {
            x: "mm",
            y:
              promoters(OrganisationOrdering.Internal) /
              reviewCount(OrganisationOrdering.Internal),
            g: "promoters",
          },
          {
            x: "mm",
            y:
              (reviewCount(OrganisationOrdering.Internal) -
                detractors(OrganisationOrdering.Internal) -
                promoters(OrganisationOrdering.Internal)) /
              reviewCount(OrganisationOrdering.Internal),
            g: "neutral",
          },
          {
            x: "mm",
            y:
              detractors(OrganisationOrdering.Internal) /
              reviewCount(OrganisationOrdering.Internal),
            g: "detractors",
          },
          {
            x: "dw",
            y:
              promoters(OrganisationOrdering.External) /
              reviewCount(OrganisationOrdering.External),
            g: "promoters",
          },
          {
            x: "dw",
            y:
              (reviewCount(OrganisationOrdering.External) -
                detractors(OrganisationOrdering.External) -
                promoters(OrganisationOrdering.External)) /
              reviewCount(OrganisationOrdering.External),
            g: "neutral",
          },
          {
            x: "dw",
            y:
              detractors(OrganisationOrdering.External) /
              reviewCount(OrganisationOrdering.External),
            g: "detractors",
          },
          {
            x: "b2b",
            y:
              promoters(OrganisationOrdering.B2B) /
              reviewCount(OrganisationOrdering.B2B),
            g: "promoters",
          },
          {
            x: "b2b",
            y:
              (reviewCount(OrganisationOrdering.B2B) -
                detractors(OrganisationOrdering.B2B) -
                promoters(OrganisationOrdering.B2B)) /
              reviewCount(OrganisationOrdering.B2B),
            g: "neutral",
          },
          {
            x: "b2b",
            y:
              detractors(OrganisationOrdering.B2B) /
              reviewCount(OrganisationOrdering.B2B),
            g: "detractors",
          },
        ]}
      />
      <Axis id="bottom-axis" position="bottom" />
      <Axis
        id="left-axis"
        position="left"
        // showGridLines
        tickFormat={(d: any) => `${Number(d * 100).toFixed(0)} %`}
      />
    </Chart>
  );

  return (
    <MMPage
      title={txt.get("sales.page_title")}
      subTitle={txt.get("sales.nps.acronym")}
    >
      <EuiSpacer />

      <EuiFlexGroup>
        <EuiFlexItem>{renderNPSScores()}</EuiFlexItem>
        <EuiFlexItem>{renderEvaluationsChart()}</EuiFlexItem>
        <EuiFlexItem>{renderNpsChart()}</EuiFlexItem>
      </EuiFlexGroup>
    </MMPage>
  );
}

export default MMSales;
