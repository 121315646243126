import {
  EuiCheckbox,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSpacer,
  EuiText,
  EuiTextArea,
  EuiTitle,
} from "@elastic/eui";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { updateField } from "hoc/helper-hooks";
import { MMOrderLineDeliveryFieldProps } from "../order-line-delivery";
import { FITTING_EMPTY, Fitting } from "store/data/fitting/fitting";

function MMDeliveryInstructions(props: MMOrderLineDeliveryFieldProps) {
  const [fitting, setFitting] = useState<Fitting | null | undefined>();

  useEffect(() => {
    setFitting(props.fitting);
  }, [props.fitting]);

  const onInstructedChanged = (value: boolean) => {
    if (props.onChange) {
      props.onChange(
        { ...(fitting || FITTING_EMPTY), instructed: value },
        props.orderLineId
      );
    }
  };

  return (
    <EuiFlexGroup gutterSize="xs">
      <EuiFlexItem>
        <EuiTitle size="xxs">
          <EuiText color="grey">
            {txt.get("orders.delivery.instructions")}
          </EuiText>
        </EuiTitle>
        <EuiSpacer size="s" />
        <EuiText size="s" color="grey">
          {txt.html("orders.delivery.instruction_details")}
        </EuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}

export default MMDeliveryInstructions;
