import {
  EuiCheckbox,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
} from "@elastic/eui";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { COSTING_EMPTY, Costing } from "store/data/costing/costing";
import { updateField } from "hoc/helper-hooks";
import { MMOrderLineProductInput } from "components/orders/order-entry/order-line-entry";
import { isOrderLineDeclarationNeeded } from "store/data/order/order";

export interface MMCostingDetailsProps {
  costing?: Costing | null;
  orderLineIndex?: number | null;
  onChange?: any;
  orderLineProduct: MMOrderLineProductInput;
}

function MMCostingDetails(props: MMCostingDetailsProps) {
  const [costing, setCosting] = useState<Costing>(
    props.costing || COSTING_EMPTY
  );
  const [orderLineIndex, setOrderLineIndex] = useState<
    number | null | undefined
  >(props.orderLineIndex);
  const [orderLineProduct, setOrderLineProduct] =
    useState<MMOrderLineProductInput>(props.orderLineProduct);

  useEffect(() => {
    setCosting(props.costing || COSTING_EMPTY);
    setOrderLineIndex(props.orderLineIndex);
    setOrderLineProduct(props.orderLineProduct);
  }, [props.costing, props.orderLineIndex, props.orderLineProduct]);

  return (
    <EuiFlexGroup gutterSize="xs" direction="column">
      {isOrderLineDeclarationNeeded(orderLineProduct.orderType) ? (
        costing.authorization !== false ? (
          <EuiFlexItem grow={false} style={{ whiteSpace: "nowrap" }}>
            <EuiCheckbox
              compressed={true}
              id={`is-quoted-${orderLineIndex}`}
              onChange={() => {
                if (props.onChange)
                  props.onChange(
                    updateField(
                      costing || COSTING_EMPTY,
                      "is_quoted",
                      !costing?.is_quoted
                    ),
                    orderLineIndex !== undefined ? orderLineIndex : -1
                  );
              }}
              checked={costing.is_quoted}
              label={
                <EuiText size="xs" style={{ position: "relative", top: "2px" }}>
                  {txt.get("orders.order.costing.is_quoted")}
                </EuiText>
              }
            />
            {costing.is_quoted ? (
              <EuiFieldText
                style={{
                  width: "100px",
                  marginTop: "5px",
                  marginLeft: "20px",
                }}
                compressed={true}
                value={costing.quotation_code || ""}
                placeholder={txt.get("orders.order.costing.quotation_code")}
                onChange={(e: any) => {
                  setCosting(
                    updateField(costing, "quotation_code", e.target.value)
                  );
                }}
                onBlur={(e: any) => {
                  props.onChange(
                    updateField(costing, "quotation_code", e.target.value),
                    orderLineIndex !== undefined ? orderLineIndex : -1
                  );
                }}
              />
            ) : (
              <></>
            )}
          </EuiFlexItem>
        ) : (
          <></>
        )
      ) : (
        <EuiFlexItem>
          <EuiText size="xs" color="gray">
            {txt.html(
              "orders.order.costing.no_declaration_needed_for",
              orderLineProduct.orderType
            )}
          </EuiText>
        </EuiFlexItem>
      )}
      {isOrderLineDeclarationNeeded(orderLineProduct.orderType) &&
      costing.authorization !== false ? (
        <EuiFlexItem grow={false} style={{ whiteSpace: "nowrap" }}>
          <EuiCheckbox
            compressed={true}
            id={`is-authorized-${orderLineIndex}`}
            onChange={() => {
              props.onChange(
                updateField(costing, "is_authorized", !costing.is_authorized),
                orderLineIndex !== undefined ? orderLineIndex : -1
              );
            }}
            checked={costing.is_authorized}
            label={
              <EuiText size="xs" style={{ position: "relative", top: "2px" }}>
                {txt.get("orders.order.costing.is_authorized")}
              </EuiText>
            }
          />
          {costing.is_authorized ? (
            <EuiFieldText
              style={{
                width: "100px",
                marginTop: "5px",
                marginLeft: "20px",
              }}
              compressed={true}
              value={costing.authorization_code || ""}
              placeholder={txt.get("orders.order.costing.authorization_code")}
              onChange={(e: any) => {
                setCosting(
                  updateField(costing, "authorization_code", e.target.value)
                );
              }}
              onBlur={(e: any) => {
                props.onChange(
                  updateField(costing, "authorization_code", e.target.value),
                  orderLineIndex !== undefined ? orderLineIndex : -1
                );
              }}
            />
          ) : (
            <></>
          )}
        </EuiFlexItem>
      ) : (
        <></>
      )}
      {isOrderLineDeclarationNeeded(orderLineProduct.orderType) ? (
        <EuiFlexItem grow={false}>
          <EuiCheckbox
            compressed={true}
            id={`is-invoiced-${orderLineIndex}`}
            onChange={() => {
              props.onChange(
                updateField(
                  costing || COSTING_EMPTY,
                  "is_invoiced",
                  !costing?.is_invoiced
                ),
                orderLineIndex !== undefined ? orderLineIndex : -1
              );
            }}
            checked={costing?.is_invoiced}
            label={
              <EuiText size="xs" style={{ position: "relative", top: "2px" }}>
                {txt.get("orders.order.costing.is_invoiced")}
              </EuiText>
            }
          />
        </EuiFlexItem>
      ) : (
        <></>
      )}
    </EuiFlexGroup>
  );
}

export default MMCostingDetails;
