import { Component, SyntheticEvent } from 'react';
import RandomHelper from 'helpers/random-helper';

export enum MMPopoverType {
    Alert,
    Confirm,
}
  
interface MMPopoverProps {
    content: string;
    title?: string;   
    type: MMPopoverType;
    onDone:Function;
    onPrimary?: Function;
    onSecondary?: Function;
}

interface MMPopoverState {
    content: string;
    title?: string;  
    type: MMPopoverType;
}

class MMPopover extends Component<MMPopoverProps,MMPopoverState>{

    private cssId: string;

    constructor(props:MMPopoverProps) {
        super(props);
        this.state = {
            content : props.content,
            title : props.title,  
            type: props.type,          
        };
        this.cssId = RandomHelper.cssid();

        this._onPrimaryClicked = this._onPrimaryClicked.bind(this);
        this._onSecondaryClicked = this._onSecondaryClicked.bind(this);
        this._onDone = this._onDone.bind(this);
    }

    _onPrimaryClicked(e:SyntheticEvent) {
        e.preventDefault();
        if (this.props.onPrimary) {
            this.props.onPrimary();
        }
        this._onDone();
    }

    _onSecondaryClicked(e:SyntheticEvent) {    
        e.preventDefault();
        if (this.props.onSecondary) {
            this.props.onSecondary();
        }
    }

    _onDone() {
        this.props.onDone();
    }

    render(): JSX.Element {
        return (
            <div id={this.cssId} className="PopoverBackground">
                <div className="Popover">
                    { this.state.title
                    ?
                    <div className="Popover--title-holder">
                        <div className="Popover--title">
                            {this.state.title}
                        </div>
                    </div>   
                    :
                    ''
                    }
                    <div className="Popover--content-holder">
                        <div className="Popover--content">
                            {this.state.content}
                        </div>
                    </div>
                    <div className="Popover--buttons-holder">
                        <div className="Popover--buttons">
                            { this.state.type === MMPopoverType.Confirm
                            ?
                            <div onClick={this._onSecondaryClicked} className="Button Button--secondary">
                                Cancel
                            </div>
                            :
                            ''                
                            }
                            <div onClick={this._onPrimaryClicked} className="Button Button--primary">
                                OK
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MMPopover;