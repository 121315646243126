import {
  copyToClipboard,
  EuiAvatar,
  EuiBadge,
  EuiBasicTable,
  EuiButton,
  EuiCard,
  EuiDescriptionList,
  EuiFieldText,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiIcon,
  EuiPopover,
  EuiPopoverFooter,
  EuiPopoverTitle,
  EuiRange,
  EuiSpacer,
  EuiText,
  useIsWithinBreakpoints,
} from "@elastic/eui";
import { Fragment, useEffect, useState } from "react";

import ConnectAPIHelper from "api/connect-api-helper";
import { useNavigate, useSearchParams } from "react-router-dom";
import StringHelper from "helpers/string-helper";
import {
  Order,
  OrderLine,
  orderLineMeasurementsDescription,
  OrderLineStatus,
  orderLineStatusDescription,
  orderLineToHealth,
  OrderStatus,
  OrderStatusAction,
  orderStatusDescription,
  orderStatusFromAction,
  orderToHealth,
  productDescription,
  productShortDescription,
  productTinyDescription,
} from "store/data/order/order";

import DateHelper from "helpers/date-helper";
import datemath from "@elastic/datemath";
import AuthenticationHelper from "helpers/authentication-helper";
import { useDispatch, useSelector } from "react-redux";
import { toastAdd } from "store/components/toast/toast";
import txt from "helpers/text-helper";
import {
  AlertConfirm,
  AlertConfirmActionState,
  AlertInput,
  AlertInputValueType,
  confirmAsk,
  confirmGet,
} from "store/components/alert/confirm";
import FileUploadHelper from "helpers/file-upload-helper";
import { fileUrl } from "helpers/google-helper";
import { formatLocation } from "store/data/location/location";
import { v4 as uuid } from "uuid";
import { trackEvent } from "helpers/analytics-helper";
import {
  Organisation,
  OrganisationOrdering,
} from "store/data/organisation/organisation";
import { Hand, handDescription } from "store/data/scan/scan";
import { columnDateOnlyShort, columnTimeOnlyShort } from "hoc/helper-hooks";
import MMCell from "components/layouts/table/cell";
import {
  formalName,
  fullName,
} from "store/data/personal-details/personal-details";
import _ from "lodash";
import UrlHelper from "helpers/url-helper";
import {
  Referral,
  referralMissingText,
  referralRejectionText,
  ReferralStatus,
  referralStatusDescription,
  referralToHealth,
} from "store/data/referral/referral";
import { viewName } from "store/data/location/location";
import { Referrer } from "store/data/referrer/referrer";
import { ApiResponseStatus } from "api/api-helper";
import ClientsAPIHelper from "api/clients-api.helper";
import { Client, clientName } from "store/data/client/client";
import { indicationDescription } from "store/data/indication/indication";
import { useLocalStorage } from "store/local-storage";

export const PAGE_SIZE_OPTIONS = [5, 10, 25, 50, 100];
export const DEFAULT_PAGE_SIZE = 5;
export const SEARCH_DEBOUNCE_DELAY = 700;
export const DEFAULT_SINCE = "2020-01-01";
export const DEFAULT_UNTIL = "now";
export const DEFAULT_SORT_BY = "ordered_at";
export const DEFAULT_SORT_ORDER = "desc";
export const DEFAULT_FOR_ME_ORDERS_SELECTED = false;
export const DEFAULT_BY_ME_ORDERS_SELECTED = false;
export const DEFAULT_WITH_REFERRAL = "any";
export const DEFAULT_INCLUDE_DEMO = false;
export const LAST_UPDATE_WARNING_AFTER_HOURS = 24 * 14; // after 14 days

const DEFAULT_COLUMNS = [
  "order_id",
  "products",
  "ordered_by",
  "ordered_at",
  "practitioner",
  "organisation",
  "client_code",
  "delivery_method",
  "location",
  "status",
  "last_update",
  "actions",
];

export interface MMOrderShortlistFilters {
  since?: string;
  until?: string;
  search?: string;
  status?: OrderStatus | string | OrderStatus[] | string[];
  limit?: number;
  offset?: number;
  sortBy?: string;
  sortOrder?: string;
  hasPaymentInfo?: boolean;
  needsPaymentInfo?: boolean;
  needsAuthorization?: "yes" | "no" | "unknown";
  isQuoted?: boolean;
  isAuthorized?: boolean;
  hasReferral?: boolean;
  isInvoiced?: boolean;
  needsQuote?: boolean;
  forMeOrdersSelected?: boolean;
  byMeOrdersSelected?: boolean;
  withReferral?: string;
  includeDemo?: boolean;
  hasClientCode?: boolean;
  ordering?: OrganisationOrdering | OrganisationOrdering[];
  isEvaluated?: boolean;
  referralStatus?: ReferralStatus | ReferralStatus[];
  practitioners?: number[];
  locations?: number[];
  organisations?: number[];
  isComplete?: boolean | null;
}

export interface MMOrderShortlistProps {
  filters?: MMOrderShortlistFilters;
  columns?: any;
  actions?: MMOrderAction[];
  statusActions?: OrderStatusAction[];
  clickable?: boolean;
  onChange?: Function;
  orderTab?:
    | "referral"
    | "needs"
    | "product_selection"
    | "measurements"
    | "fitting"
    | "costing";
  name: string;
}

export enum MMOrderAction {
  Edit,
  View,
  Duplicate,
  Delete,
  AttachReferral,
  DownloadReferral,
  DownloadAttachments,
  ChangeStatus,
  Evaluate,
  Deliver,
}

enum ConfirmActionState {
  Inactive,
  Ask,
  Confirm,
  Cancel,
  Perform,
}

function MMOrdersShortlist(props: MMOrderShortlistProps) {
  const filters = props.filters || {};
  const activeColumns = props.columns || [];
  const activeActions = props.actions || [];
  const activeStatusActions = props.statusActions || [];
  const isClickable = props.clickable !== undefined ? props.clickable : true;

  const dispatch = useDispatch();

  let [searchParams, setSearchParams] = useSearchParams();

  const handleOrderNavigate = (orderId: string | number) => {
    let params = UrlHelper.queryParams();
    params.order = orderId;
    if (props.orderTab) {
      params.order_tab = props.orderTab;
      console.log("adding order tab", props.orderTab, params);
    }
    setSearchParams(params, { replace: true });
  };

  const navigate = useNavigate();

  const handleNavigate = (path: string) => {
    // console.log("handleNavigate");
    navigate(
      `/my-dashboard?${UrlHelper.toQueryString(UrlHelper.queryParams())}`,
      {
        replace: true,
      }
    );
    navigate(path);
  };

  const api: ConnectAPIHelper = new ConnectAPIHelper();
  const clientApi: ClientsAPIHelper = new ClientsAPIHelper();
  const [isLoading, setIsLoading] = useState(true);

  const [orders, setOrders] = useState([]);
  const [clients, setClients] = useState<any>({});

  const [selectedOrders] = useState<[]>([]);

  const localKey = (name: string) =>
    `shortlist_${props.name || "orders"}_${name}`;

  const [since] = useState(filters.since);
  const [until] = useState(filters.until);
  const [search] = useState(filters.search || "");
  const [orderStatus] = useState(filters.status || "_");
  const [ordering] = useState(filters.ordering);
  const [hasClientCode] = useState(filters.hasClientCode);
  const [needsAuthorization] = useState(filters.needsAuthorization);
  const [isQuoted] = useState(filters.isQuoted);
  const [isAuthorized] = useState(filters.isAuthorized);
  const [isInvoiced] = useState(filters.isInvoiced);
  const [needsQuote] = useState(filters.needsQuote);
  const [hasReferral] = useState(filters.hasReferral);
  const [referralStatus] = useState(filters.referralStatus);
  const [hasPaymentInfo] = useState(filters.hasPaymentInfo);
  const [needsPaymentInfo] = useState(filters.needsPaymentInfo);
  const [practitioners] = useState(filters.practitioners);
  const [locations, setLocations] = useState(filters.locations);
  const [organisations, setOrganisations] = useState(filters.organisations);
  const [isComplete, setIsComplete] = useState(filters.isComplete);
  const [limit, setLimit] = useLocalStorage(
    localKey("limit"),
    filters.limit || DEFAULT_PAGE_SIZE
  );
  const [offset, setOffset] = useLocalStorage(
    localKey("offset"),
    filters.offset || 0
  );
  const [total, setTotal] = useState(0);
  const [sortBy, setSortBy] = useLocalStorage(
    localKey("sort_by"),
    filters.sortBy || DEFAULT_SORT_BY
  );
  const [sortOrder, setSortOrder] = useLocalStorage(
    localKey("sort_order"),
    filters.sortOrder || DEFAULT_SORT_ORDER
  );
  const [expandedOrders, setExpandedOrders] = useState({} as any);
  const [error, setError] = useState("");
  const [isForMeOrdersSelected, setIsForMeOrdersSelected] = useState(
    filters.forMeOrdersSelected || DEFAULT_FOR_ME_ORDERS_SELECTED
  );
  const [isByMeOrdersSelected, setIsByMeOrdersSelected] = useState(
    filters.byMeOrdersSelected || DEFAULT_BY_ME_ORDERS_SELECTED
  );
  const [includeDemo, setIncludeDemo] = useState<boolean>(
    filters.includeDemo || DEFAULT_INCLUDE_DEMO
  );

  const [isEvaluated] = useState(filters.isEvaluated);
  const [withReferral] = useState(
    AuthenticationHelper.getGroups().includes("/manometric")
      ? filters.withReferral || DEFAULT_WITH_REFERRAL
      : DEFAULT_WITH_REFERRAL
  );

  const [orderEvaluatePopover, setOrderEvaluatePopover] = useState<
    number | null
  >(null);

  const [evaluationNotes, setEvaluationNotes] = useState<string>("");
  const [evaluationScore, setEvaluationScore] = useState<number>(10);

  const [changeStatusRef] = useState("update_order_status_" + uuid());
  const [deleteRef] = useState("delete_orders_" + uuid());
  const [toCorrect, setToCorrect] = useState<any>(null);
  const [toCorrectValue, setToCorrectValue] = useState("");
  const [isToCorrectOpen, setIsToCorrectOpen] = useState<boolean>(false);

  useEffect(() => {
    if (props.filters?.includeDemo !== undefined) {
      setIncludeDemo(props.filters.includeDemo);
    }

    setIsByMeOrdersSelected(
      props.filters?.byMeOrdersSelected || DEFAULT_BY_ME_ORDERS_SELECTED
    );

    setIsForMeOrdersSelected(
      props.filters?.forMeOrdersSelected || DEFAULT_FOR_ME_ORDERS_SELECTED
    );

    if (props.filters?.includeDemo !== undefined) {
      setIncludeDemo(props.filters.includeDemo);
    }
    setLocations(props.filters?.locations || []);
    setOrganisations(props.filters?.organisations || []);
    setIsComplete(props.filters?.isComplete);
  }, [props.filters]);
  const handleChange = () => {
    if (props.onChange) {
      props.onChange();
    }
  };
  const updateOrderFields = async (id: number, fields: any) => {
    setExpandedOrders({});
    setIsLoading(true);
    const result = await api.updateOrderFields(id, fields);
    console.log("updateOrderFields", result);
    setIsLoading(false);
    setToCorrect(null);
    setIsToCorrectOpen(false);
    handleChange();
    await loadOrders();
  };
  const loadOrders = async () => {
    setExpandedOrders({});
    setIsLoading(true);
    let sinceDate = since ? datemath.parse(since)?.toDate() : undefined;
    let untilDate = until
      ? datemath.parse(until, { roundUp: true })?.toDate()
      : undefined;
    let filters: any = {};
    if (search) filters.search = search;
    if (hasClientCode !== undefined) {
      filters.has_client_code = hasClientCode;
    }
    if (needsAuthorization !== undefined) {
      filters.needs_authorization = needsAuthorization;
    }
    if (isQuoted !== undefined) {
      filters.is_quoted = isQuoted;
    }
    if (isAuthorized !== undefined) {
      filters.is_authorized = isAuthorized;
    }
    if (isInvoiced !== undefined) {
      filters.is_invoiced = isInvoiced;
    }
    if (needsQuote !== undefined) {
      filters.needs_quote = needsQuote;
    }
    if (hasReferral !== undefined) {
      filters.has_referral = hasReferral;
    }
    if (includeDemo !== undefined) {
      filters.include_demo = includeDemo;
    }
    if (isByMeOrdersSelected)
      filters.ordered_by = AuthenticationHelper.getEmail();
    if (isForMeOrdersSelected) {
      filters.practitioner = AuthenticationHelper.getPersonalDetailsId();
    }
    if (isEvaluated !== undefined) {
      filters.is_evaluated = isEvaluated;
    }
    if (practitioners) {
      filters.practitioners = practitioners;
    }
    if (locations) {
      filters.locations = locations;
    }
    if (organisations) {
      filters.organisations = organisations;
    }
    if (orderStatus !== "_" && orderStatus !== "") filters.status = orderStatus;
    if (ordering !== undefined) filters.ordering = ordering;
    if (referralStatus !== undefined) filters.referral_status = referralStatus;
    if (hasPaymentInfo !== undefined) filters.has_payment_info = hasPaymentInfo;
    if (needsPaymentInfo !== undefined)
      filters.needs_payment_info = needsPaymentInfo;
    if (isComplete !== undefined) filters.is_complete = isComplete;

    const result = await api.getOrders(
      filters,
      limit,
      offset,
      orderFieldToSortKey(sortBy),
      sortOrder,
      sinceDate,
      untilDate
    );

    setIsLoading(false);
    if (result.status === ApiResponseStatus.OK) {
      setOrders(result.result);
      setTotal(result.meta_data.result_set.total);
      setError("");
    } else {
      setOrders([]);
      setTotal(0);
      setError(`${result.status} (${result.code}): ${result.message}`);
    }
  };

  useEffect(() => {
    loadOrders();
  }, [
    since,
    until,
    search,
    orderStatus,
    limit,
    offset,
    sortBy,
    sortOrder,
    isForMeOrdersSelected,
    isByMeOrdersSelected,
    isEvaluated,
    withReferral,
    includeDemo,
    hasClientCode,
    ordering,
    locations,
    organisations,
    isComplete,
  ]);

  useEffect(() => {
    const handleClientSummaries = async (orders: Order[]) => {
      if (await AuthenticationHelper.hasPermission("client_details#read_all")) {
        const clientCodes: any[] = [
          ...Array.from(
            new Set(orders.map((order: Order) => order.client_code))
          ),
        ];
        if (clientCodes.length > 0) {
          const result: any =
            await clientApi.getClientSummaryByClientCodes(clientCodes);
          if (result && result.status === ApiResponseStatus.OK) {
            const lookup: any = {};
            for (let i = 0; i < result.result.length; i++) {
              const client: Client = result.result[i];
              if (client.client_code) {
                lookup[client.client_code] = client;
              }
            }

            setClients(lookup);
          }
        } else {
          setClients({});
        }
      } else {
        setClients({});
      }
    };

    handleClientSummaries(orders || []);
  }, [orders]);

  const alertConfirm: AlertConfirm = useSelector(confirmGet);
  const handleStatusActions = async (
    order: Order,
    statusActions: OrderStatusAction[]
  ) => {
    const orderId = order.id;
    if (statusActions.length === 1) {
      const statusAction: OrderStatusAction = statusActions[0];

      const canEditStatusLog: boolean =
        await AuthenticationHelper.hasPermission("order_status_logs#edit_all");

      let input: AlertInput | undefined;
      let confirmText: string | undefined;
      let cancelText: string | undefined;
      let customActionData: any = {
        editableDate: canEditStatusLog ? DateHelper.toDateTimeSortable() : null,
      };
      let content: string = txt.uf(
        "generic.confirm_action_x_y",
        txt.get(
          `orders.order.status_action.${(statusAction as string).toLowerCase()}`
        ),
        txt.lo("orders.order.articled")
      );

      if (statusAction === OrderStatusAction.Deliver) {
        content = canEditStatusLog
          ? txt.get(
              "generic.confirm_action_x_y",
              txt.lo(
                `orders.order.status_action.${statusAction.toLowerCase()}`
              ),
              txt.lo("orders.order.articled")
            )
          : txt.get("orders.order.delivery_checks");
        input = canEditStatusLog
          ? {
              valueType: AlertInputValueType.Text,
              description: txt.get("orders.order.log_note"),
              isMandatory: false,
              isVisible: true,
              value: "",
            }
          : {
              valueType: AlertInputValueType.Check,
              description: txt.get("orders.order.delivery_checks_confirm"),
              isMandatory: true,
              isVisible: true,
            };
        customActionData = {
          editableDate: canEditStatusLog
            ? DateHelper.toDateTimeSortable()
            : null,
          title: txt.get("orders.order.delivery_products"),
          order: order,
          fields: order.order_lines.map((line) => ({
            type: "select",
            label: `${
              line.hand && line.hand.toLowerCase() === "l"
                ? handDescription(Hand.Left)
                : line.hand && line.hand.toLowerCase() === "r"
                  ? handDescription(Hand.Right)
                  : ""
            } ${line.product_selection.type} ${
              line.product_selection.color === "n.v.t."
                ? ""
                : line.product_selection.color
            } ${
              line.product_selection.size === "n.v.t."
                ? ""
                : line.product_selection.size
            } `,
            key: "order_line_id",
            id: line.id,
            value: [
              OrderLineStatus.Produced,
              OrderLineStatus.Packaged,
            ].includes(line.status)
              ? OrderLineStatus.Delivered
              : line.status,
            read_only: ![
              OrderLineStatus.Produced,
              OrderLineStatus.Packaged,
            ].includes(line.status),
            options: [
              OrderLineStatus.Produced,
              OrderLineStatus.Packaged,
            ].includes(line.status)
              ? [
                  {
                    text: txt.get(`orders.order.delivery_deliver`),
                    value: OrderLineStatus.Delivered,
                  },
                  {
                    text: txt.get(
                      `orders.order.status_action.${OrderStatusAction.Reject.toLowerCase()}`
                    ),
                    value: OrderLineStatus.Rejected,
                  },
                  {
                    text: txt.get("orders.order.cannot_deliver"),
                    value: line.status,
                  },
                ]
              : [
                  {
                    text: orderLineStatusDescription(line.status),
                    value: line.status,
                  },
                ],
          })),
        };
        cancelText = txt.get("generic.cancel");
      } else {
        customActionData = {
          orderLineIds: order.order_lines.map((line: OrderLine) => line.id),
        };
      }

      dispatch(
        confirmAsk(
          `${txt.get(
            `orders.order.status_action.${(
              statusAction as string
            ).toLowerCase()}`
          )}.`,
          content,
          changeStatusRef,
          {
            orderId,
            statusAction,
            ...customActionData,
          },
          input,
          confirmText,
          cancelText
        )
      );
    }
  };

  const handleDeleteOrders = async () => {
    dispatch(
      confirmAsk(
        `${txt.uf("generic.delete_x", txt.get("orders.page_title"))}.`,
        txt.get("orders.delete_orders_confirm", selectedOrders.length),
        deleteRef
      )
    );
  };

  useEffect(() => {
    if (
      alertConfirm.actionState === AlertConfirmActionState.Perform &&
      alertConfirm.actionKey === deleteRef
    ) {
      deleteOrders();
    }
    if (
      alertConfirm.actionState === AlertConfirmActionState.Perform &&
      alertConfirm.actionKey === changeStatusRef
    ) {
      let comment: string | undefined;
      let orderLineStatusses: any[] | undefined;
      let status: OrderStatus = orderStatusFromAction(
        alertConfirm.actionData.statusAction
      );
      if (alertConfirm) {
        if (
          alertConfirm.input &&
          alertConfirm.input.valueType !== AlertInputValueType.Check
        ) {
          comment = alertConfirm.input.value;
        }
        if (
          alertConfirm.actionData.statusAction === OrderStatusAction.Deliver
        ) {
          let fullyDelivered = true;
          let linesChecked = alertConfirm.actionData.fields
            ? alertConfirm.actionData.fields
            : [];
          console.log("lineschecked", linesChecked);
          orderLineStatusses = [];
          for (
            let i = 0;
            i < alertConfirm.actionData.order.order_lines.length;
            i++
          ) {
            const line = alertConfirm.actionData.order.order_lines[i];
            const lineChecked: any = linesChecked.find(
              (lineToCheck: any) => lineToCheck.id === line.id
            );

            //order is fully delivered if all lines are in an end state (cancelled,delivered,rejected);
            const statusToCheck = lineChecked ? lineChecked.value : line.status;
            fullyDelivered &&= [
              OrderLineStatus.Delivered,
              OrderLineStatus.Cancelled,
              OrderLineStatus.Rejected,
            ].includes(statusToCheck);

            orderLineStatusses.push({
              order_line_id: line.id,
              status: lineChecked.value,
            });
          }
          if (!fullyDelivered) {
            status = OrderStatus.DeliveredPartial;
          }
        }
      }

      changeOrderStatus(
        alertConfirm.actionData.orderId,
        status,
        comment,
        orderLineStatusses
      );
    }
  }, [alertConfirm]);

  const duplicateOrder = async (orderId: number) => {
    setIsLoading(true);

    const result = await api.duplicateOrder(orderId);
    console.log("duplicateOrder", result);
    if (result && result.order_id) {
      trackEvent("order", `order_duplicated`, `order ${result.order_id}`);
      dispatch(
        toastAdd(
          txt.get("generic.is_duplicated", txt.get("orders.order.name")),
          null,
          "success"
        )
      );
      handleOrderNavigate(result.order_id);
    } else {
      dispatch(toastAdd(txt.get("generic.error"), null, "danger"));
    }

    setIsLoading(false);
  };

  const evaluateOrder = async (
    orderId: number,
    score: number,
    notes: string
  ) => {
    console.log("evaluateOrder", orderId, score, notes);
    const result = await api.createOrderEvaluation(orderId, score, notes);
    if (result && result.id) {
      trackEvent("evaluation", `order_evaluated`, `evalaution ${result.id}`);
      dispatch(
        toastAdd(
          txt.get("generic.is_evaluated", txt.get("orders.order.name")),
          null,
          "success"
        )
      );
      handleChange();
      await loadOrders();
    } else {
      dispatch(toastAdd(txt.get("generic.error"), null, "danger"));
    }
  };

  const changeOrderStatus = async (
    orderId: number,
    status: OrderStatus,
    comment?: string,
    orderLineStatusses?: any[]
  ) => {
    const result = await api.updateOrderStatus(
      orderId,
      status,
      comment,
      orderLineStatusses
    );
    if (result) {
      trackEvent(
        "order",
        `order_${status.toString().toLowerCase()}`,
        `order ${orderId}`
      );
      dispatch(
        toastAdd(txt.get("orders.order.updated_order"), null, "success")
      );
      handleChange();
      await loadOrders();
      // navigate(0);
    } else {
      dispatch(
        toastAdd(txt.get("orders.order.update_order_failed"), null, "danger")
      );
    }
  };

  const deleteOrders = async () => {
    if (selectedOrders.length > 0) {
      const result = await api.deleteOrders(
        selectedOrders.map((order: Order) => order.id)
      );
      dispatch(
        toastAdd(
          txt.get("orders.deleted_orders", result.deleted_order_count),
          null,
          "success"
        )
      );
    }
    navigate(0);
  };

  const orderFieldToSortKey = (field: string) => {
    switch (field) {
      // case 'client_hand':
      //   return 'hand';
      // case 'client_number':
      //   return 'client';
      default:
        return field;
    }
  };

  const onOrdersChange = ({ page = {} as any, sort = {} as any }) => {
    if (page.size) {
      const newLimit = page.size;
      const newOffset = Math.max(0, page.index * page.size);
      if (limit != newLimit) setLimit(newLimit);
      if (offset != newOffset) setOffset(newOffset);
    }
    if (sort.field) {
      setSortBy(sort.field);
      setSortOrder(sort.direction ?? "asc");
    }
  };

  const resultCountInfo = () =>
    total == 0
      ? txt.uf("generic.found_no_x", txt.get("orders.page_title"))
      : `Showing ${
          limit == 0
            ? "all"
            : `${offset + 1}-${Math.min(total, offset + limit)}`
        } of ${total} found orders.`;

  const limitOffsetToPage = (limit: number, offset: number) => {
    //pages in EUI are zero based
    const page = limit > 0 ? Math.max(0, offset / limit) : 0;
    return page;
  };

  const pagination: any = {
    pageIndex: limitOffsetToPage(limit, offset),
    pageSize: limit,
    totalItemCount: total,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showPerPageOptions: false,
  };

  const sorting: any = {
    sort: {
      field: sortBy,
      direction: sortOrder,
    },
    enableAllColumns: false,
    // readOnly: false,
  };

  const uploadAttachmentToOrder = async (
    orderId: number,
    attachType?: string
  ) => {
    const path = "orders/attach";
    const key = "attach_file";

    console.log("uploadAttachmentToOrder", orderId, attachType);

    setIsLoading(true);

    const uploadHelper = new FileUploadHelper();

    //should just be order_id
    let options: any = {
      folder: `${orderId}`,
    };

    if (attachType) {
      options.order_id = orderId;
      options.attach_type = attachType;
    }

    const result = await uploadHelper.uploadFile(
      path,
      key,
      (info: any) => {
        if (info && info === "uploading") setIsLoading(true);
      },
      options
    );

    if (result) {
      trackEvent(
        "order",
        `order_uploaded_${attachType ?? "attachment"}`,
        `order ${orderId}`
      );
      handleChange();
      await loadOrders();
    }

    setIsLoading(false);
  };

  const getAction = (action: MMOrderAction) => {
    switch (action) {
      case MMOrderAction.View:
        return {
          render: (order: Order) => {
            return (
              <div
                style={{ display: "flex", alignItems: "center" }}
                onClick={(e: any) => {
                  e?.stopPropagation();
                  handleOrderNavigate(order.id);
                }}
              >
                <EuiIcon cursor="pointer" type={"eye"} />
                {/* <EuiText className="action-text">
                  &nbsp;&nbsp;{txt.get("generic.view")}
                </EuiText> */}
              </div>
            );
          },
        };
      case MMOrderAction.Edit:
      case MMOrderAction.Deliver:
        return {
          render: (order: Order) => {
            return (
              <div
                style={{ display: "flex", alignItems: "center" }}
                onClick={(e: any) => {
                  e?.stopPropagation();
                  handleOrderNavigate(order.id);
                }}
              >
                <EuiIcon
                  cursor="pointer"
                  type={action === MMOrderAction.Deliver ? "check" : "pencil"}
                />
                {/* <EuiText className="action-text">
                  &nbsp;&nbsp;
                  {action === MMOrderAction.Deliver
                    ? txt.get("orders.order.delivery_deliver")
                    : txt.get("generic.edit")}
                </EuiText> */}
              </div>
            );
          },
        };

      case MMOrderAction.Duplicate:
        return {
          render: (order: Order) => {
            return (
              <div
                style={{ display: "flex", alignItems: "center" }}
                onClick={(e: any) => {
                  e?.stopPropagation();
                  duplicateOrder(order.id);
                }}
              >
                <EuiIcon cursor="pointer" type="copy" />
                {/* <EuiText className="action-text">
                  &nbsp;&nbsp;{txt.get("generic.duplicate")}
                </EuiText> */}
              </div>
            );
          },
        };
      case MMOrderAction.Delete:
        return {
          render: (order: Order) => {
            return (
              <div
                style={{ display: "flex", alignItems: "center" }}
                onClick={(e: any) => {
                  e?.stopPropagation();
                  duplicateOrder(order.id);
                }}
              >
                <EuiIcon cursor="pointer" type="delete" />
                {/* <EuiText className="action-text">
                  &nbsp;&nbsp;{txt.get("generic.delete")}
                </EuiText> */}
              </div>
            );
          },
        };
      case MMOrderAction.AttachReferral:
        return {
          render: (order: Order) => {
            return (
              <div
                aria-label={txt.get("generic.delete")}
                style={{ display: "flex", alignItems: "center" }}
                onClick={(e: any) => {
                  e?.stopPropagation();
                  uploadAttachmentToOrder(
                    order.id,
                    "referral_letter_reference"
                  );
                }}
              >
                <EuiIcon cursor="pointer" type="paperClip" />
                {/* <EuiText className="action-text">
                  &nbsp;&nbsp;{txt.get("generic.attach")}
                </EuiText> */}
              </div>
            );
          },
        };
      case MMOrderAction.DownloadReferral:
        return {
          render: (order: Order) => {
            return order.referral_letter_reference ? (
              <div
                style={{ display: "flex", alignItems: "center" }}
                onClick={(e: any) => {
                  e?.stopPropagation();
                  window.open(
                    fileUrl(order.referral_letter_reference),
                    "_blank"
                  );
                }}
              >
                <EuiIcon cursor="pointer" type="download" />
              </div>
            ) : (
              <div
                style={{ display: "flex", alignItems: "center" }}
                onClick={(e: any) => {
                  e?.stopPropagation();
                }}
              >
                <EuiIcon cursor="pointer" type="download" color="#d0d0d0" />
                {/* <EuiText className="action-text">
                  &nbsp;&nbsp;{txt.get("generic.download")}
                </EuiText> */}
              </div>
            );
          },
        };
      case MMOrderAction.Evaluate:
        return {
          render: (order: Order) => {
            return (
              <EuiPopover
                ownFocus={false}
                button={
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setOrderEvaluatePopover(order.id);
                    }}
                  >
                    <EuiIcon cursor="pointer" type="faceHappy" />
                  </div>
                }
                isOpen={orderEvaluatePopover === order.id}
                closePopover={() => setOrderEvaluatePopover(null)}
                anchorPosition="rightCenter"
              >
                <EuiPopoverTitle>
                  {txt.get("orders.order.evaluate_explanation")}
                </EuiPopoverTitle>
                <div style={{ width: "300px" }}>
                  <EuiFormRow>
                    <EuiRange
                      id={`order-evaluation-score-${order.id}`}
                      min={1}
                      max={10}
                      value={evaluationScore}
                      onChange={(e) =>
                        setEvaluationScore(parseInt(e.currentTarget.value))
                      }
                      showTicks
                      tickInterval={1}
                      // levels={[
                      //   {
                      //     min: 1,
                      //     max: 7,
                      //     color: "danger",
                      //     "data-test-subj": "dangerColorLevel",
                      //   },
                      //   {
                      //     min: 7,
                      //     max: 9,
                      //     color: "warning",
                      //   },
                      //   {
                      //     min: 9,
                      //     max: 10,
                      //     color: "success",
                      //   },
                      // ]}
                      aria-label={"Evalution score"}
                      aria-describedby={`order-evaluation-score-${order.id}`}
                    />
                  </EuiFormRow>
                  <EuiFormRow>
                    <EuiFieldText
                      value={evaluationNotes}
                      placeholder={txt.get("orders.order.evaluation_notes")}
                      onChange={(e: any) => setEvaluationNotes(e.target.value)}
                      onKeyDown={(e: any) =>
                        e.code === "Space" ? e.stopPropagation() : e
                      }
                    />
                  </EuiFormRow>
                </div>
                <EuiPopoverFooter>
                  <EuiButton
                    fullWidth
                    size="s"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      evaluateOrder(order.id, evaluationScore, evaluationNotes);
                      setOrderEvaluatePopover(null);
                    }}
                  >
                    {txt.get("generic.evaluate")}
                  </EuiButton>
                </EuiPopoverFooter>
              </EuiPopover>
            );
          },
        };
      // case MMOrderAction.DownloadAttachments:
      //   return {
      //     render: (order:Order) => {
      //       return (
      //         <div style={{display:'flex', alignItems:'center'}}
      //           onClick={(e:any) => {e?.stopPropagation(); }}
      //           >
      //           <EuiIcon cursor='pointer' type='download' target="_blank" href={fileUrl(order.referral_letter_reference)}/>
      //         </div>
      //       );
      //     },
      //   };
      case MMOrderAction.ChangeStatus:
        return {
          render: (order: Order) => {
            return (
              <div
                style={{ display: "flex", alignItems: "center" }}
                onClick={(e: any) => {
                  e?.stopPropagation();
                  handleStatusActions(order, activeStatusActions);
                }}
              >
                <EuiIcon cursor="pointer" type="check" />
                {/* <EuiText className="action-text">
                  &nbsp;&nbsp;{txt.get("generic.change_status")}
                </EuiText> */}
              </div>
            );
          },
        };
      // [
      //   {
      //     render: (order:Order) => (
      //       <div style={{display:'flex', alignItems:'center'}}
      //       onClick={(e:any) => {e?.stopPropagation(); toggleOrderLines(order)}}
      //       >
      //       <EuiIcon cursor='pointer'
      //         type={expandedOrders[order.id] ? 'arrowDown' : 'arrowRight'}
      //       />
      //       <EuiText>&nbsp;&nbsp;{ expandedOrders[order.id] ? txt.get('generic.less') : txt.get('generic.more') }</EuiText>
      //     </div>
      //     ),
      //   },
      // ]
    }
  };

  const getActions = () => {
    return activeActions.map((action) => getAction(action));
  };

  const getCorrigableClientCode = (order: Order) => {
    const id: string = `order-client-code-${order.id}`;
    const button: any = (
      <EuiIcon
        type="plusInCircleFilled"
        style={{ color: "#f90", opacity: 1 }}
        onClick={(e: any) => {
          e.preventDefault();
          e.stopPropagation();
          setToCorrectValue(order.client_code);
          setToCorrect(order);
          setIsToCorrectOpen(true);
        }}
      />
    );

    const closePopover = () => {
      setIsToCorrectOpen(false);
      setToCorrect(null);
    };

    return (
      <EuiPopover
        initialFocus={`#${id}`}
        button={button}
        isOpen={isToCorrectOpen && order.id == toCorrect.id}
        closePopover={closePopover}
      >
        <EuiFormRow
          color="primary"
          label={txt.uf(
            "generic.fill_out_x",
            txt.get("orders.order.client_code")
          )}
          id={id}
        >
          <EuiFieldText
            compressed
            value={toCorrectValue}
            name="client_code"
            onChange={(e: any) => {
              setToCorrectValue(e.target.value);
            }}
          />
        </EuiFormRow>

        <EuiSpacer />

        <EuiButton
          size="s"
          fill
          onClick={(e: any) => {
            e.preventDefault();
            e.stopPropagation();
            updateOrderFields(order.id, { client_code: toCorrectValue });
          }}
        >
          {txt.get("generic.save")}
        </EuiButton>
      </EuiPopover>
    );
  };

  const getColumn = (key: string): any => {
    switch (key) {
      case "order_id":
        return {
          name: txt.get("orders.order.name"),
          field: "id",
          sortable: api.orderIsSortableBy(orderFieldToSortKey("id")),
          style: { width: "75px" },
          render: (id: string, order: Order) => (
            <MMCell
              bold={true}
              text={id}
              wrap={false}
              after={
                order.is_demo ? (
                  <EuiBadge
                    color="accent"
                    style={{
                      marginLeft: "3px",
                      fontSize: "8px",
                    }}
                  >
                    {txt.up("generic.demo")}
                  </EuiBadge>
                ) : undefined
              }
            />
          ),
        };
      case "order_client":
        return {
          name: `${txt.get("orders.order.name")}/${txt.get(
            "orders.order.client_code"
          )}`,
          field: "id",
          sortable: api.orderIsSortableBy(orderFieldToSortKey("id")),
          style: { width: "75px" },
          render: (id: string, order: Order) => (
            <MMCell
              bold={true}
              text={id}
              subText={
                order.client_code
                  ? StringHelper.ucfirst(order.client_code ?? "-")
                  : "-"
              }
              wrap={false}
              after={
                order.is_demo ? (
                  <EuiBadge
                    color="accent"
                    style={{
                      marginLeft: "3px",
                      fontSize: "8px",
                    }}
                  >
                    {txt.up("generic.demo")}
                  </EuiBadge>
                ) : undefined
              }
            />
          ),
        };
      case "products":
        return {
          name: txt.get("orders.order.products"),
          truncateText: true,
          field: "order_lines",
          render: (order_lines: OrderLine[]) => {
            let descriptions: any = [];
            _.forOwn(
              _.groupBy(order_lines, (line: OrderLine) =>
                line.translations &&
                line.translations.name &&
                line.translations.name[txt.lang()]
                  ? line.translations.name[txt.lang()].substring(
                      0,
                      line.translations.name[txt.lang()].indexOf(",") > 0
                        ? line.translations.name[txt.lang()].indexOf(",")
                        : line.translations.name[txt.lang()].length
                    )
                  : "-"
              ),
              (lines, description) => {
                descriptions.push(
                  <EuiFlexItem
                    grow={false}
                    key={`${lines.length}${description}`}
                  >
                    <EuiText size="s">{`${
                      lines.length > 1 ? `${lines.length}x ` : ""
                    }${description}`}</EuiText>
                  </EuiFlexItem>
                );
              }
            );
            return (
              <EuiFlexGroup direction="column" gutterSize="xs">
                {descriptions}
              </EuiFlexGroup>
            );
          },
        };
      case "products_detailed":
        return {
          name: txt.get("orders.order.products"),
          truncateText: true,
          render: (order: Order) => (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
              }}
              onClick={(e: any) => {
                e?.stopPropagation();
                toggleOrderLines(order);
              }}
            >
              <EuiIcon
                style={{ position: "relative", top: "2px" }}
                type={expandedOrders[order.id] ? "arrowDown" : "arrowRight"}
              />
              <EuiText size="s">
                {order.order_lines
                  .filter((line: OrderLine, i: number) => i < 1)
                  .map((line: OrderLine) => line)
                  .reduce(
                    (descriptions: string, line: any) =>
                      `${descriptions} ${
                        line.product_selection
                          ? productShortDescription(
                              line.product_selection,
                              line.translations
                            )
                          : ""
                      }`,
                    `${
                      order.order_lines.length === 1
                        ? `1 ${txt.lo("orders.order.product")}`
                        : txt.lo(
                            "orders.order.count_products",
                            order.order_lines.length
                          )
                    } :`
                  ) + (order.order_lines.length > 1 ? "..." : "")}
              </EuiText>
            </div>
          ),
        };
      case "ordered_by":
        return {
          name: txt.get("orders.order.ordered_by"),
          field: "ordered_by",
          type: "string",
          sortable: api.orderIsSortableBy(orderFieldToSortKey("ordered_by")),
          render: (val: string) => <MMCell text={val} />,
        };
      case "ordered_at":
        return {
          name: txt.get("orders.order.ordered_at"),
          field: "ordered_at",
          type: "date",
          style: { minWidth: "75px" },
          sortable: api.orderIsSortableBy(orderFieldToSortKey("ordered_at")),
          render: (val: string) => (
            <MMCell
              bold={true}
              text={columnDateOnlyShort(val, txt.lang())}
              subText={columnTimeOnlyShort(val)}
            />
          ),
        };
      case "organisation":
        return {
          name: txt.get("account.organisation"),
          field: "organisation",
          sortable: api.orderIsSortableBy(orderFieldToSortKey("organisation")),
          render: (organisation: Organisation) =>
            organisation && organisation.name ? organisation.name : "-",
        };
      case "delivery":
        return {
          name: txt.get("orders.order.delivery"),
          field: "delivery_method",
          sortable: api.orderIsSortableBy(
            orderFieldToSortKey("delivery_method")
          ),
          // render: (organisation: Organisation) =>
          //   organisation && organisation.name ? organisation.name : "-",
        };
      case "org":
        return {
          name: txt.get("account.org"),
          field: "organisation",
          style: { minWidth: "45px" },
          sortable: api.orderIsSortableBy(orderFieldToSortKey("organisation")),
          render: (organisation: Organisation) =>
            organisation && organisation.acronym ? organisation.acronym : "-",
        };
      case "practitioner":
        return {
          name: txt.get("orders.order.practitioner"),
          field: "practitioner",
          sortable: api.orderIsSortableBy(orderFieldToSortKey("practitioner")),
          render: (practitioner: string, order: Order) => (
            <MMCell
              before={
                <EuiAvatar
                  color="#e9ebf0"
                  size="s"
                  name={
                    practitioner && order.personal_details
                      ? fullName(order.personal_details)
                      : ""
                  }
                ></EuiAvatar>
              }
              text={`${
                practitioner && order.personal_details
                  ? fullName(order.personal_details)
                  : "-"
              }`}
              subText={order.location ? viewName(order.location) : ""}
              subSoft={true}
            />
          ),
        };
      case "practitioner_detailed":
        return {
          name: txt.get("orders.order.practitioner"),
          field: "practitioner",
          sortable: api.orderIsSortableBy(orderFieldToSortKey("practitioner")),
          render: (practitioner: string, order: Order) =>
            `${
              practitioner && order.personal_details
                ? fullName(order.personal_details)
                : "-"
            } (${
              order.organisation && order.organisation.acronym
                ? order.organisation.acronym
                : "-"
            })`,
        };
      case "corrigable_client_code":
        return {
          name: txt.get("orders.order.client_code"),
          sortable: api.orderIsSortableBy(orderFieldToSortKey("client_code")),
          style: { minWidth: "75px" },
          field: "client_code",
          render: (client_code: string, order: Order) =>
            client_code ? (
              <MMCell
                bold={true}
                text={client_code ? client_code : "-"}
                subText={
                  client_code && clients && clients[client_code] ? (
                    <EuiText size="s">
                      {clientName(clients[client_code], true)}
                      <br />
                      {clients[client_code].birth_date
                        ? DateHelper.toDate(clients[client_code].birth_date)
                        : ""}
                    </EuiText>
                  ) : (
                    ""
                  )
                }
                wrap={false}
              />
            ) : (
              getCorrigableClientCode(order)
            ),
        };
      case "client_code":
        return {
          name: txt.get("orders.order.client_code"),
          sortable: api.orderIsSortableBy(orderFieldToSortKey("client_code")),
          style: { minWidth: "75px" },
          field: "client_code",
          render: (client_code: string) => (
            <MMCell
              bold={true}
              text={client_code ? client_code : "-"}
              subText={
                client_code && clients && clients[client_code] ? (
                  <EuiText
                    size="s"
                    style={{ maxWidth: "150px", whiteSpace: "normal" }}
                  >
                    {clientName(clients[client_code], true)}
                    <br />
                    {clients[client_code].birth_date
                      ? DateHelper.toDate(clients[client_code].birth_date)
                      : ""}
                  </EuiText>
                ) : (
                  ""
                )
              }
              wrap={false}
            />
          ),
        };
      case "external_client_details":
        return {
          name: txt.get(
            AuthenticationHelper.isInternalUser()
              ? "orders.external_client_reference_name.external_client"
              : AuthenticationHelper.getGroups().includes("/b2b")
                ? "orders.external_client_reference_name.client.name"
                : "orders.external_client_reference_name.patient.name"
          ),
          sortable: api.orderIsSortableBy(orderFieldToSortKey("client")),
          field: "external_client_reference",
          render: (external_client_reference: string, order: Order) => (
            <MMCell
              before={
                order.external_client_name ? (
                  <EuiAvatar
                    color="#e9ebf0"
                    size="s"
                    name={order.external_client_name}
                  ></EuiAvatar>
                ) : (
                  <EuiAvatar
                    color="#e9ebf0"
                    iconType="faceHappy"
                    size="s"
                    name={external_client_reference}
                  ></EuiAvatar>
                )
              }
              text={
                order.external_client_name
                  ? order.external_client_name
                  : external_client_reference || "-"
              }
              subText={
                order.external_client_phone_number
                  ? order.external_client_phone_number
                  : order.external_client_email
                    ? order.external_client_email
                    : undefined
              }
            />
          ),
        };
      case "external_client_reference":
        //todo: the reference/patient_number should be based on the organisation data

        return {
          name: txt.get(
            AuthenticationHelper.getGroups().includes("/b2b")
              ? "orders.external_client_reference_name.reference"
              : "orders.external_client_reference_name.patient_number"
          ),
          sortable: api.orderIsSortableBy(
            orderFieldToSortKey("external_client_reference")
          ),
          field: "external_client_reference",
          render: (external_client_reference: string) =>
            external_client_reference ? external_client_reference : "-",
        };
      case "delivery_method":
        return {
          name: txt.get("orders.order.delivery"),
          sortable: api.orderIsSortableBy(
            orderFieldToSortKey("delivery_method")
          ),
          field: "delivery_method",
          render: (delivery_method: string) =>
            delivery_method
              ? StringHelper.ucfirst(delivery_method ?? "-")
              : "-",
        };
      case "location":
        return {
          name: txt.get("orders.order.delivery_location"),
          sortable: api.orderIsSortableBy(orderFieldToSortKey("location")),
          field: "location",
          render: (location: string) =>
            location ? formatLocation(location ?? "-") : "-",
        };
      case "referral":
        return {
          name: txt.get("referrals.name"),
          sortable: api.orderIsSortableBy(orderFieldToSortKey("referral")),
          field: "referral",
          render: (referral: Referral) =>
            referral ? (
              <EuiFlexGroup direction="column" gutterSize="xs">
                <EuiFlexItem style={{ display: "inline-block" }}>
                  <EuiBadge
                    color={referralToHealth(referral.status as ReferralStatus)}
                  >
                    {referralStatusDescription(
                      referral.status as ReferralStatus
                    )}
                  </EuiBadge>
                </EuiFlexItem>
                <EuiFlexItem>
                  {!referral.status ||
                  referral.status === ReferralStatus.Pending ? (
                    <EuiText size="s">{referralMissingText(referral)}</EuiText>
                  ) : referral.status &&
                    referral.status === ReferralStatus.Rejected ? (
                    <EuiText size="s">
                      {referralRejectionText(referral)}
                    </EuiText>
                  ) : (
                    <></>
                  )}
                </EuiFlexItem>
              </EuiFlexGroup>
            ) : (
              <EuiText size="xs">-</EuiText>
            ),
        };
      case "referrer":
        return {
          name: txt.get("admin.referrers.name"),
          sortable: api.orderIsSortableBy(orderFieldToSortKey("referrer")),
          field: "referral.referrer",
          render: (referrer: Referrer, order: Order) => (
            <EuiFlexGroup gutterSize="none" direction="column">
              {referrer && referrer.person ? (
                <MMCell
                  wrap={false}
                  text={formalName(referrer.person)}
                  subSoft={true}
                  subText={
                    referrer.alternative_organisation
                      ? referrer.alternative_organisation
                      : referrer.organisation
                        ? referrer.organisation.name
                        : "-"
                  }
                />
              ) : (
                "-"
              )}
              {order.referral?.indications &&
              order.referral?.indications.length > 0 ? (
                <MMCell
                  text={indicationDescription(order.referral.indications)}
                />
              ) : (
                <></>
              )}
            </EuiFlexGroup>
          ),
        };
      case "status":
        return {
          name: txt.get("generic.status"),
          sortable: api.orderIsSortableBy(orderFieldToSortKey("status")),
          field: "status",
          render: (status: OrderStatus) => {
            return (
              <Fragment>
                <EuiBadge color={orderToHealth(status as OrderStatus)}>
                  {orderStatusDescription(status as OrderStatus)}
                </EuiBadge>
              </Fragment>
            );
          },
        };
      case "last_update":
        return {
          name: txt.get("generic.last_update"),
          field: "updated_at",
          style: { minWidth: "75px" },
          type: "date",
          sortable: api.orderIsSortableBy(orderFieldToSortKey("updated_at")),
          render: (val: string) => (
            <MMCell
              bold={true}
              wrap={false}
              text={columnDateOnlyShort(val, txt.lang())}
              subText={columnTimeOnlyShort(val)}
            />
          ),
        };
      case "referral_last_update":
        return {
          name: txt.get("generic.last_update"),
          field: "referral.updated_at",
          style: { minWidth: "75px" },
          // type: "date",
          sortable: api.orderIsSortableBy(
            orderFieldToSortKey("referral.updated_at")
          ),
          render: (val: string) => (
            <MMCell
              bold={true}
              wrap={false}
              text={columnDateOnlyShort(val)}
              subText={columnTimeOnlyShort(val)}
              tag={
                DateHelper.olderThan(LAST_UPDATE_WARNING_AFTER_HOURS, val)
                  ? DateHelper.old(val)
                  : ""
              }
              tagColor="danger"
              columnDirection="column"
            />
          ),
        };
      case "actions":
        return {
          name: "",
          isExpander: true,
          actions: getActions(),
        };
      case "last_quoted_at":
        return {
          name: txt.get("finance.authorizations.quoted_at"),
          field: "order_lines",
          style: { minWidth: "75px" },
          type: "date",
          sortable: false,
          render: (orderLines: OrderLine[]) => {
            let overallLastQuotedAt: any = null;

            _.each(orderLines, (line) => {
              const costing = line?.costing;
              if (
                costing &&
                costing?.last_quoted_at &&
                (overallLastQuotedAt === null ||
                  costing?.last_quoted_at < overallLastQuotedAt)
              ) {
                overallLastQuotedAt = costing?.last_quoted_at;
              }
            });

            if (overallLastQuotedAt) {
              return (
                <MMCell
                  bold={true}
                  wrap={false}
                  text={columnDateOnlyShort(overallLastQuotedAt)}
                  subText={columnTimeOnlyShort(overallLastQuotedAt)}
                  tag={
                    DateHelper.olderThan(
                      LAST_UPDATE_WARNING_AFTER_HOURS,
                      overallLastQuotedAt
                    )
                      ? DateHelper.old(overallLastQuotedAt)
                      : ""
                  }
                  tagColor="danger"
                  columnDirection="column"
                />
              );
            }

            return <></>;
          },
        };
      case "is_complete":
        return {
          name: txt.get("orders.order.completion.is_complete").concat("?"),
          field: "is_complete",
          sortable: false,
          style: { width: "75px" },
          render: (is_complete: boolean | null) => (
            <MMCell
              align={"center"}
              bold={true}
              wrap={false}
              text={
                is_complete === null ? (
                  <EuiBadge color="warning">
                    {txt.get("orders.order.completion.not_checked")}
                  </EuiBadge>
                ) : is_complete ? (
                  <EuiBadge color="success">
                    {txt.get("orders.order.completion.is_complete")}
                  </EuiBadge>
                ) : (
                  <EuiBadge color="danger">
                    {txt.get("orders.order.completion.is_incomplete")}
                  </EuiBadge>
                )
              }
            ></MMCell>
          ),
        };

      default:
        return {
          name: "?",
        };
    }
  };

  const getColumns = () => {
    let columnsToRender: string[] =
      activeColumns && activeColumns.length > 0
        ? [...activeColumns]
        : [...DEFAULT_COLUMNS];
    if (activeActions.length > 0) {
      columnsToRender.push("actions");
    }
    return columnsToRender.map((column: string) => getColumn(column));
  };

  const toggleOrderLines = (order: Order) => {
    const newExpandedOrders: any = { ...expandedOrders };
    if (newExpandedOrders[order.id]) {
      delete newExpandedOrders[order.id];
    } else {
      let listItems: any = [];
      order.order_lines.forEach((line) => {
        listItems.push({
          title: "Product",
          description: `${line.code}-${line.hand}`,
        });
      });

      newExpandedOrders[order.id] = (
        <EuiFlexGrid columns={1} gutterSize="s">
          {order.order_lines.map((line: OrderLine) => {
            let descriptionLines = [
              {
                title: txt.get("orders.order.order_type"),
                description: line.order_type ?? "-",
              },
              {
                title: txt.get("orders.order.hand"),
                description: `${
                  line.hand && line.hand.toString() == "L"
                    ? txt.get("scanning.scans.scan.hand.left")
                    : line.hand && line.hand.toString() == "R"
                      ? txt.get("scanning.scans.scan.hand.right")
                      : ""
                } ${
                  line.digits && line.digits.length
                    ? " (" + line.digits.join(", ") + ")"
                    : ""
                }`,
              },
              {
                title: txt.get("orders.order.measurements"),
                description: line.measurements
                  ? orderLineMeasurementsDescription(line.measurements)
                  : "-",
              },
            ];
            if (line.remake_reason || line.replacement_for) {
              descriptionLines.push({
                title: txt.get("orders.order.remake_reason"),
                description: line.remake_reason ?? "-",
              });
              descriptionLines.push({
                title: txt.get("orders.order.replacement_for"),
                description: line.replacement_for ?? "-",
              });
            }
            return (
              <EuiFlexItem key={`key-${line.id}`}>
                <EuiCard
                  textAlign="left"
                  title={
                    line.product_selection
                      ? productDescription(
                          line.product_selection,
                          line.translations
                        )
                      : ""
                  }
                  children={
                    <EuiDescriptionList
                      type="column"
                      rowGutterSize="s"
                      listItems={descriptionLines}
                    />
                  }
                  footer={
                    <Fragment>
                      {line.measurements && line.measurements.notes ? (
                        <EuiDescriptionList
                          rowGutterSize="s"
                          listItems={[
                            {
                              title: "Notes",
                              description: line.measurements.notes ?? "-",
                            },
                          ]}
                        />
                      ) : (
                        <></>
                      )}
                      <EuiBadge
                        color={orderLineToHealth(line.status)}
                        title={line.status}
                      >
                        {orderLineStatusDescription(line.status)}
                      </EuiBadge>
                    </Fragment>
                  }
                />
              </EuiFlexItem>
            );
          })}
        </EuiFlexGrid>
      );
    }
    setExpandedOrders(newExpandedOrders);
  };

  const getRowProps = (order: any) => {
    const { id } = order;
    return {
      "data-id": `row-${id}`,
      onClick: (e: any) => {
        e.stopPropagation();
        if (e.shiftKey) {
          copyToClipboard(e.target.innerText);
          dispatch(
            toastAdd(txt.get("generic.copied_x", `'${e.target.innerText}'`))
          );
        } else if (
          e.target.tagName !== "BUTTON" &&
          e.target.tagName !== "INPUT"
        ) {
          handleOrderNavigate(id);
        }
      },
    };
  };

  return (
    <Fragment>
      <EuiBasicTable
        loading={isLoading}
        cellPadding="2"
        tableLayout="auto"
        itemId="id"
        itemIdToExpandedRowMap={expandedOrders}
        items={orders}
        columns={getColumns()}
        sorting={sorting}
        pagination={pagination}
        rowProps={isClickable ? getRowProps : undefined}
        noItemsMessage={
          error
            ? error
            : txt.uf("generic.found_no_x", txt.get("orders.page_title"))
        }
        onChange={onOrdersChange}
      />
    </Fragment>
  );
}

export default MMOrdersShortlist;
