import {
  EuiBasicTableColumn,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiInMemoryTable,
  EuiPanel,
} from "@elastic/eui";
import ConnectAPIHelper from "api/connect-api-helper";
import MMCell from "components/layouts/table/cell";
import MMColumnFormatted from "components/layouts/table/column-formatted";
import MMMemoryTable from "components/layouts/table/memory-table";
import MMTitle from "components/layouts/title/title";
import DateHelper from "helpers/date-helper";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { Client } from "store/data/client/client";
import { renderCurrentProductionStep } from "store/data/production/production";

import { Workflow } from "store/data/production/workflow";
import { Hand, handDescription } from "store/data/scan/scan";

export interface MMProductionInfoProps {
  client: Client | null;
  productions: any[];
  isEditable?: boolean;
  isHeaderShown?: boolean;
}

function MMProductionInfo(props: MMProductionInfoProps) {
  const api = new ConnectAPIHelper();

  const [client, setClient] = useState<Client>();
  const [productions, setProductions] = useState<any[]>([]);
  const [workflowLookup, setWorkflowLookup] = useState<any>({});
  const [checks, setChecks] = useState([]);

  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isHeaderShown, setIsHeaderShown] = useState<boolean>(false);

  useEffect(() => {
    const loadWorkflows = async () => {
      const result = await api.getWorkflows();

      if (result.status === "OK") {
        let lookup: any = {};
        result.result.forEach((workflow: Workflow) => {
          if (workflow.id) {
            lookup[workflow.id] = workflow;
          }
        });
        setWorkflowLookup(lookup);
      } else {
        setWorkflowLookup({});
      }
    };
    const loadChecks = async () => {
      const result = await api.getProductionChecks();
      setChecks(result);
    };
    loadChecks();
    loadWorkflows();
  }, []);
  useEffect(() => {
    setIsEditable(!!props.isEditable);
    if (props.client) {
      setClient(props.client);
    } else {
      setClient(undefined);
    }
    if (props.productions) {
      setProductions(props.productions);
    } else {
      setProductions([]);
    }
  }, [props.productions, props.client, props.isEditable, props.isHeaderShown]);

  const getColumns = (): Array<EuiBasicTableColumn<any>> => [
    {
      name: "",
      field: "code",
      render: (code: string) => <MMCell size="xs" text={code} />,
    },
    {
      name: "",
      field: "product",
      render: (product: string) => <MMCell size="xs" text={product} />,
    },
    {
      name: "",
      field: "order_line.hand",
      render: (hand: Hand) => (
        <MMCell size="xs" text={handDescription(hand, undefined, true)} />
      ),
    },
    {
      name: "",
      field: "order_date",
      style: { minWidth: "90px" },
      render: (finish_date: string, production: any) => {
        const lastStep = workflowLookup[production.workflow?.id]
          ? workflowLookup[production.workflow?.id].workflow_steps[
              workflowLookup[production.workflow?.id].workflow_steps.length - 1
            ]
          : null;
        return (
          <MMCell
            size="xs"
            align="center"
            text={
              finish_date
                ? txt.get("clients.care_info.productions.finished")
                : txt.get("clients.care_info.production.due")
            }
            subText={
              finish_date
                ? DateHelper.toDate(finish_date)
                : lastStep
                ? DateHelper.toDate(
                    DateHelper.addBusinessDays(
                      lastStep.delivery_day,
                      production.order_date
                    )
                  )
                : "-"
            }
          />
        );
      },
    },
    {
      render: (production: any) => (
        <MMCell
          size="xs"
          text={renderCurrentProductionStep(production, checks)}
        />
      ),
    },
  ];

  return client ? (
    <EuiFlexGrid gutterSize="s" className="subtle-editing">
      <EuiFlexItem>
        <MMTitle text={txt.get("clients.care_info.productions.name")} />
      </EuiFlexItem>
      <EuiPanel className="subtle-panel">
        <EuiFlexItem>
          <MMMemoryTable items={productions} columns={getColumns()} />
        </EuiFlexItem>
      </EuiPanel>
    </EuiFlexGrid>
  ) : (
    <></>
  );
}

export default MMProductionInfo;
