import {
  EuiBadge,
  EuiBasicTable,
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHealth,
  EuiHorizontalRule,
  EuiIcon,
  EuiImage,
  EuiLink,
  EuiSpacer,
  EuiSuperSelect,
  EuiText,
  OnTimeChangeProps,
} from "@elastic/eui";
import { Fragment, useEffect, useState } from "react";

import ManoXAPIHelper from "api/manox-api-helper";
import { useNavigate } from "react-router-dom";
import {
  ProcessingRunStatus,
  ProcessingRunQuality,
  processingRunQualityDescription,
  ProcessingRunSummary,
  processingRunQualityToHealth,
  processingRunStatusDescription,
  processingRunStatusToHealth,
} from "store/data/scan/processing-run";
import {
  isValidScanStatus,
  ScanSummary,
  ScanStatus,
  scanStatusDescription,
  scanToHealth,
  Scan,
  SCAN_STATUSSES_ACCEPTED,
  SCAN_STATUSSES_SELECTABLE,
} from "store/data/scan/scan";
import { Asset } from "store/data/asset/asset";
import { useLocalStorage } from "store/local-storage";
import { useDebounce } from "use-debounce";
import DateHelper from "helpers/date-helper";
import datemath from "@elastic/datemath";
import UrlHelper from "helpers/url-helper";
import txt from "helpers/text-helper";
import MMCell from "components/layouts/table/cell";
import AuthenticationHelper from "helpers/authentication-helper";
import { ApiResponse, ApiResponseStatus } from "api/api-helper";
import ConnectAPIHelper from "api/connect-api-helper";
import { Order } from "store/data/order/order";
import { viewName } from "store/data/location/location";
import { fullName } from "store/data/personal-details/personal-details";

export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100]; //,0]; //0 would mean without limit
export const DEFAULT_PAGE_SIZE = 10;
export const SEARCH_DEBOUNCE_DELAY = 700;
export const DEFAULT_SINCE = "2020-01-01";
export const DEFAULT_UNTIL = "now";
export const DEFAULT_SORT_BY = "captured_at";
export const DEFAULT_SORT_ORDER = "desc";

function MMScansList() {
  const navigate = useNavigate();
  const api: ManoXAPIHelper = new ManoXAPIHelper();
  const connectApi: ConnectAPIHelper = new ConnectAPIHelper();

  const [scans, setScans] = useState([]);

  const [orders, setOrders] = useState<any>({});

  const [since, setSince] = useLocalStorage("scan_since", DEFAULT_SINCE);
  const [until, setUntil] = useLocalStorage("scan_until", DEFAULT_UNTIL);

  const [searchFieldValue, setSearchFieldValue] = useLocalStorage(
    "scan_search",
    ""
  );
  const [isOrderInfoShown, setIsOrderInfoShown] = useState<boolean>(false);
  const [search] = useDebounce(searchFieldValue, SEARCH_DEBOUNCE_DELAY);
  const [scanStatus, setScanStatus] = useLocalStorage("scan_status", "_a_");
  const [clientFieldValue, setClientFieldValue] = useLocalStorage(
    "client_number",
    ""
  );
  const [client] = useDebounce(clientFieldValue, SEARCH_DEBOUNCE_DELAY);
  const [limit, setLimit] = useLocalStorage("scan_limit", DEFAULT_PAGE_SIZE);
  const [offset, setOffset] = useLocalStorage("scan_offset", 0);
  const [total, setTotal] = useState(0);
  const [sortBy, setSortBy] = useLocalStorage("scan_sort_by", DEFAULT_SORT_BY);
  const [sortOrder, setSortOrder] = useLocalStorage(
    "scan_sort_order",
    DEFAULT_SORT_ORDER
  );
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState("");

  useEffect(() => {
    const querySearch = UrlHelper.queryParam("search");
    const queryClient = UrlHelper.queryParam("client");
    const queryStatus = UrlHelper.queryParam("status");

    if (querySearch || queryClient || queryStatus) {
      setUntil(DEFAULT_UNTIL);
      setSince(DEFAULT_SINCE);
      setSortBy(DEFAULT_SORT_BY);
      setSortOrder(DEFAULT_SORT_ORDER);
      setSearchFieldValue(querySearch ?? "");
      setClientFieldValue(queryClient ?? "");
      setScanStatus(
        queryStatus && isValidScanStatus(queryStatus) ? queryStatus : "_a_"
      );
    }
  }, []);

  useEffect(() => {
    const loadScans = async () => {
      setIsLoading(true);
      //temp fix for name search. it's case sensitive so make it uppercase
      let sinceDate = since ? datemath.parse(since)?.toDate() : undefined;
      let untilDate = until
        ? datemath.parse(until, { roundUp: true })?.toDate()
        : undefined;
      console.group("since,until");
      console.log(since);
      console.log(sinceDate);
      console.log(until);
      console.log(untilDate);
      console.groupEnd();
      let filters: any = {};
      if (client.trim()) {
        filters.client = client.trim() as number;
      }
      if (search.toUpperCase().trim()) {
        filters.name = search.toUpperCase().trim();
      }
      if (scanStatus === "_") {
        // filters.status = null;
      } else if (scanStatus === "_a_") {
        filters.status = SCAN_STATUSSES_ACCEPTED.map((status: ScanStatus) =>
          status.toString()
        );
      } else {
        filters.status = scanStatus.toString();
      }

      const result = await api.getScans(
        filters,
        limit,
        offset,
        scanFieldToSortKey(sortBy),
        sortOrder,
        sinceDate,
        untilDate
      );
      setIsLoading(false);
      if (result.status == "OK") {
        setScans(result.result);
        setTotal(result.meta_data.result_set.total);
        setError("");
      } else {
        setScans([]);
        setTotal(0);
        setError(`${result.status} (${result.code}): ${result.message}`);
      }
    };

    const setInterfaceForPermissions = async () => {
      let isOrderInfoShown: boolean = await AuthenticationHelper.hasPermission([
        "orders#read_all",
        "orders#read_org",
        "orders#read",
      ]);
      setIsOrderInfoShown(isOrderInfoShown);
    };
    loadScans();
    setInterfaceForPermissions();
  }, [
    since,
    until,
    client,
    search,
    scanStatus,
    limit,
    offset,
    sortBy,
    sortOrder,
  ]);

  useEffect(() => {
    const loadOrders = async (scans: Scan[]) => {
      const fields = [
        "location_id",
        "location.name",
        "location.city",
        "organisation_id",
        "organisation.name",
        "personal_details.first_name",
        "personal_details.last_name",
      ];
      const ids: number[] = [
        ...Array.from(
          new Set(
            scans
              .map((scan: Scan) =>
                !isNaN(parseInt(scan.order_number))
                  ? parseInt(scan.order_number)
                  : 0
              )
              .filter((orderId: number) => orderId > 0)
          )
        ),
      ];
      if (ids.length > 0) {
        const result: ApiResponse = await connectApi.getOrders(
          { ids, fields },
          100,
          0
        );
        if (result.status === ApiResponseStatus.OK) {
          let orderLookup: any = {};
          for (let i = 0; i < result.result.length; i++) {
            const order: Order = result.result[i];
            orderLookup[order.id + ""] = order;
          }
          setOrders(orderLookup);
        }
      } else {
        setOrders([]);
      }
    };
    loadOrders(scans);
  }, [scans]);

  const scanFieldToSortKey = (field: string) => {
    switch (field) {
      case "client_hand":
        return "hand";
      case "client_number":
        return "client";
      default:
        return field;
    }
  };

  const onTimeChange = ({ start, end }: OnTimeChangeProps) => {
    setSince(start);
    setUntil(end);
  };

  const onSearchChange = (event: any) => {
    setSearchFieldValue(event.target.value);
  };

  const onClientChange = (event: any) => {
    setClientFieldValue(event.target.value);
  };

  const onScanStatusChange = (event: any) => {
    setScanStatus(event.target.value);
  };

  const onScanStatusSuperChange = (status: any) => {
    setScanStatus(status);
  };

  const onScansChange = ({ page = {} as any, sort = {} as any }) => {
    if (page.size) {
      const newLimit = page.size;
      const newOffset = Math.max(0, page.index * page.size);
      if (limit != newLimit) setLimit(newLimit);
      if (offset != newOffset) setOffset(newOffset);
    }
    if (sort.field) {
      setSortBy(sort.field);
      setSortOrder(sort.direction ?? "asc");
    }
  };

  const renderThumbnails = (scan: ScanSummary) => (
    <EuiFlexGroup gutterSize="xs">
      {scan.thumbnails && scan.thumbnails.length > 0 ? (
        scan.thumbnails.map((thumbnail: Asset, i: number) => (
          <EuiFlexItem key={`thumbnail-${i}`}>
            <EuiImage alt={thumbnail.tags[0]} src={thumbnail.url} />
          </EuiFlexItem>
        ))
      ) : (
        <EuiIcon
          style={{ margin: "auto" }}
          color="#aaa"
          type="image"
          size="l"
        />
      )}
    </EuiFlexGroup>
  );
  const resultCountInfo = () =>
    total === 0
      ? txt.uf("generic.found_no_x", txt.get("scanning.scans.page_title"))
      : txt.uf(
          "generic.showing_x_of_y_found_z",
          limit === 0
            ? txt.get("generic.all")
            : `${offset + 1}-${Math.min(total, offset + limit)}`,
          total,
          txt.get("scanning.scans.page_title")
        ) + ".";

  const scanStatusEnumToSuperSelectOptions = (
    enumeration: any,
    active: ScanStatus[]
  ) => {
    let result = enumToSelectOptions(enumeration, active);
    result = result.map((element: any, i: number) => {
      return {
        inputDisplay:
          i <= 1 ? (
            <EuiHealth color="transparent" style={{ lineHeight: "inherit" }}>
              {element.text}
            </EuiHealth>
          ) : (
            <EuiHealth
              color={scanToHealth(element.value)}
              style={{ lineHeight: "inherit" }}
            >
              {element.text}
            </EuiHealth>
          ),
        ...element,
      };
    });
    return result;
  };

  const enumToSelectOptions = (enumeration: any, active: ScanStatus[]) => {
    let result: any = [
      {
        value: "_a_",
        text: txt.get("scanning.scans.accepted_scans"),
      },
      {
        value: "_",
        text: txt.get("scanning.scans.all_scans"),
      },
    ];

    Object.entries(enumeration).forEach(([text, value]) => {
      if (active.includes(value as ScanStatus)) {
        text = scanStatusDescription(text as ScanStatus);
        result.push({ value, text });
      }
    });
    return result;
  };

  const limitOffsetToPage = (limit: number, offset: number) => {
    //pages in EUI are zero based
    const page = limit > 0 ? Math.max(0, offset / limit) : 0;
    return page;
  };

  const pagination: any = {
    pageIndex: limitOffsetToPage(limit, offset),
    pageSize: limit,
    totalItemCount: total,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showPerPageOptions: true,
  };

  const sorting: any = {
    sort: {
      field: sortBy,
      direction: sortOrder,
    },
    enableAllColumns: false,
    // readOnly: false,
  };

  const columns = () => {
    let columns: any[] = [
      {
        name: txt.get("scanning.scans.name"),
        field: "name",
        sortable: api.scanIsSortableBy(scanFieldToSortKey("name")),
        render: (name: string, scan: Scan) =>
          name ? (
            <EuiLink href={`/scanning/scans/${scan.scan_id}`}>{name}</EuiLink>
          ) : (
            "-"
          ),
      },
      {
        name: txt.get("scanning.scans.scan.captured_at"),
        field: "captured_at",
        type: "date",
        sortable: api.scanIsSortableBy(scanFieldToSortKey("captured_at")),
        render: (captured_at: string) => {
          return captured_at ? DateHelper.toDateTime(captured_at) : "-";
        },
      },
    ];
    if (isOrderInfoShown) {
      columns = columns.concat([
        {
          name: txt.get("orders.order.id"),
          field: "order_number",
          sortable: api.scanIsSortableBy(scanFieldToSortKey("order_number")),
          render: (order_number: string) =>
            order_number ? (
              <EuiLink href={`?order=${order_number}`}>{order_number}</EuiLink>
            ) : (
              "-"
            ),
        },
        {
          name: txt.get("scanning.scanners.location"),
          field: "order_number",
          render: (order_number: string) => (
            <MMCell
              text={
                orders &&
                orders[order_number] &&
                orders[order_number].organisation
                  ? orders[order_number].organisation.name
                  : "-"
              }
              subText={
                orders && orders[order_number] && orders[order_number].location
                  ? viewName(orders[order_number].location)
                  : "-"
              }
              subSoft={true}
              wrap={true}
            />
          ),
        },
        {
          name: txt.get("scanning.scanners.practitioner"),
          field: "order_number",
          render: (order_number: string) => (
            <MMCell
              text={
                orders && orders[order_number]
                  ? fullName(orders[order_number].personal_details)
                  : "-"
              }
              subSoft={true}
              wrap={true}
            />
          ),
        },
        {
          name: txt.get("orders.order.client_code"),
          sortable: api.scanIsSortableBy(scanFieldToSortKey("client_number")),
          field: "client_number",
          render: (client_number: string) => client_number || "-",
        },
      ]);
    }

    columns = columns.concat([
      {
        name: txt.get("orders.order.hand"),
        sortable: api.scanIsSortableBy(scanFieldToSortKey("client_hand")),
        field: "client_hand",
        render: (client_hand: string) => (
          <MMCell
            text={
              client_hand
                ? txt.get(
                    `scanning.scans.scan.hand.${client_hand.toLowerCase()}`
                  )
                : "-"
            }
            wrap={false}
          />
        ),
      },
      {
        name: txt.get("generic.status"),
        field: "status",
        render: (status?: ScanStatus) =>
          status ? (
            <EuiBadge color={scanToHealth(status)}>
              {scanStatusDescription(status)}
            </EuiBadge>
          ) : (
            "-"
          ),
      },
      {
        name: txt.get("scanning.scans.processing"),
        field: "processing",
        render: (processingRun: ProcessingRunSummary) =>
          !processingRun ? (
            <EuiBadge color="default">
              {txt.get("scanning.scans.scan.nothing_to_process")}
            </EuiBadge>
          ) : processingRun.status === ProcessingRunStatus.Failed ? (
            <EuiBadge color="danger">
              {txt.get("scanning.scans.scan.processing_failed")} (
              {processingRun.count}x)
            </EuiBadge>
          ) : (
            <EuiBadge
              color={processingRunStatusToHealth(
                processingRun.status as ProcessingRunStatus
              )}
            >
              {processingRunStatusDescription(
                processingRun.status as ProcessingRunStatus
              )}
            </EuiBadge>
          ),
      },
      {
        name: txt.get("scanning.scans.scan.quality"),
        field: "processing",
        render: (processingRun: ProcessingRunSummary) =>
          processingRun &&
          processingRun.status !== ProcessingRunStatus.Failed &&
          processingRun.status !== ProcessingRunStatus.Pending ? (
            <EuiBadge
              color={processingRunQualityToHealth(
                processingRun.quality as ProcessingRunQuality
              )}
            >
              {processingRunQualityDescription(
                processingRun.quality as ProcessingRunQuality
              )}
            </EuiBadge>
          ) : (
            "-"
          ),
      },
      // {
      //   name: 'Thumbnails',
      //   render: (scan:any) => renderThumbnails(scan),
      // },
      {
        name: txt.get("scanning.scanners.name"),
        field: "scanner.name",
        render: (name: string) => <MMCell text={name || ""} wrap={false} />,
      },
    ]);

    return columns;
  };

  const getRowProps = (scan: any) => {
    const { scan_id } = scan;
    return {
      "data-id": `row-${scan_id}`,
      onClick: (e: any) => {
        if (
          e.target.tagName !== "BUTTON" &&
          e.target.tagName !== "INPUT" &&
          e.target.tagName !== "A"
        ) {
          navigate(`/scanning/scans/${scan_id}`);
        }
      },
    };
  };

  return (
    <Fragment>
      <EuiFlexGroup>
        <EuiFlexItem style={{ width: "20%" }}>
          <EuiFieldSearch
            compressed={true}
            placeholder={txt.get("generic.search")}
            value={searchFieldValue}
            isLoading={isLoading}
            isClearable={!isLoading}
            contentEditable={!isLoading}
            onChange={(event: any) => onSearchChange(event)}
            aria-label={txt.get("generic.search")}
          />
        </EuiFlexItem>
        {isOrderInfoShown ? (
          <EuiFlexItem style={{ width: "20%" }}>
            <EuiFieldSearch
              compressed={true}
              placeholder={txt.get("orders.order.client_code")}
              value={clientFieldValue || ""}
              isLoading={isLoading}
              isClearable={!isLoading}
              contentEditable={!isLoading}
              onChange={(event: any) => onClientChange(event)}
              aria-label={txt.get("orders.order.client_code")}
            />
          </EuiFlexItem>
        ) : (
          <EuiFlexItem grow={true} />
        )}

        <EuiFlexItem>
          <EuiSuperSelect
            compressed={true}
            itemClassName="scan-status-select-item"
            id="scan-status-select"
            isLoading={isLoading}
            options={scanStatusEnumToSuperSelectOptions(
              ScanStatus,
              SCAN_STATUSSES_SELECTABLE
            )}
            valueOfSelected={scanStatus}
            onChange={(status) => onScanStatusSuperChange(status)}
            aria-label="Select status"
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="xl" />
      <EuiFlexGroup alignItems="flexEnd">
        <EuiFlexItem>
          <EuiText textAlign="right" size="xs">
            {resultCountInfo()}
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="s" />
      <EuiHorizontalRule margin="none" style={{ height: 1 }} />
      <EuiBasicTable
        tableLayout="auto"
        itemId="id"
        items={scans}
        columns={columns()}
        pagination={pagination}
        sorting={sorting}
        rowProps={getRowProps}
        noItemsMessage={
          error
            ? error
            : txt.uf("generic.found_no_x", txt.get("scanning.scans.page_title"))
        }
        onChange={onScansChange}
      />
    </Fragment>
  );
}

export default MMScansList;
