import { EuiLoadingSpinner, EuiText } from "@elastic/eui";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import env from "helpers/env-helper";
import MMGeocoderView from "./geocoder-view";

export interface LatLng {
  lat: number;
  lng: number;
}

function MMGeocoder({
  onLatLng,
  address,
}: {
  onLatLng: (latLng: LatLng | null) => void;
  address: string;
}) {
  const API_KEY: string = env("REACT_APP_GOOGLE_MAPS");

  const onLocation = (location: google.maps.LatLngLiteral) => {
    onLatLng(
      location ? ({ lat: location.lat, lng: location.lng } as LatLng) : null
    );
  };

  const render: any = (status: any) => {
    switch (status) {
      case Status.LOADING:
        return <EuiLoadingSpinner />;
      case Status.FAILURE:
        return <EuiText>could not load google api</EuiText>;
      case Status.SUCCESS:
        return <MMGeocoderView onLocation={onLocation} address={address} />;
    }
  };

  return <Wrapper apiKey={API_KEY} render={render} />;
}

export default MMGeocoder;
