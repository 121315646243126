import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiTextArea,
  useIsWithinBreakpoints,
} from "@elastic/eui";
import {
  MMOrderLineFieldProps,
  MMOrderLineProductInput,
} from "../order-line-entry";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { updateField } from "hoc/helper-hooks";
import {
  EMPTY_PRODUCT_MEASUREMENTS,
  ProductMeasurements,
} from "store/data/order/order";

function MMOrderNotes(props: MMOrderLineFieldProps) {
  const isMobile: boolean = useIsWithinBreakpoints(["xs", "s"]);
  const [selection, setSelection] = useState<MMOrderLineProductInput>(
    props.selection
  );
  const [values, setValues] = useState<ProductMeasurements>(
    props.selection.measurements || EMPTY_PRODUCT_MEASUREMENTS
  );
  const [isLarge, setIsLarge] = useState<boolean>(
    true ||
      !!(
        props.selection &&
        props.selection.ui &&
        props.selection.ui?.notesIsLarge
      )
  );

  useEffect(() => {
    setSelection(props.selection);

    setValues(
      (old) => props.selection.measurements || EMPTY_PRODUCT_MEASUREMENTS
    );
    setIsLarge(
      true ||
        !!(
          props.selection &&
          props.selection.ui &&
          props.selection.ui?.notesIsLarge
        )
    );
  }, [props.selection]);

  const onValueChanged = (values: ProductMeasurements) => {
    if (props.onChange) {
      props.onChange({ ...selection, measurements: values });
      // } else {
      //   setValues(values);
    }
  };

  const css = () => {
    let result: any = {};

    if (!isMobile) {
      result = {
        ...result,
        width: "100%",
        minWidth: "382px",
        maxWidth: props.maxWidth ? props.maxWidth : "475px",
        height: "112px",
      };
    }
    if (!isLarge) {
      result = { ...result, overflowY: "hidden" };
    }

    return { ...result, fontSize: "1rem" };
  };

  return (
    <EuiFlexGroup gutterSize="none" style={css()}>
      <EuiFlexItem>
        <EuiFormRow
          className="product-input"
          display="rowCompressed"
          label={props.showTitle ? txt.get("orders.order.notes") : undefined}
          style={css()}
        >
          <EuiTextArea
            disabled={!props.isEditable}
            onFocus={props.onFocus || undefined}
            value={values.notes || ""}
            rows={isLarge ? 6 : 1}
            compressed={true}
            style={css()}
            placeholder={`${txt.get("orders.order.notes")}...`}
            onBlur={(e) =>
              onValueChanged(updateField(values, "notes", e.target.value))
            }
            onChange={(e) =>
              setValues(updateField(values, "notes", e.target.value))
            }
          />
        </EuiFormRow>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}

export default MMOrderNotes;
