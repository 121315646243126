// import {
//   EuiButton,
//   EuiFlyout,
//   EuiFlyoutBody,
//   EuiFlyoutFooter,
//   EuiFlyoutHeader,
//   EuiHeaderSectionItemButton,
//   EuiIcon,
//   EuiNotificationEvent,
//   EuiPanel,
//   EuiSpacer,
//   EuiTitle,
// } from "@elastic/eui";
// import DateHelper from "helpers/date-helper";
// import { useDispatch } from "react-redux";
// import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import {
//   Notification,
//   notificationCountGet,
//   notificationSetRead,
//   notificationsGet,
//   notificationsHide,
//   notificationsIsShown,
//   notificationsShow,
//   NotificationStatus,
// } from "store/components/notification/notification";

const icons: any = {
  sales: "faceHappy",
  orders: "documentEdit",
  production: "wrench",
  finance: "stats",
  products: "layers",
  scans: "compute",
  "quick-links": "link",
  okrs: "visGauge",
};

function MMNotifications() {
  //euinotificationevent is deprecated and obsolete with upgrading to EUI v94.5.1
  return <></>;
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const single: Notification[] = useSelector(notificationsGet);
  // const notificationCount: number = useSelector(notificationCountGet);
  // const isNotificationsShown: boolean = useSelector(notificationsIsShown);
  // const notificationList = single; //.concat(single).concat(single);
  // const readNotification = (notificationId: string) => {
  //   console.log("readNotification", notificationId);
  //   dispatch(notificationSetRead(notificationId));
  // };
  // const hideNotifications = () => {
  //   dispatch(notificationsHide());
  // };

  // const showNotifications = () => {
  //   dispatch(notificationsShow());
  // };

  // const primaryAction = (notification: Notification) => {
  //   const title =
  //     notification.actions && notification.actions.length > 0
  //       ? notification.actions[0].title
  //       : undefined;
  //   return title;
  // };
  // const primacyActionClick = (notification: Notification) => {
  //   const link =
  //     notification.actions && notification.actions.length > 0
  //       ? notification.actions[0].link
  //       : undefined;

  //   if (link) {
  //     return () => {
  //       readNotification(notification.id);
  //       hideNotifications();
  //       navigate(link);
  //     };
  //   }

  //   return undefined;
  // };
  // const renderNotifications = (): any[] => {
  //   const notifications = notificationList.map((notification) => (
  //     <EuiNotificationEvent
  //       id={notification.id}
  //       key={notification.id}
  //       time={DateHelper.ago(notification.date)}
  //       title={notification.title}
  //       badgeColor={notification.importance || "hollow"}
  //       messages={[notification.text || ""]}
  //       type={notification.topic || ""}
  //       iconType={notification.topic ? icons[notification.topic] || "" : ""}
  //       isRead={notification.status === NotificationStatus.Read}
  //       primaryAction={primaryAction(notification)}
  //       onClickPrimaryAction={primacyActionClick(notification)}
  //       onRead={(id, isRead) => {
  //         if (isRead) readNotification(id);
  //       }}
  //     />
  //   ));

  //   return notifications;
  // };

  // return isNotificationsShown ? (
  //   <EuiFlyout
  //     ownFocus={true}
  //     onClose={hideNotifications}
  //     size="s"
  //     aria-labelledby="notification-list"
  //   >
  //     <EuiFlyoutHeader hasBorder={true}>
  //       <div style={{ display: "flex" }}>
  //         <EuiHeaderSectionItemButton
  //           notification={notificationCount}
  //           iconType="bell"
  //           style={{ pointerEvents: "none" }}
  //         ></EuiHeaderSectionItemButton>
  //         <EuiTitle>
  //           <h2>Notifications</h2>
  //         </EuiTitle>
  //       </div>
  //     </EuiFlyoutHeader>
  //     <EuiFlyoutBody>
  //       <EuiPanel
  //         role="feed"
  //         paddingSize="none"
  //         hasShadow={false}
  //         hasBorder={false}
  //         style={{ maxWidth: "350px" }}
  //       >
  //         {renderNotifications()}
  //       </EuiPanel>
  //     </EuiFlyoutBody>
  //     <EuiFlyoutFooter style={{ backgroundColor: "#ffffff" }}>
  //       <EuiButton iconType="check">Mark all as read</EuiButton>
  //     </EuiFlyoutFooter>
  //   </EuiFlyout>
  // ) : (
  //   <></>
  // );
}

export default MMNotifications;
