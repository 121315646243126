import {
  EuiBasicTable,
  EuiComboBox,
  EuiFieldSearch,
  EuiFilterButton,
  EuiFilterGroup,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiHealth,
  EuiHorizontalRule,
  EuiLink,
  EuiSpacer,
  EuiSuperSelect,
  EuiSwitch,
  EuiText,
} from "@elastic/eui";
import { Fragment, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "store/local-storage";
import txt from "helpers/text-helper";
import ConnectAPIHelper from "api/connect-api-helper";
import { columnString } from "hoc/helper-hooks";
import { useDebounce } from "use-debounce";
import { Referrer } from "store/data/referrer/referrer";
import {
  formalName,
  fullName,
} from "store/data/personal-details/personal-details";
import MMColumnFormatted from "components/layouts/table/column-formatted";
import { ApiResponse, ApiResponseStatus } from "api/api-helper";

export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100]; //,0]; //0 would mean without limit
export const DEFAULT_PAGE_SIZE = 10;
export const SEARCH_DEBOUNCE_DELAY = 700;
export const DEFAULT_SORT_BY = "name";
export const DEFAULT_SORT_ORDER = "asc";

const AGB_VEKTIS_PERSON_PUBLIC_URL_BASE: string =
  "https://www.vektis.nl/agb-register/zorgverlener-";

const AGB_VEKTIS_ORGANISATION_PUBLIC_URL_BASE: string =
  "https://www.vektis.nl/agb-register/onderneming-";

function MMReferrersList() {
  const navigate = useNavigate();
  const api = new ConnectAPIHelper();

  const [referrers, setReferrers] = useState<Referrer[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useLocalStorage(
    "referrer_limit",
    DEFAULT_PAGE_SIZE
  );
  const [offset, setOffset] = useLocalStorage("referrer_offset", 0);
  const [total, setTotal] = useState(0);
  const [sortBy, setSortBy] = useLocalStorage(
    "referrer_sort_by",
    DEFAULT_SORT_BY
  );
  const [sortOrder, setSortOrder] = useLocalStorage(
    "referrer_sort_order",
    DEFAULT_SORT_ORDER
  );
  const [searchFieldValue, setSearchFieldValue] = useLocalStorage(
    "referrer_search",
    ""
  );
  const [search] = useDebounce(searchFieldValue, SEARCH_DEBOUNCE_DELAY);
  const [error, setError] = useState("");
  const [isActiveValue, setIsActiveValue] = useState("_");
  const [isOnlyAgbSelected, setIsOnlyAgbSelected] = useState<
    undefined | boolean
  >();
  const [isOnlyOrganisationAgbSelected, setIsOnlyOrganisationAgbSelected] =
    useState<undefined | boolean>();
  // const [organisations, setOrganisations] = useState([]);
  // const [selectedOrganisations, setSelectedOrganisations] = useState<any[]>([]);

  // useEffect(() => {
  //   const loadOrganisations = async () => {
  //     const organisations = await api.getOrganisations();
  //     setOrganisations(organisations);
  //   };

  //   loadOrganisations();
  // }, []);

  // useEffect(() => {
  //   setSelectedOrganisations([]);
  // }, [organisations]);

  // const onOrganisationChange = (selectedOptions: any[]) => {
  //   setSelectedOrganisations(selectedOptions);
  // };

  // const toSelectOptions = (list: any[]) => {
  //   return list
  //     .map((item) => ({
  //       label: item.name,
  //       "data-id": item.id,
  //     }))
  //     .concat([
  //       {
  //         label: txt.uf(
  //           "generic.without_x",
  //           txt.get("admin.organisations.name")
  //         ),
  //         "data-id": "_",
  //       },
  //     ]);
  // };

  useEffect(() => {
    const loadRefferers = async () => {
      setIsLoading(true);

      let filters: any = {};
      // if (selectedOrganisations && selectedOrganisations.length > 0) {
      //   filters.organisation_ids = selectedOrganisations.map(
      //     (organisation) => organisation["data-id"]
      //   );
      // }
      if (isActiveValue && isActiveValue !== "_") {
        filters.is_active = isActiveValue === "yes";
      }

      if (isOnlyAgbSelected === true) {
        filters.has_agb = true;
      } else if (isOnlyAgbSelected === false) {
        filters.has_agb = false;
      }

      if (isOnlyOrganisationAgbSelected === true) {
        filters.has_organisation_agb = true;
      } else if (isOnlyOrganisationAgbSelected === false) {
        filters.has_organisation_agb = false;
      }

      if (search) {
        filters.search = search;
      }

      const result = await api.getAdminReferrers(
        filters,
        limit,
        offset,
        referrerFieldToSortKey(sortBy),
        sortOrder
      );
      if (result.status === "OK") {
        setReferrers(result.result);
        setTotal(result.meta_data.result_set.total);
        setError("");
      } else {
        setReferrers([]);
        setTotal(0);
        setError(`${result.status} (${result.code}): ${result.message}`);
      }
      setIsLoading(false);
    };

    loadRefferers();
  }, [
    sortOrder,
    sortBy,
    search,
    limit,
    offset,
    // selectedOrganisations,
    isActiveValue,
    isOnlyAgbSelected,
    isOnlyOrganisationAgbSelected,
  ]);

  const resultCountInfo = () =>
    total === 0
      ? txt.uf("generic.found_no_x", txt.get("admin.referrers.page_title"))
      : txt.uf(
          "generic.showing_x_of_y_found_z",
          limit === 0
            ? txt.get("generic.all")
            : `${offset + 1}-${Math.min(total, offset + limit)}`,
          total,
          txt.get("admin.referrers.page_title")
        ) + ".";

  const limitOffsetToPage = (limit: number, offset: number) => {
    //pages in EUI are zero based
    const page = limit > 0 ? Math.max(0, offset / limit) : 0;
    return page;
  };

  const pagination: any = {
    pageIndex: limitOffsetToPage(limit, offset),
    pageSize: limit,
    totalItemCount: total,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showPerPageOptions: true,
  };

  const sorting: any = {
    sort: {
      field: sortBy,
      direction: sortOrder,
    },
    enableAllColumns: false,
    // readOnly: false,
  };

  const referrerFieldToSortKey = (field: string) => {
    switch (field) {
      default:
        return field;
    }
  };

  const onSearchChange = (event: any) => {
    setSearchFieldValue(event.target.value);
  };

  const onReferrersChange = ({ page = {} as any, sort = {} as any }) => {
    if (page.size) {
      const newLimit = page.size;
      const newOffset = Math.max(0, page.index * page.size);
      if (limit !== newLimit) setLimit(newLimit);
      if (offset !== newOffset) setOffset(newOffset);
    }
    if (sort.field) {
      setSortBy(sort.field);
      setSortOrder(sort.direction ?? "asc");
    }
  };

  const handleValueChange = async (
    type: string,
    value: string,
    referrer: Referrer
  ) => {
    if (referrer.id) {
      const result: ApiResponse = await api.updateAdminReferrerFields(
        referrer.id,
        { [type]: value }
      );
      if (result && result.status == ApiResponseStatus.OK) {
        const updatedReferrer: Referrer = result.result;
        const updatedReferrers: Referrer[] = referrers.map(
          (referrer: Referrer) =>
            referrer.id === updatedReferrer.id ? updatedReferrer : referrer
        );
        setReferrers(updatedReferrers);
      }
      console.log("handleValueChange", type, value, referrer.id);
    }
  };

  const columns = [
    {
      name: txt.get("admin.referrers.id"),
      field: "id",
      sortable: api.referrerIsSortableBy(referrerFieldToSortKey("id")),
      style: { minWidth: "60px" },
    },
    {
      name: txt.get("admin.referrers.name"),
      field: "person",
      sortable: api.referrerIsSortableBy(referrerFieldToSortKey("name")),
      render: (person: any, referrer: Referrer) =>
        person ? (
          <EuiLink href={`/admin/referrers/${referrer.id}`}>
            <MMColumnFormatted
              value={formalName(person)}
              noWrap={true}
              highlight={search}
            />
          </EuiLink>
        ) : (
          "-"
        ),
    },
    {
      name: txt.get("admin.referrers.agb_code"),
      field: "agb_code",
      sortable: api.referrerIsSortableBy(referrerFieldToSortKey("agb_code")),
      render: (agbCode: string, referrer: Referrer) => (
        <span>
          <MMColumnFormatted
            value={columnString(agbCode)}
            noWrap={true}
            highlight={search}
            isEditable={true}
            onChange={(value: string) =>
              handleValueChange("agb_code", value, referrer)
            }
          />
          {agbCode ? (
            <EuiLink
              href={`${AGB_VEKTIS_PERSON_PUBLIC_URL_BASE}${agbCode}`}
              external={true}
              target="_blank"
            ></EuiLink>
          ) : (
            <></>
          )}
        </span>
      ),
    },
    {
      name: txt.get("referrers.specialty"),
      field: "specialty",
      sortable: api.referrerIsSortableBy(referrerFieldToSortKey("specialty")),
      render: (specialty: string, referrer: Referrer) => (
        <MMColumnFormatted
          value={columnString(specialty)}
          noWrap={true}
          highlight={search}
          isEditable={true}
          onChange={(value: string) =>
            handleValueChange("specialty", value, referrer)
          }
        />
      ),
    },
    {
      name: txt.get("admin.organisations.name"),
      field: "alternative_organisation",
      sortable: api.referrerIsSortableBy(
        referrerFieldToSortKey("alternative_organisation")
      ),
      render: (alternative_organisation: string, referrer: Referrer) => (
        <MMColumnFormatted
          value={columnString(
            referrer.organisation?.name || alternative_organisation
          )}
          noWrap={false}
          highlight={search}
          isEditable={true}
          onChange={(value: string) =>
            handleValueChange("alternative_organisation", value, referrer)
          }
        />
      ),
    },
    {
      name: txt.get("admin.referrers.organisation_agb_code"),
      field: "organisation_agb_code",
      sortable: api.referrerIsSortableBy(
        referrerFieldToSortKey("organisation_agb_code")
      ),
      render: (organisationAgbCode: string, referrer: Referrer) => (
        <span>
          <MMColumnFormatted
            value={columnString(organisationAgbCode)}
            noWrap={true}
            highlight={search}
            isEditable={true}
            onChange={(value: string) =>
              handleValueChange("organisation_agb_code", value, referrer)
            }
          />{" "}
          {organisationAgbCode ? (
            <EuiLink
              href={`${AGB_VEKTIS_ORGANISATION_PUBLIC_URL_BASE}${organisationAgbCode}`}
              external={true}
              target="_blank"
            ></EuiLink>
          ) : (
            <></>
          )}
        </span>
      ),
    },
    {
      name: txt.get("admin.referrers.note"),
      field: "note",
      sortable: api.referrerIsSortableBy(referrerFieldToSortKey("note")),
      render: (note: string, referrer: Referrer) => (
        <MMColumnFormatted
          value={columnString(note)}
          noWrap={false}
          highlight={search}
          isEditable={true}
          onChange={(value: string) =>
            handleValueChange("note", value, referrer)
          }
        />
      ),
    },
    {
      name: txt.get("generic.is_active"),
      sortable: api.referrerIsSortableBy(referrerFieldToSortKey("is_active")),

      type: "boolean",
      field: "is_active",
      render: (is_active: boolean) => (
        <EuiHealth color={is_active ? "success" : "danger"}>
          <MMColumnFormatted
            value={is_active ? txt.get("generic.yes") : txt.get("generic.no")}
            noWrap={true}
            highlight={search}
          />
        </EuiHealth>
      ),
    },
  ];

  const getRowProps = (referrer: any) => {
    const { id } = referrer;
    return {
      "data-id": `row-${id}`,
      // onClick: (e: any) => {
      //   if (
      //     e.target.tagName !== "BUTTON" &&
      //     e.target.tagName !== "INPUT" &&
      //     e.target.tagName !== "A" &&
      //     e.target.id !== "EDIT"
      //   ) {
      //     navigate(`/admin/referrers/${id}`);
      //   }
      // },
    };
  };

  const isActiveOptions = [
    {
      value: "_",
      dropDownDisplay: (
        <EuiHealth color="success" style={{ lineHeight: "inherit" }}>
          {txt.get("generic.active")}
        </EuiHealth>
      ),
      inputDisplay: (
        <EuiText color="subdued" size="xs">
          {txt.uf("generic.select_x", txt.get("generic.active_state"))}
        </EuiText>
      ),
    },
    {
      value: "yes",

      inputDisplay: (
        <EuiHealth
          textSize="xs"
          color="success"
          style={{ lineHeight: "inherit" }}
        >
          {txt.get("generic.active")}
        </EuiHealth>
      ),
    },
    {
      value: "no",
      inputDisplay: (
        <EuiHealth
          textSize="xs"
          color="danger"
          style={{ lineHeight: "inherit" }}
        >
          {txt.get("generic.inactive")}
        </EuiHealth>
      ),
    },
  ];
  const onIsActiveChange = (value: string) => {
    setIsActiveValue(value);
  };
  return (
    <Fragment>
      <EuiFlexGroup alignItems="flexStart">
        <EuiFlexItem grow={false}>
          <EuiFieldSearch
            compressed={true}
            placeholder={txt.get("generic.search")}
            value={searchFieldValue}
            isLoading={isLoading}
            isClearable={!isLoading}
            contentEditable={!isLoading}
            onChange={(event: any) => onSearchChange(event)}
            aria-label={txt.get("generic.search")}
          />
        </EuiFlexItem>
        {/* <EuiFlexItem grow={false}>
          <EuiComboBox
            compressed={true}
            style={{ width: "225px" }}
            aria-label={txt.uf(
              "generic.select_x",
              txt.get("admin.organisations.name")
            )}
            placeholder={txt.uf(
              "generic.select_x",
              txt.get("admin.organisations.name")
            )}
            // singleSelection={{ asPlainText: true }}
            options={toSelectOptions(organisations)}
            selectedOptions={selectedOrganisations}
            onChange={onOrganisationChange}
          />
        </EuiFlexItem> */}
        <EuiFlexItem grow={false}>
          <EuiSuperSelect
            compressed={true}
            style={{ width: "225px" }}
            options={isActiveOptions}
            valueOfSelected={isActiveValue}
            onChange={(value) => onIsActiveChange(value)}
          ></EuiSuperSelect>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFormRow display="rowCompressed" label={""}>
            <EuiFilterGroup
              contentEditable={false}
              compressed={true}
              style={{ width: "180px" }}
            >
              <EuiFilterButton
                hasActiveFilters={isOnlyAgbSelected === true}
                onClick={(e: any) => {
                  setIsOnlyAgbSelected(
                    isOnlyAgbSelected === true ? undefined : true
                  );
                }}
              >
                {txt.get("admin.referrers.only_agb")}
              </EuiFilterButton>
              <EuiFilterButton
                hasActiveFilters={isOnlyAgbSelected === false}
                onClick={(e: any) => {
                  setIsOnlyAgbSelected(
                    isOnlyAgbSelected === false ? undefined : false
                  );
                }}
              >
                {txt.get("admin.referrers.without_agb")}
              </EuiFilterButton>
            </EuiFilterGroup>
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFormRow display="rowCompressed" label={""}>
            <EuiFilterGroup
              contentEditable={false}
              compressed={true}
              style={{ width: "220px" }}
            >
              <EuiFilterButton
                hasActiveFilters={isOnlyOrganisationAgbSelected === true}
                onClick={(e: any) => {
                  setIsOnlyOrganisationAgbSelected(
                    isOnlyOrganisationAgbSelected === true ? undefined : true
                  );
                }}
              >
                {txt.get("admin.referrers.only_organisation_agb")}
              </EuiFilterButton>
              <EuiFilterButton
                hasActiveFilters={isOnlyOrganisationAgbSelected === false}
                onClick={(e: any) => {
                  setIsOnlyOrganisationAgbSelected(
                    isOnlyOrganisationAgbSelected === false ? undefined : false
                  );
                }}
              >
                {txt.get("admin.referrers.without_organisation_agb")}
              </EuiFilterButton>
            </EuiFilterGroup>
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup alignItems="flexEnd">
        <EuiFlexItem>
          <EuiText textAlign="right" size="xs">
            {resultCountInfo()}
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="s" />
      <EuiHorizontalRule margin="none" style={{ height: 1 }} />
      <EuiBasicTable
        loading={isLoading}
        tableLayout="auto"
        itemId="id"
        items={referrers}
        columns={columns}
        pagination={pagination}
        sorting={sorting}
        rowProps={getRowProps}
        noItemsMessage={
          error
            ? error
            : txt.uf(
                "generic.found_no_x",
                txt.get("admin.referrers.page_title")
              )
        }
        onChange={onReferrersChange}
      />
    </Fragment>
  );
}

export default MMReferrersList;
