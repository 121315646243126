import {  EuiText, EuiTourStep } from "@elastic/eui";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { useLocalStorage } from "store/local-storage";

function MMTours() {

    const tours = {
        tour_1_language : ['step_1']            
    }

    const [toursViewed, setToursViewed] = useLocalStorage('tours_viewed',[]);
    
    const showTourStep = (key:string) => {
        if (toursViewed.includes(key)) {
            return false;
        }
        return true;
    }

    const trackTourShown = (key:string) => {
        const newToursViewed = [...toursViewed,key].filter((v,i,a)=>a.indexOf(v)==i);
        setToursViewed(newToursViewed);
    }

    useEffect(() => {
        document.getElementById('tour_1_language_step_1')?.addEventListener('click',() => {            
            trackTourShown('tour_1_language.step_1');
        });
    },[]);

    return (
        <EuiTourStep
        anchor="#tour_1_language_step_1"
        content={
          <EuiText>
            {txt.html('tours.tour_1_language.step_1.content')}
          </EuiText>
        }
        isStepOpen={showTourStep('tour_1_language.step_1')}
        minWidth={300}
        maxWidth={450}
        onFinish={() => trackTourShown('tour_1_language.step_1')}
        step={1}
        stepsTotal={1}
        title={txt.get('tours.tour_1_language.step_1.title')}
        subtitle={txt.get('tours.tour_1_language.step_1.subtitle')}
        anchorPosition="leftDown"
      />
    );
}

export default MMTours;
