import {
  EuiComboBox,
  EuiFieldText,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFormRow,
  EuiIcon,
  EuiPopover,
  EuiText,
} from "@elastic/eui";
import { Fragment, useEffect, useState } from "react";
import { Order } from "store/data/order/order";
import { Organisation } from "store/data/organisation/organisation";
import {
  PersonalDetails,
  fullName,
} from "store/data/personal-details/personal-details";
import { FocusState, MMOrderEntryInputProps } from "../order-entry";
import txt from "helpers/text-helper";
import { updateField } from "hoc/helper-hooks";
import env from "helpers/env-helper";
import { viewName } from "store/data/location/location";

const toPractitionerOption = (order: any, practitionerOptions: any) => {
  let result: any = null;
  if (order && order.practitioner && practitionerOptions) {
    result = practitionerOptions.find(
      (practitionerOption: any) =>
        practitionerOption.value === order.practitioner
    );
  }

  return result;
};

const toLocationOption = (order: any, locationOptions: any) => {
  let result: any = null;

  if (order && order.location_id && locationOptions) {
    result = locationOptions.find(
      (locationOption: any) => locationOption.value === order.location_id
    );
  }
  return result;
};

const toDeliveryMethodOption = (order: any, deliveryMethodOptions: any) => {
  let result: any = null;

  if (order && order.delivery_method && deliveryMethodOptions) {
    result = deliveryMethodOptions.find(
      (deliveryMethodOption: any) =>
        deliveryMethodOption.value === order.delivery_method
    );
  }
  return result;
};

function MMOrderInputWhoWhere(props: MMOrderEntryInputProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedLocation, setSelectedLocation] = useState<any>();
  const [locationOptions, setLocationOptions] = useState<any[]>([]);
  const [locationError, setLocationError] = useState<string>("");
  const [practitionerOptions, setPractitionerOptions] = useState<any[]>([]);
  const [selectedPractitioner, setSelectedPractitioner] = useState<any>();
  const [practitionerError, setPractitionerError] = useState<string>("");

  const [deliveryMethodOptions, setDeliveryMethodOptions] = useState<any[]>([]);
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState<any>();
  const [deliveryMethodError, setDeliveryMethodError] = useState<string>("");

  useEffect(() => {
    if (
      props.focusState &&
      props.focusState === FocusState.Validating &&
      props.showValidation
    ) {
      setIsOpen(true);
    }
  }, [props.focusState, props.showValidation]);

  useEffect(() => {
    if (!props.deliveryMethods) {
      setDeliveryMethodOptions([]);
    } else {
      setDeliveryMethodOptions(
        props.deliveryMethods.map((deliveryMethod: any) => ({
          label: deliveryMethod.name,
          value: deliveryMethod.name,
        }))
      );
    }
  }, [props.deliveryMethods]);

  useEffect(() => {
    if (props.order && props.order.delivery_method) {
      setSelectedDeliveryMethod(
        toDeliveryMethodOption(props.order, deliveryMethodOptions)
      );
    } else {
      setSelectedDeliveryMethod(null);
    }
  }, [props.order, deliveryMethodOptions]);

  useEffect(() => {
    if (!props.locations) {
      setLocationOptions([]);
    } else {
      setLocationOptions(
        props.locations.map((location: any) => ({
          label: viewName(location),
          value: location.id,
        }))
      );
    }
  }, [props.locations]);

  useEffect(() => {
    if (props.order && props.order.location_id) {
      setSelectedLocation(toLocationOption(props.order, locationOptions));
    } else {
      setSelectedLocation(null);
    }
  }, [props.order, locationOptions]);

  useEffect(() => {
    if (!props.practitioners) {
      setPractitionerOptions([]);
    } else {
      setPractitionerOptions(
        props.practitioners.map((practitioner: any) => ({
          label: `${practitioner.firstName} ${practitioner.lastName}`,
          value: practitioner.id,
          data_organisation_id:
            practitioner.organisationId ||
            env("REACT_APP_MANOMETRIC_ORGANISATION_ID"),
        }))
      );
    }
  }, [props.practitioners]);

  useEffect(() => {
    if (
      (!props.order || !props.order.practitioner) &&
      props.personalDetails &&
      props.onChange
    ) {
      props.onChange(
        updateField(
          updateField(
            props.order,
            "organisation_id",
            props.personalDetails.organisation_id
          ),
          "practitioner",
          props.personalDetails.id
        )
      );
    } else {
      setSelectedPractitioner(
        toPractitionerOption(props.order, practitionerOptions)
      );
    }
  }, [props.order, practitionerOptions, props.personalDetails]);

  useEffect(() => {
    if (
      (!props.order || !props.order.delivery_method) &&
      props.deliveryMethods &&
      props.deliveryMethods.length === 1 &&
      props.onChange
    ) {
      props.onChange(
        updateField(
          props.order,
          "delivery_method",
          props.deliveryMethods[0].name
        )
      );
    } else {
      setSelectedDeliveryMethod(
        toDeliveryMethodOption(props.order, deliveryMethodOptions)
      );
    }
  }, [props.order, deliveryMethodOptions]);

  useEffect(() => {
    if (
      (!props.order || !props.order.location_id) &&
      props.locations &&
      props.locations.length === 1 &&
      props.onChange
    ) {
      props.onChange(
        updateField(props.order, "location_id", props.locations[0].id)
      );
    } else {
      setSelectedLocation(toLocationOption(props.order, locationOptions));
    }
  }, [props.order, locationOptions]);

  useEffect(() => {
    let isComplete: boolean = true;
    if (props.inputs?.practitioner && props.inputs?.practitioner.validator) {
      const validationResult =
        props.inputs.practitioner.validator(selectedPractitioner);
      setPractitionerError(
        validationResult.result ? "" : validationResult.message
      );
      isComplete &&= validationResult.result;
    }
    if (props.inputs?.location_id && props.inputs?.location_id.validator) {
      const validationResult =
        props.inputs.location_id.validator(selectedLocation);
      setLocationError(validationResult.result ? "" : validationResult.message);
      isComplete &&= validationResult.result;
    }

    if (
      props.inputs?.delivery_method &&
      props.inputs?.delivery_method.validator
    ) {
      const validationResult = props.inputs.delivery_method.validator(
        selectedDeliveryMethod
      );
      setDeliveryMethodError(
        validationResult.result ? "" : validationResult.message
      );
      isComplete &&= validationResult.result;
    }

    if (isComplete) {
      setIsOpen(false);
    }
  }, [selectedPractitioner, selectedLocation, selectedDeliveryMethod]);

  const onPractitionerChanged = (practitioner: any) => {
    if (props.onChange) {
      props.onChange(
        updateField(
          updateField(
            props.order,
            "organisation_id",
            practitioner ? practitioner.data_organisation_id : null
          ),
          "practitioner",
          practitioner ? practitioner.value : null
        )
      );
    } else {
      setSelectedPractitioner(practitioner);
    }
  };

  const onLocationChanged = (location: any) => {
    if (props.onChange) {
      props.onChange(
        updateField(
          props.order,
          "location_id",
          location ? location.value : null
        )
      );
    } else {
      setSelectedLocation(location);
    }
  };

  const onDeliveryMethodChanged = (deliveryMethod: any) => {
    if (props.onChange) {
      props.onChange(
        updateField(
          props.order,
          "delivery_method",
          deliveryMethod ? deliveryMethod.value : null
        )
      );
    } else {
      setSelectedDeliveryMethod(deliveryMethod);
    }
  };

  const close = () => {
    setIsOpen(false);
    // if (props.changeFocusState) {
    //   props.changeFocusState(FocusState.Order);
    // }
  };
  return (
    <EuiPopover
      isOpen={isOpen}
      closePopover={close}
      panelPaddingSize="l"
      button={
        <EuiFlexGrid
          gutterSize="none"
          className="hoverable"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <EuiFlexGroup gutterSize="s" justifyContent="flexEnd">
            <EuiText
              className="who-where-practioner"
              color={
                !!practitionerError && props.hasValidation
                  ? "danger"
                  : undefined
              }
            >
              {selectedPractitioner
                ? selectedPractitioner.label
                : txt.uf(
                    "generic.select_x",
                    txt.get("orders.order.practitioner")
                  )}
            </EuiText>
            <EuiIcon
              size="s"
              type={props.isEditable ? "pencil" : "eye"}
              color="#ffffff"
              className="hoverable-highlight"
            />
          </EuiFlexGroup>
          <EuiText
            className="who-where-location"
            style={{ opacity: 0.5 }}
            color={
              (!!locationError || !!deliveryMethodError) && props.hasValidation
                ? "danger"
                : undefined
            }
          >
            {selectedLocation
              ? selectedLocation.label
              : txt.uf(
                  "generic.select_x",
                  txt.get("orders.order.delivery_location")
                )}
          </EuiText>
        </EuiFlexGrid>
      }
    >
      <EuiFormRow
        className={
          !props.isEditable ? "readonly-input product-input" : "product-input"
        }
        isDisabled={!props.isEditable}
        display="rowCompressed"
        label={txt.get("orders.order.practitioner")}
        isInvalid={props.hasValidation && !!practitionerError}
        error={props.hasValidation && practitionerError}
      >
        <EuiComboBox
          isDisabled={!props.isEditable}
          className={selectedPractitioner ? `option-selected` : ""}
          style={{ width: "280px" }}
          color="accent"
          isLoading={isLoading}
          singleSelection={{ asPlainText: true }}
          compressed={true}
          placeholder={txt.uf(
            "generic.select_x",
            txt.get("orders.order.practitioner")
          )}
          options={practitionerOptions}
          selectedOptions={selectedPractitioner ? [selectedPractitioner] : []}
          onChange={(selected) =>
            onPractitionerChanged(selected.length > 0 ? selected[0] : null)
          }
        />
      </EuiFormRow>
      <EuiFormRow
        className={
          !props.isEditable ? "readonly-input product-input" : "product-input"
        }
        isDisabled={!props.isEditable}
        display="rowCompressed"
        label={txt.get("orders.order.delivery_location")}
        isInvalid={props.hasValidation && !!locationError}
        error={props.hasValidation && locationError}
        style={
          selectedLocation && locationOptions && locationOptions.length === 1
            ? { display: "none" }
            : {}
        }
      >
        <EuiComboBox
          isDisabled={!props.isEditable}
          className={selectedLocation ? `option-selected` : ""}
          style={{ width: "280px" }}
          color="accent"
          isLoading={isLoading}
          singleSelection={{ asPlainText: true }}
          compressed={true}
          placeholder={txt.uf(
            "generic.select_x",
            txt.get("orders.order.delivery_location")
          )}
          options={locationOptions}
          selectedOptions={selectedLocation ? [selectedLocation] : []}
          onChange={(selected) =>
            onLocationChanged(selected.length > 0 ? selected[0] : null)
          }
        />
      </EuiFormRow>
      <EuiFormRow
        className={
          !props.isEditable ? "readonly-input product-input" : "product-input"
        }
        isDisabled={!props.isEditable}
        display="rowCompressed"
        label={txt.get("orders.order.delivery")}
        isInvalid={props.hasValidation && !!deliveryMethodError}
        error={props.hasValidation && deliveryMethodError}
        style={
          selectedDeliveryMethod &&
          deliveryMethodOptions &&
          deliveryMethodOptions.length === 1
            ? { display: "none" }
            : {}
        }
      >
        <EuiComboBox
          isDisabled={!props.isEditable}
          className={selectedLocation ? `option-selected` : ""}
          style={{ width: "280px" }}
          color="accent"
          isLoading={isLoading}
          singleSelection={{ asPlainText: true }}
          compressed={true}
          placeholder={txt.uf(
            "generic.select_x",
            txt.get("orders.order.delivery")
          )}
          options={deliveryMethodOptions}
          selectedOptions={
            selectedDeliveryMethod ? [selectedDeliveryMethod] : []
          }
          onChange={(selected) =>
            onDeliveryMethodChanged(selected.length > 0 ? selected[0] : null)
          }
        />
      </EuiFormRow>
    </EuiPopover>
  );
}

export default MMOrderInputWhoWhere;
