import { EuiButton, EuiFlexItem, EuiSpacer } from "@elastic/eui";
import MMPage, { PageLink } from "components/layouts/page/page";
import txt from "helpers/text-helper";
import { adminNav } from "./admin";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthenticationHelper from "helpers/authentication-helper";
import MMScannersList from "./scanners-list";

function MMAdminScanners() {
  const [buttonsToShow, setButtonsToShow] = useState<any[]>([]);
  const [subPages, setSubPages] = useState<PageLink[]>([]);
  useEffect(() => {
    const getSubPages = async () => {
      setSubPages(await adminNav("/admin/scanners"));
    };

    getSubPages();
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      let buttons: any[] = [];
      // if (await AuthenticationHelper.hasPermission(["scanners#edit_all"])) {
      //   buttons.push(
      //     <EuiButton
      //       key="add_scanner"
      //       color="accent"
      //       size="s"
      //       fill
      //       iconType="plus"
      //       onClick={() => navigate("/admin/scanners/new")}
      //     >
      //       {txt.get("generic.add_new_x", txt.lo("admin.scanners.name"))}
      //     </EuiButton>
      //   );
      // }
      setButtonsToShow(buttons);
    };
    setInterfaceForPermissions();
  }, []);

  const buttons = () => {
    return buttonsToShow;
  };

  return (
    <MMPage
      title={`${txt.get("admin.page_title")} ${txt.lo(
        "admin.scanners.page_title"
      )}`}
      subPages={subPages}
      backTo="/admin"
      backToText={txt.uf("generic.back_to", txt.get("admin.page_title"))}
      sideActions={buttons()}
    >
      <EuiFlexItem>
        <EuiSpacer />
        <MMScannersList />
      </EuiFlexItem>
    </MMPage>
  );
}
export default MMAdminScanners;
