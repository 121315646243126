import MMPage from 'components/layouts/page/page';
import { EuiButton, EuiFlexItem, EuiSpacer, EuiText } from '@elastic/eui';
import { useNavigate } from 'react-router-dom';
import txt from 'helpers/text-helper';

function MMProductionAgenda() {

    const navigate = useNavigate();
    
    return (
        <MMPage title="Production Agenda">
            <EuiFlexItem>
                <EuiSpacer/>
                <EuiText>
                <p>{txt.get('production.agenda.page_title')}</p>
                <EuiButton onClick={() => (navigate('/production/agenda/import'))}>
                { txt.get('production.agenda.import.intro') }
                </EuiButton>
                </EuiText>            
            </EuiFlexItem>
        </MMPage>            
    );
}

export default MMProductionAgenda;