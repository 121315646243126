import { Component } from "react";

import MMPage from "components/layouts/page/page";
import { EuiButton, EuiFlexItem, EuiSpacer, EuiText } from "@elastic/eui";
import AuthenticationHelper from "helpers/authentication-helper";
import { useNavigate } from "react-router-dom";
import txt from "helpers/text-helper";

const MMScanners = () => {
  const groups = AuthenticationHelper.getGroups();

  const navigate = useNavigate();

  let buttons: any = [];

  if (groups.includes("/manometric")) {
    buttons.push(
      <EuiButton
        color="accent"
        size="s"
        iconType="crosshairs"
        onClick={() => navigate("/scanning/scans")}
      >
        {txt.uf("generic.view_x", txt.get("scanning.scans.page_title"))}
      </EuiButton>
    );
  }

  return (
    <MMPage
      hideTitle={true}
      title={txt.get("scanning.scanners.page_title")}
      topActions={buttons}
    >
      <EuiFlexItem>
        <EuiSpacer />

        <iframe
          src="https://grafana.manometric.cloud/d-solo/Mb_kbDA4z/manox-connect-embed?orgId=1&panelId=4&theme=light"
          width="100%"
          height="600"
        ></iframe>
      </EuiFlexItem>
    </MMPage>
  );
};

export default MMScanners;
