import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import userReducer from "store/data/user/user";
import routeReducer from "store/components/route/route";
import toastReducer from "store/components/toast/toast";
import alertConfirmReducer from "store/components/alert/confirm";
import notificationReducer from "store/components/notification/notification";
import eventReducer from "store/data/event/event";

export const store = configureStore({
  reducer: {
    user: userReducer,
    route: routeReducer,
    toast: toastReducer,
    alertConfirm: alertConfirmReducer,
    notification: notificationReducer,
    event: eventReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
