import { EuiBottomBar, EuiFlexGroup, EuiFlexItem, EuiText } from "@elastic/eui";
import env from "helpers/env-helper";

function MMBetaBar(props: any) {
  const showBeta = () => {
    const hasBetaBar = env("REACT_APP_SHOW_BETA_BAR");
    return hasBetaBar && parseInt(hasBetaBar) === 1;
  };
  return showBeta() ? (
    <EuiBottomBar className="demo-bar" paddingSize="none">
      <EuiFlexGroup gutterSize="s" justifyContent="center" alignItems="center">
        <EuiFlexItem grow={false}>
          <EuiText size="s" color="#ffffffaa">
            BETA
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiBottomBar>
  ) : (
    <></>
  );
}

export default MMBetaBar;
