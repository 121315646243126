import {
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  useIsWithinBreakpoints,
} from "@elastic/eui";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import {
  MMOrderLineFieldProps,
  MMOrderLineProductInput,
} from "../order-line-entry";
import { Order } from "store/data/order/order";

const toRemakeReasonOption = (
  selection: MMOrderLineProductInput,
  remakeReasons: any
) => {
  let result: any;

  if (selection && remakeReasons) {
    result = remakeReasons.find(
      (remakeReason: any) => remakeReason.code === selection.remakeReason
    );
  }

  if (result) {
    result = { label: result.reason, value: result.code };
  }
  return result;
};

const isShownForOrderType = (
  selection?: MMOrderLineProductInput,
  orderTypes?: any[]
) => {
  if (!selection || !selection.orderType) return false;
  const orderType: any = orderTypes?.find(
    (type: any) => type.name === selection.orderType
  );
  if (!orderType) return false;
  return orderType.reason_required;
};

function MMRemakeReason(props: MMOrderLineFieldProps) {
  const isMobile: boolean = useIsWithinBreakpoints(["xs", "s"]);
  const [order] = useState<Order | null>(props.order || null);

  const [remakeReasons] = useState<any[]>(props.remakeReasons || []);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [remakeReasonOptions, setRemakeReasonOptions] = useState<any[]>([]);
  const [selectedRemakeReason, setSelectedRemakeReason] = useState<any>(
    toRemakeReasonOption(props.selection, props.remakeReasons)
  );

  useEffect(() => {
    setIsVisible(isShownForOrderType(props.selection, props.orderTypes));
  }, [props.selection, props.orderTypes]);

  useEffect(() => {
    setSelectedRemakeReason(
      toRemakeReasonOption(props.selection, props.remakeReasons)
    );
  }, [props.selection, remakeReasons]);

  const onRemakeReasonChange = (remakeReason: any) => {
    if (props.onChange) {
      props.onChange({
        ...(props.selection || {}),
        remakeReason:
          remakeReason && remakeReason.value ? remakeReason.value : "",
      } as MMOrderLineProductInput);
    } else {
      setSelectedRemakeReason(remakeReason);
    }
  };

  useEffect(() => {
    const result = [];

    for (let i = 0; i < remakeReasons.length; i++) {
      let remakeReason = remakeReasons[i];
      if (!result[remakeReason.category]) {
        result[remakeReason.category] = {
          label: remakeReason.category,
          options: [],
        } as EuiComboBoxOptionOption;
      }
      if (
        result[remakeReason.category] &&
        result[remakeReason.category].options
      ) {
        result[remakeReason.category].options?.push({
          label: `${remakeReason.reason}${
            remakeReason.remark ? ` (${remakeReason.remark})` : ""
          }`,
          value: remakeReason.code,
        } as EuiComboBoxOptionOption);
      }
    }
    const values = Object.values(result);
    setRemakeReasonOptions(values);
  }, [remakeReasons]);

  return isVisible ? (
    <EuiFlexGroup gutterSize="s">
      <EuiFlexItem>
        <EuiFormRow
          className="product-input"
          display="rowCompressed"
          label={props.showTitle ? txt.get("orders.order.remake_reason") : ""}
        >
          <EuiComboBox
            className={selectedRemakeReason ? `option-selected` : ""}
            style={isMobile ? {} : {}}
            color="accent"
            isLoading={isLoading}
            singleSelection={{ asPlainText: true }}
            compressed={true}
            placeholder={txt.get("orders.order.remake_reason")}
            options={remakeReasonOptions}
            selectedOptions={selectedRemakeReason ? [selectedRemakeReason] : []}
            onChange={(selected) =>
              onRemakeReasonChange(selected.length > 0 ? selected[0] : null)
            }
          />
        </EuiFormRow>
      </EuiFlexItem>
    </EuiFlexGroup>
  ) : (
    <></>
  );
}

export default MMRemakeReason;
