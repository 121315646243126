import {
  EuiButtonIcon,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiPopover,
  EuiSpacer,
  EuiTab,
  EuiTabs,
  EuiText,
} from "@elastic/eui";
import { ApiResponse, ApiResponseStatus } from "api/api-helper";
import ConnectAPIHelper from "api/connect-api-helper";
import MMTitle from "components/layouts/title/title";
import DateHelper from "helpers/date-helper";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { Client } from "store/data/client/client";
import { Referral } from "store/data/referral/referral";
import MMReferralInfo from "./referral-info";
import { Order } from "store/data/order/order";
import MMOrderInfo from "./order-info";
import { useLocalStorage } from "store/local-storage";
import { Costing } from "store/data/costing/costing";
import AuthenticationHelper from "helpers/authentication-helper";
import MMCostingInfo from "./costing-info";
import MMProductionInfo from "./production-info";
import { Appointment } from "store/data/appointment/appointment";
import ClientsAPIHelper from "api/clients-api.helper";
import MMEvaluationInfo from "./evaluation-info";

const VISIBLE_REFERRALS: number = 4;
export interface MMCareInfoProps {
  clientId: number;
  client: Client | null;
  isEditable?: boolean;
  isHeaderShown?: boolean;
}

function MMCareInfo(props: MMCareInfoProps) {
  const refKey: string = `client_${props.clientId || ""}_referral`;

  const api: ConnectAPIHelper = new ConnectAPIHelper();
  const clientApi: ClientsAPIHelper = new ClientsAPIHelper();

  const [client, setClient] = useState<Client>();
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [referrals, setReferrals] = useState<Referral[]>([]);
  const [isOtherReferralsOpen, setIsOtherReferralsOpen] =
    useState<boolean>(false);
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [orders, setOrders] = useState<Order[]>([]);
  const [costings, setCostings] = useState<Costing[]>([]);
  const [productions, setProductions] = useState<any[]>([]);
  const [evaluations, setEvaluations] = useState<any[]>([]);
  const [selectedReferralId, setSelectedReferralId] = useLocalStorage(
    refKey,
    ""
  );
  const [isHeaderShown, setIsHeaderShown] = useState<boolean>(false);

  useEffect(() => {
    setIsEditable(!!props.isEditable);
    if (props.client) {
      setClient(props.client);
    } else {
      setClient(undefined);
    }
  }, [props.client, props.isEditable, props.isHeaderShown]);

  useEffect(() => {
    const loadCareInfo = async (client: Client) => {
      const referralsResult: ApiResponse = await api.getReferrals(
        {
          client_code: client.client_code,
        },
        undefined,
        undefined,
        "intake_at",
        "desc"
      );
      if (referralsResult && referralsResult.status === ApiResponseStatus.OK) {
        setReferrals(referralsResult.result);
      }
      if (!selectedReferralId) {
        if (referralsResult.result.length > 0) {
          setSelectedReferralId(referralsResult.result[0].id);
        } else {
          setSelectedReferralId("");
        }
      }
    };

    if (client) {
      loadCareInfo(client);
    } else {
      setReferrals([]);
    }
  }, [client]);

  useEffect(() => {
    const loadAppointmentInfo = async (client: Client, referralId?: number) => {
      if (await AuthenticationHelper.hasPermission(["appointments#read_all"])) {
        let filters: any = {};
        filters.vlot_client_id = client.vlot_id;
        if (referralId) {
          filters.referral_ids = [referralId];
        }
        const appointmentsResult: ApiResponse = await clientApi.getAppointments(
          filters
        );
        if (
          appointmentsResult &&
          appointmentsResult.status === ApiResponseStatus.OK
        ) {
          setAppointments(appointmentsResult.result);
        } else {
          console.log("appointmentsResult error", appointmentsResult.message);
          setAppointments([]);
        }
      }
    };
    const loadOrderInfo = async (client: Client, referralId?: number) => {
      let filters: any = {};
      filters.client_code = client.client_code;
      if (referralId) {
        filters.referral_ids = [referralId];
      }
      const ordersResult: ApiResponse = await api.getOrders(filters);
      if (ordersResult && ordersResult.status === ApiResponseStatus.OK) {
        setOrders(ordersResult.result);
      } else {
        console.log("ordersResult error", ordersResult.message);
        setOrders([]);
      }
    };

    const loadCostingInfo = async (client: Client, referralId?: number) => {
      if (
        await AuthenticationHelper.hasPermission([
          "costings#edit_all",
          "costings#read_all",
        ])
      ) {
        let filters: any = {};
        filters.client_code = client.client_code;
        if (referralId) {
          filters.referral_ids = [referralId];
        }
        const costingsResult: ApiResponse = await api.getCostings(filters);
        if (costingsResult && costingsResult.status === ApiResponseStatus.OK) {
          setCostings(costingsResult.result);
        } else {
          console.log("costingsResult error", costingsResult.message);
          setCostings([]);
        }
      }
    };
    const loadProductionInfo = async (client: Client, referralId?: number) => {
      if (await AuthenticationHelper.hasPermission(["productions#read_all"])) {
        let filters: any = {};
        filters.client = client.client_code;
        if (referralId) {
          filters.referral_ids = [referralId];
        }
        const productionsResult: ApiResponse = await api.getProductions(
          filters
        );
        if (
          productionsResult &&
          productionsResult.status === ApiResponseStatus.OK
        ) {
          setProductions(productionsResult.result);
        } else {
          console.log("productionsResult error", productionsResult.message);
          setProductions([]);
        }
      }
    };
    const loadEvaluationInfo = async (client: Client, referralId?: number) => {
      if (
        await AuthenticationHelper.hasPermission([
          "sales#read_all",
          "orders#evaluate_all",
        ])
      ) {
        let filters: any = {};
        filters.client_code = client.client_code;
        if (referralId) {
          filters.referral_ids = [referralId];
        }
        const evaluationsResult: ApiResponse = await api.getNpsEvaluations(
          filters
        );
        if (
          evaluationsResult &&
          evaluationsResult.status === ApiResponseStatus.OK
        ) {
          setEvaluations(evaluationsResult.result);
        } else {
          console.log("evaluationsResult error", evaluationsResult.message);
          setEvaluations([]);
        }
      }
    };
    if (client && selectedReferralId) {
      loadAppointmentInfo(client, selectedReferralId);
      loadOrderInfo(client, selectedReferralId);
      loadOrderInfo(client, selectedReferralId);
      loadCostingInfo(client, selectedReferralId);
      loadProductionInfo(client, selectedReferralId);
      loadEvaluationInfo(client, selectedReferralId);
    }
  }, [client, selectedReferralId]);

  const onClient = (client: Client) => {
    setClient(client);
  };

  const renderReferralSelectionTabs = (referrals: Referral[]) => {
    const firstReferrals: Referral[] = referrals.slice(0, VISIBLE_REFERRALS);
    const otherReferrals: Referral[] =
      referrals.length > VISIBLE_REFERRALS
        ? referrals.slice(VISIBLE_REFERRALS)
        : [];

    return (
      <EuiTabs size="s" style={{ width: "600px", maxWidth: "600px" }}>
        {firstReferrals.map((referral: Referral, i: number) => (
          <EuiTab
            onClick={(e: any) => {
              setSelectedReferralId(referral.id || "");
            }}
            isSelected={referral.id === selectedReferralId}
            key={`referral-tab-${i}`}
          >
            {DateHelper.toDate(referral.intake_at || referral.created_at)}
          </EuiTab>
        ))}
        {otherReferrals.length > 0 ? (
          <EuiPopover
            isOpen={isOtherReferralsOpen}
            closePopover={() => setIsOtherReferralsOpen(false)}
            button={
              <EuiTab
                isSelected={
                  selectedReferralId &&
                  otherReferrals
                    .map((referral: any) => referral.id)
                    .includes(selectedReferralId)
                }
                onClick={(e: any) => {
                  setIsOtherReferralsOpen(!isOtherReferralsOpen);
                }}
              >
                ...
              </EuiTab>
            }
          >
            <EuiFlexGrid>
              {otherReferrals.map((referral: Referral, i: number) => (
                <EuiText
                  style={
                    referral.id === selectedReferralId
                      ? { fontWeight: "600", cursor: "pointer" }
                      : { cursor: "pointer" }
                  }
                  onClick={(e: any) => {
                    setSelectedReferralId(referral.id || "");
                  }}
                  size="xs"
                  key={`referral-other-${i}`}
                >
                  {DateHelper.toDate(referral.intake_at || referral.created_at)}
                </EuiText>
              ))}
            </EuiFlexGrid>
          </EuiPopover>
        ) : (
          <></>
        )}
        {referrals.length > 1 ? (
          <EuiTab
            isSelected={selectedReferralId === ""}
            key="all"
            onClick={(e: any) => {
              setSelectedReferralId("");
            }}
          >
            {txt.get("generic.all")}
          </EuiTab>
        ) : (
          <></>
        )}
        {referrals.length === 0 ? (
          <EuiTab
            isSelected={selectedReferralId === ""}
            key="all"
            onClick={(e: any) => {
              setSelectedReferralId("");
            }}
          >
            {"-"}
          </EuiTab>
        ) : (
          <></>
        )}
      </EuiTabs>
    );
  };

  const renderReferral = (referral: Referral | undefined) =>
    referral ? (
      <MMReferralInfo
        key={`referral-${referral.id}`}
        client={client || null}
        referral={referral}
      />
    ) : (
      <></>
    );

  const renderReferrals = (referrals: Referral[]) =>
    referrals.map((referral: Referral) => renderReferral(referral));

  return client ? (
    <EuiFlexGrid className="subtle-editing">
      <EuiFlexItem>
        <MMTitle text={txt.get("clients.care_info.referrals.name")} />
        <EuiFlexGroup gutterSize="none">
          <EuiFlexItem grow={true}>
            {renderReferralSelectionTabs(referrals)}
          </EuiFlexItem>
          <EuiButtonIcon
            aria-label="add"
            style={{
              whiteSpace: "nowrap",
              fontWeight: "600",
              position: "relative",
              top: "14px",
              paddingBottom: "6px",
            }}
            iconType={"merge"}
          />
          <EuiButtonIcon
            aria-label="add"
            style={{
              whiteSpace: "nowrap",
              fontWeight: "600",
              position: "relative",
              top: "14px",
              paddingBottom: "6px",
            }}
            iconType={"plus"}
          />
        </EuiFlexGroup>
        <EuiPanel className="subtle-panel">
          <EuiSpacer size="s" />
          {selectedReferralId ? (
            renderReferral(
              referrals.find(
                (referral: Referral) => referral.id === selectedReferralId
              )
            )
          ) : referrals ? (
            <EuiText size="xs" color="subdued">
              {txt.get(
                "clients.care_info.referrals.x_referrals",
                referrals.length
              )}
            </EuiText>
          ) : (
            <></>
          )}
        </EuiPanel>
      </EuiFlexItem>
      {orders ? (
        <EuiFlexItem>
          <MMOrderInfo client={client} orders={orders} />
        </EuiFlexItem>
      ) : (
        <></>
      )}
      {productions ? (
        <EuiFlexItem>
          <MMProductionInfo client={client} productions={productions} />
        </EuiFlexItem>
      ) : (
        <></>
      )}
      {costings ? (
        <EuiFlexItem>
          <MMCostingInfo client={client} costings={costings} />
        </EuiFlexItem>
      ) : (
        <></>
      )}
      {evaluations ? (
        <EuiFlexItem>
          <MMEvaluationInfo client={client} evaluations={evaluations} />
        </EuiFlexItem>
      ) : (
        <></>
      )}
    </EuiFlexGrid>
  ) : (
    <></>
  );
}

export default MMCareInfo;
