import {
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSpacer,
  EuiText,
  EuiTitle,
  useIsWithinMaxBreakpoint,
} from "@elastic/eui";
import { FileType } from "helpers/file-upload-helper";
import txt from "helpers/text-helper";
import { AttachedToType } from "store/data/file/file";
import { File } from "store/data/file/file";
import { useEffect, useState } from "react";
import ConnectAPIHelper from "api/connect-api-helper";
import { useDispatch } from "react-redux";
import { toastAdd } from "store/components/toast/toast";
import MMFiles from "components/layouts/files/files";
import MMFileUpload from "components/layouts/input/file-upload";
import { MMOrderLineDeliveryFieldProps } from "../order-line-delivery";
import { FITTING_EMPTY, Fitting } from "store/data/fitting/fitting";

function MMDeliveryAttachments(props: MMOrderLineDeliveryFieldProps) {
  const dispatch = useDispatch();
  const api = new ConnectAPIHelper();
  const isMobileScreen: boolean = useIsWithinMaxBreakpoint("xs");

  const [isLoading, setIsLoading] = useState(false);

  const [fitting, setFitting] = useState<Fitting | null | undefined>(
    props.fitting
  );
  const [files, setFiles] = useState<File[]>(props.fitting?.files || []);
  const [fileToAdd, setFileToAdd] = useState<File>();

  useEffect(() => {
    setFiles((files) =>
      props.fitting && props.fitting.files ? props.fitting.files : []
    );
    setFitting(props.fitting);
  }, [props.fitting]);

  const handleAdded = (file: File) => {
    setFileToAdd((old) => file);
  };

  useEffect(() => {
    if (fileToAdd && props.onChange && files) {
      props.onChange(
        {
          ...(props.fitting || FITTING_EMPTY),
          files: files.concat([fileToAdd]),
        },
        props.orderLineId
      );
      setFileToAdd(undefined);
    }
  }, [fileToAdd]);

  const handleDelete = (fileId: number) => {
    const deleteFile = async (fileId: number) => {
      const result = await api.removeOrderFile(
        fileId,
        props.order?.id,
        undefined,
        undefined,
        props.fitting?.id || undefined
      );
      if (result && props.onChange && files) {
        const updatedFiles = files.filter((file: any) => file.id !== fileId);
        props.onChange(
          { ...(props.fitting || FITTING_EMPTY), files: updatedFiles },
          props.orderLineId
        );
      } else {
        dispatch(
          toastAdd(txt.get("orders.order.delete_file_failed"), null, "danger")
        );
      }
    };
    deleteFile(fileId);
  };

  return (
    <EuiFlexGrid gutterSize="none">
      <EuiFlexGroup gutterSize="xs" direction="column">
        <EuiSpacer size="s" />
        <EuiFormRow
          label={""}
          style={
            !isMobileScreen && props.maxHeight
              ? { overflowX: "auto", height: props.maxHeight }
              : {}
          }
        >
          <EuiFlexGroup direction="column" style={{ width: "220px" }}>
            {files.length > 0 ? (
              <EuiFlexItem>
                <MMFiles files={files} onDelete={handleDelete} />
              </EuiFlexItem>
            ) : (
              <></>
            )}
            {props.isEditable ? (
              <EuiFlexItem>
                <MMFileUpload
                  multiple={true}
                  fileTypes={[
                    FileType.Docx,
                    FileType.Image,
                    FileType.Pdf,
                    FileType.Text,
                  ]}
                  attachmentOptions={{
                    orderId: props.order?.id || undefined,
                    attachedToId: props.fitting?.id || undefined,
                    attachedToType: AttachedToType.OrderLine,
                  }}
                  onAdded={handleAdded}
                />
              </EuiFlexItem>
            ) : (
              <></>
            )}
          </EuiFlexGroup>
        </EuiFormRow>
      </EuiFlexGroup>
    </EuiFlexGrid>
  );
}

export default MMDeliveryAttachments;
