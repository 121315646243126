import MMPage from "components/layouts/page/page";
import { EuiFlexItem, EuiSpacer } from "@elastic/eui";
import txt from "helpers/text-helper";
import MMProductionsList from "./production-productions-list";

function MMProductions() {
  return (
    <MMPage title={txt.get("production.productions.page_title")}>
      <EuiFlexItem>
        <EuiSpacer />
        <MMProductionsList />
      </EuiFlexItem>
    </MMPage>
  );
}

export default MMProductions;
