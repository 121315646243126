import {
  EuiButton,
  EuiComboBox,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
  EuiSwitch,
  EuiText,
  EuiTitle,
} from "@elastic/eui";
import ConnectAPIHelper from "api/connect-api-helper";
import AuthenticationHelper from "helpers/authentication-helper";
import txt from "helpers/text-helper";
import { updateField, useStateWithCallback } from "hoc/helper-hooks";
import { Fragment, useEffect, useState } from "react";
import { Workflow } from "store/data/production/workflow";
import MMWorkflowSteps from "./workflow-steps";
import { WorkflowStep } from "store/data/production/workflow-step";

const ACRONYM_MAX_LENGTH: number = 12;
const WORKFLOW_IS_ACTIVE_DEFAULT: boolean = true;
export interface MMWorkflowProps {
  fields: {
    workflow: Workflow | null;
  };
  handleSubmit?: Function;
}

function MMWorkflow(props: MMWorkflowProps) {
  const api = new ConnectAPIHelper();
  const [workflow, setWorkflow] = useStateWithCallback(props.fields.workflow);
  const [editAllowed, setEditAllowed] = useState(false);

  useEffect(() => {
    const setPermissions = async () => {
      setEditAllowed(
        await AuthenticationHelper.hasPermission("workflows#edit_all")
      );
    };
    setPermissions();
  }, []);

  const onName = (name: string) => {
    setWorkflow(updateField(workflow, "name", name));
  };

  const onAcronymChange = (e: any) => {
    const input = e.target;
    const start = input.selectionStart;
    const end = input.selectionEnd;
    const acronym = e.target.value.toUpperCase();

    setWorkflow(
      {
        ...workflow,
        acronym,
      } as Workflow,
      () => input.setSelectionRange(start, end)
    );
  };

  const onActiveChange = (e: any) => {
    setWorkflow(updateField(workflow, "is_active", e.target.checked));
  };

  const onStringValue = (e: any) => e.target.value;

  const toSelectOptions = (list: any[]) => {
    return list.map((item) => ({
      label: item.name,
      "data-id": item.id,
    }));
  };

  const onWorkflowStepsChange = (steps: WorkflowStep[]) => {
    setWorkflow(updateField(workflow, "workflow_steps", steps));
  };
  const onSubmit = (e: any) => {
    if (props.handleSubmit) {
      props.handleSubmit(workflow);
    }
  };

  return (
    <EuiForm>
      <EuiFormRow label={txt.get("generic.name")}>
        <EuiFieldText
          disabled={!editAllowed}
          name="name"
          value={workflow?.name}
          onChange={(e) => onName(onStringValue(e))}
        />
      </EuiFormRow>
      <EuiFormRow label={txt.get("generic.acronym")}>
        <EuiFieldText
          disabled={!editAllowed}
          name="acronym"
          maxLength={ACRONYM_MAX_LENGTH}
          value={workflow?.acronym}
          onChange={onAcronymChange}
        />
      </EuiFormRow>
      <EuiFormRow
        label={
          <span id="workflow-is-active">{txt.get("generic.is_active")}</span>
        }
      >
        <EuiSwitch
          disabled={!editAllowed}
          onChange={onActiveChange}
          label={
            workflow?.is_active ? txt.get("generic.yes") : txt.get("generic.no")
          }
          checked={
            workflow && workflow.is_active !== undefined
              ? workflow.is_active
              : WORKFLOW_IS_ACTIVE_DEFAULT
          }
          aria-describedby="workflow-is-active"
        />
      </EuiFormRow>
      <EuiFlexItem grow={false}>
        <EuiSpacer />
        <EuiTitle size="xxs">
          <EuiText>{txt.get("admin.workflows.steps")}</EuiText>
        </EuiTitle>

        <MMWorkflowSteps
          fields={{ workflow, editAllowed }}
          onChange={onWorkflowStepsChange}
        />
        <EuiSpacer />
      </EuiFlexItem>
      {editAllowed ? (
        <Fragment>
          <EuiSpacer size="xxl" />
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiButton size="s" onClick={onSubmit} fill={true}>
                {txt.get("generic.save")}
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </Fragment>
      ) : (
        <></>
      )}
    </EuiForm>
  );
}
export default MMWorkflow;
