import {
  EuiBadge,
  EuiBadgeGroup,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPopover,
} from "@elastic/eui";
import { Fragment, useEffect, useState } from "react";
import {
  Order,
  OrderStatus,
  orderStatusDescription,
  orderToHealth,
} from "store/data/order/order";
import { MMOrderEntryInputProps } from "./order-entry";
import txt from "helpers/text-helper";
import AuthenticationHelper from "helpers/authentication-helper";
import { useNavigate } from "react-router-dom";
import ConnectAPIHelper from "api/connect-api-helper";

function MMOrderStatus(props: MMOrderEntryInputProps) {
  const [isStatusSelectOpen, setIsStatusSelectOpen] = useState(false);
  const [isStatusChangeAllowed, setIsStatusChangeAllowed] = useState(false);
  const [order, setOrder] = useState<Order | null>(props.order || null);

  const api = new ConnectAPIHelper();
  useEffect(() => {
    setOrder(props.order || null);
  }, [props.order]);
  const navigate = useNavigate();

  const statusOptions = Object.values(OrderStatus)
    .filter(
      (status) =>
        ![
          OrderStatus.Drafted,
          OrderStatus.Rejected,
          OrderStatus.DeliveredPartial,
          OrderStatus.ProducedPartial,
        ].includes(status)
    )
    .map((status) => (
      <EuiContextMenuItem
        key={status}
        onClick={() => {
          setIsStatusSelectOpen(false);
          if (order) {
            saveStatus(status, order);
          }
        }}
      >
        {orderStatusDescription(status)}
      </EuiContextMenuItem>
    ));

  const saveStatus = async (status: OrderStatus, order?: Order) => {
    if (status !== "DRAFTED" && order && order.id && order.status !== status) {
      const orderLineStatusses = order.order_lines.map((line) => ({
        order_line_id: line.id,
        status,
      }));
      await api.updateOrderStatus(
        order.id,
        status,
        undefined,
        orderLineStatusses
      );
      navigate(0);
    } else {
      console.log("status or order not changeable");
    }
  };

  useEffect(() => {
    const getIsPowerStatusChangeAllowed = async () => {
      const result = !!(
        order?.is_demo &&
        (await AuthenticationHelper.hasPermission("orders#edit_submitted_all"))
      );
      console.log("getIsPowerStatusChangeAllowed", result);
      setIsStatusChangeAllowed(result);
    };

    getIsPowerStatusChangeAllowed();
  }, []);

  return (
    <EuiFlexGrid gutterSize="s">
      {(order && order.is_demo) ||
      (!order?.id && AuthenticationHelper.isDemo()) ? (
        <EuiFlexItem grow={false}>
          <EuiBadge color="accent" style={{ width: "55px" }}>
            {txt.up("generic.demo")}
          </EuiBadge>
        </EuiFlexItem>
      ) : (
        <></>
      )}
      {order && order.id > 0 ? (
        // order.is_demo &&
        order.status !== OrderStatus.Drafted && isStatusChangeAllowed ? (
          <EuiFlexItem grow={false}>
            <EuiPopover
              id="save-status"
              button={
                <EuiFlexItem grow={false} style={{ maxWidth: "100px" }}>
                  <EuiBadgeGroup gutterSize="none">
                    <EuiBadge
                      color={orderToHealth(
                        order ? order.status : OrderStatus.Drafted
                      )}
                      iconSide="right"
                      iconType="pencil"
                      onClick={() => setIsStatusSelectOpen(!isStatusSelectOpen)}
                      onClickAriaLabel="change status"
                    >
                      {order ? orderStatusDescription(order.status) : ""}
                    </EuiBadge>
                  </EuiBadgeGroup>
                </EuiFlexItem>
              }
              isOpen={isStatusSelectOpen}
              closePopover={() => setIsStatusSelectOpen(false)}
              panelPaddingSize="none"
              anchorPosition="downRight"
              style={{ height: "100%" }}
            >
              <EuiContextMenuPanel size="s" items={statusOptions} />
            </EuiPopover>
          </EuiFlexItem>
        ) : (
          <EuiFlexItem grow={false} style={{ maxWidth: "100px" }}>
            <EuiBadgeGroup gutterSize="none">
              <EuiBadge
                color={orderToHealth(
                  order ? order.status : OrderStatus.Drafted
                )}
              >
                {order ? orderStatusDescription(order.status) : ""}
              </EuiBadge>
            </EuiBadgeGroup>
          </EuiFlexItem>
        )
      ) : (
        <></>
      )}
    </EuiFlexGrid>
  );
}

export default MMOrderStatus;
