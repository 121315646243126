import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiSpacer } from "@elastic/eui";
import MMPage, { PageLink } from "components/layouts/page/page";
import txt from "helpers/text-helper";
import { adminNav } from "./admin";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthenticationHelper from "helpers/authentication-helper";
import MMInsurersList from "./insurers-list";

function MMAdminInsurers() {
  const [buttonsToShow, setButtonsToShow] = useState<any[]>([]);
  const [subActionsToShow, setSubActionsToShow] = useState<any[]>([]);

  const navigate = useNavigate();
  const [subPages, setSubPages] = useState<PageLink[]>([]);
  useEffect(() => {
    const getSubPages = async () => {
      setSubPages(await adminNav("/admin/insurers"));
    };

    getSubPages();
  }, []);

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      let subActions: any[] = [];
      if (await AuthenticationHelper.hasPermission("insurers#edit_all")) {
        subActions.push(
          <EuiButton
            onClick={() => navigate("/admin/insurers/agencies")}
            size="s"
            iconType={"arrowRight"}
          >
            {txt.get("admin.insurers.agencies.page_title")}
          </EuiButton>
        );
      }
      setSubActionsToShow(subActions);

      let buttons: any[] = [];
      if (await AuthenticationHelper.hasPermission(["insurers#edit_all"])) {
        buttons.push(
          <EuiButton
            key="add_insurer"
            size="s"
            fill={true}
            iconType="plus"
            onClick={() => navigate("/admin/insurers/new")}
          >
            {txt.get("generic.add_new_x", txt.lo("admin.insurers.name"))}
          </EuiButton>
        );
      }
      setButtonsToShow(buttons);
    };
    setInterfaceForPermissions();
  }, []);

  const buttons = () => {
    return buttonsToShow;
  };

  const subActions = () => {
    return subActionsToShow;
  };

  return (
    <MMPage
      title={`${txt.get("admin.page_title")} ${txt.lo(
        "admin.insurers.page_title"
      )}`}
      subPages={subPages}
      backTo="/admin"
      backToText={txt.uf("generic.back_to", txt.get("admin.page_title"))}
      sideActions={buttons()}
    >
      <EuiFlexItem>
        <EuiFlexGroup>
          {subActions().map((subActionBlock: any, i: number) => (
            <EuiFlexItem grow={false} key={`sub-action-block-${i}`}>
              {subActionBlock}
            </EuiFlexItem>
          ))}
        </EuiFlexGroup>
        <EuiSpacer />
        <MMInsurersList />
      </EuiFlexItem>
    </MMPage>
  );
}
export default MMAdminInsurers;
