import { EuiIcon, EuiKeyPadMenu, EuiKeyPadMenuItem } from "@elastic/eui";
import MMPage, { PageLink } from "components/layouts/page/page";
import AuthenticationHelper from "helpers/authentication-helper";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const adminNav = async (activeHref?: string) => {
  let navItems: any[] = [];
  if (
    await AuthenticationHelper.hasPermission([
      "organisations#read_all",
      "organisations#edit_all",
    ])
  ) {
    navItems.push({
      title: txt.get("admin.organisations.page_title"),
      href: "/admin/organisations",
      active: activeHref && activeHref.indexOf("/admin/organisations") === 0,
      icon: "globe",
    });
  }

  if (
    await AuthenticationHelper.hasPermission([
      "locations#read_all",
      "locations#edit_all",
    ])
  ) {
    navItems.push({
      title: txt.get("admin.locations.page_title"),
      href: "/admin/locations",
      active: activeHref && activeHref.indexOf("/admin/locations") === 0,
      icon: "pin",
    });
  }

  if (
    await AuthenticationHelper.hasPermission([
      "personal_details#read_all",
      "personal_details#edit_all",
    ])
  ) {
    navItems.push({
      title: txt.get("admin.users.page_title"),
      href: "/admin/users",
      active: activeHref && activeHref.indexOf("/admin/users") === 0,
      icon: "user",
    });
  }

  if (
    await AuthenticationHelper.hasPermission([
      "insurers#read_all",
      "insurers#edit_all",
    ])
  ) {
    navItems.push({
      title: txt.get("admin.insurers.page_title"),
      href: "/admin/insurers",
      active: activeHref && activeHref === "/admin/insurers",
      icon: "help",
    });
  }

  if (
    await AuthenticationHelper.hasPermission([
      "contracts#read_all",
      "contracts#edit_all",
    ])
  ) {
    navItems.push({
      title: txt.get("admin.contracts.page_title_short"),
      href: "/admin/insurers/contracts",
      active:
        activeHref && activeHref.indexOf("/admin/insurers/contracts") === 0,
      icon: "function",
    });
  }

  if (
    await AuthenticationHelper.hasPermission([
      "invoices#read_all",
      "invoices#edit_all",
    ])
  ) {
    navItems.push({
      title: txt.get("admin.invoices.page_title"),
      href: "/admin/invoices",
      active: activeHref && activeHref === "/admin/invoices",
      icon: "documentation",
    });
  }
  if (
    await AuthenticationHelper.hasPermission([
      "referrers#read_all",
      "referrers#edit_all",
    ])
  ) {
    navItems.push({
      title: txt.get("admin.referrers.page_title"),
      href: "/admin/referrers",
      active: activeHref && activeHref === "/admin/referrers",
      icon: "temperature",
    });
  }

  if (
    await AuthenticationHelper.hasPermission([
      "scanners#read_all",
      "scanners#edit_all",
    ])
  ) {
    navItems.push({
      title: txt.get("admin.scanners.page_title"),
      href: "/admin/scanners",
      active: activeHref && activeHref.indexOf("/admin/scanners") === 0,
      icon: "compute",
    });
  }

  if (
    await AuthenticationHelper.hasPermission([
      "data_reload#execute",
      "validate_products#execute",
    ])
  ) {
    navItems.push({
      title: txt.get("admin.data.page_title"),
      href: "/admin/data",
      active: activeHref && activeHref.indexOf("/admin/data") === 0,
      icon: "database",
    });
  }

  if (
    await AuthenticationHelper.hasPermission([
      "workflows#read_all",
      "workflows#edit_all",
    ])
  ) {
    navItems.push({
      title: txt.get("admin.workflows.page_title"),
      href: "/admin/workflows",
      active: activeHref === "/admin/workflows",
      icon: "wrench",
    });
  }

  if (await AuthenticationHelper.hasPermission(["orders#edit_all"])) {
    navItems.push({
      title: txt.get("admin.data.order_matches.page_title"),
      href: "/admin/order-matches",
      active: activeHref && activeHref.indexOf("/admin/order-matches") === 0,
      icon: "documentEdit",
    });
  }

  if (await AuthenticationHelper.hasRole("super_admin")) {
    navItems.push({
      title: txt.get("admin.permissions.page_title"),
      href: "/admin/permissions",
      active: activeHref && activeHref.indexOf("/admin/permissions") === 0,
      icon: "lock",
    });
  }

  const subPages: PageLink[] = [
    {
      title: "",
      href: "/admin",
      active: !activeHref || activeHref === "/admin",
      // icon: "gear",
      items: navItems,
    },
  ];
  return subPages;
};

function MMAdmin() {
  const [subPages, setSubPages] = useState<PageLink[]>([]);

  useEffect(() => {
    const getSubPages = async () => {
      setSubPages(await adminNav());
    };
    getSubPages();
  }, []);

  const navigate = useNavigate();

  const menuItems = () => (
    <EuiKeyPadMenu>
      {subPages[0]?.items?.map((page: PageLink, i) => (
        <EuiKeyPadMenuItem
          key={`link-${i}`}
          onClick={() => navigate(page.href)}
          label={page.title}
        >
          {page.icon ? <EuiIcon type={page.icon} size="l" /> : <></>}
        </EuiKeyPadMenuItem>
      ))}
    </EuiKeyPadMenu>
  );

  return (
    <MMPage
      title={txt.get("admin.page_title")}
      backTo="/"
      backToText={txt.uf("generic.back_to", txt.get("dashboard.page_title"))}
      subPages={subPages}
    >
      <nav aria-label="Nav title">{menuItems()}</nav>
    </MMPage>
  );
}

export default MMAdmin;
