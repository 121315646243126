import MMPage from "components/layouts/page/page";
import { EuiButton, EuiFlexItem, EuiProgress } from "@elastic/eui";
import MMScanDetail from "./scan-detail";
import { useNavigate, useParams } from "react-router-dom";
import { Scan } from "store/data/scan/scan";
import { useEffect, useState } from "react";
import ManoXAPIHelper from "api/manox-api-helper";
import txt from "helpers/text-helper";

function MMScan() {
  const navigate = useNavigate();
  const { scanId } = useParams();

  const [scan, setScan] = useState({} as Scan);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const api = new ManoXAPIHelper();

  useEffect(() => {
    if (!scanId) {
      setError("no scan ID given");
    } else {
      const loadScan = async () => {
        const result = await api.getScan(scanId);
        setScan(result.result);
        if (result.status === "OK") {
          setIsLoading(false);
        } else {
          setError(result.message);
        }
      };

      loadScan();
    }
  }, []);

  return (
    <MMPage
      title={scan ? scan.name : "..."}
      share={true}
      topActions={[
        <EuiButton
          color="accent"
          aria-label={txt.get("generic.back_to", txt.get("orders.scans"))}
          iconType="arrowLeft"
          size="s"
          onClick={() => navigate("/scanning/scans")}
        >
          {txt.get("generic.back_to", txt.get("scanning.scans.page_title"))}
        </EuiButton>,
      ]}
    >
      <EuiFlexItem>
        {isLoading ? (
          <EuiProgress size="xs" color="accent" />
        ) : (
          <MMScanDetail scan={scan} />
        )}
      </EuiFlexItem>
    </MMPage>
  );
}

export default MMScan;
