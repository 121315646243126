import {
  EuiBasicTable,
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiLink,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";
import { Fragment, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "store/local-storage";
import txt from "helpers/text-helper";
import ConnectAPIHelper from "api/connect-api-helper";
import { columnString } from "hoc/helper-hooks";
import { useDebounce } from "use-debounce";
import {
  Client,
  clientAddressLine,
  clientName,
} from "store/data/client/client";
import MMCell from "components/layouts/table/cell";
import ClientsAPIHelper from "api/clients-api.helper";
import AuthenticationHelper from "helpers/authentication-helper";
import { fullName } from "store/data/personal-details/personal-details";
import { MMLink } from "components/layouts/navigation/link";

export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100]; //,0]; //0 would mean without limit
export const DEFAULT_PAGE_SIZE = 10;
export const SEARCH_DEBOUNCE_DELAY = 700;
export const DEFAULT_SORT_BY = "name";
export const DEFAULT_SORT_ORDER = "asc";

function MMClientsList() {
  const navigate = useNavigate();
  const api = new ClientsAPIHelper();

  const [clients, setClients] = useState([]);
  const [columns, setColumns] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [canOpenClientPage, setCanOpenClientPage] = useState<boolean>(false);
  const [limit, setLimit] = useLocalStorage("client_limit", DEFAULT_PAGE_SIZE);
  const [offset, setOffset] = useLocalStorage("client_offset", 0);
  const [total, setTotal] = useState(0);
  const [sortBy, setSortBy] = useLocalStorage(
    "client_sort_by",
    DEFAULT_SORT_BY
  );
  const [sortOrder, setSortOrder] = useLocalStorage(
    "client_sort_order",
    DEFAULT_SORT_ORDER
  );
  const [searchFieldValue, setSearchFieldValue] = useLocalStorage(
    "client_search",
    ""
  );
  const [search] = useDebounce(searchFieldValue, SEARCH_DEBOUNCE_DELAY);
  const [error, setError] = useState("");

  const onSearchChange = (event: any) => {
    setSearchFieldValue(event.target.value);
  };

  const toSelectOptions = (list: any[]) => {
    return list
      .map((item) => ({
        label: item.name,
        "data-id": item.id,
      }))
      .concat([
        {
          label: txt.uf("generic.without_x", txt.get("clients.name")),
          "data-id": "_",
        },
      ]);
  };

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      let columnOptions: string[] = [];
      setCanOpenClientPage(
        await AuthenticationHelper.hasPermission("client_details#edit_all")
      );
      let isClientCommunicationDetailsShown: boolean =
        false &&
        (await AuthenticationHelper.hasPermission([
          "client_communications#read_all",
          "client_communications#edit_all",
        ]));
      columnOptions.push("id");
      columnOptions.push("client_code");
      if (isClientCommunicationDetailsShown) {
        columnOptions.push("full_name");
      } else {
        columnOptions.push("formal_name");
      }
      columnOptions.push("birth_date");

      if (isClientCommunicationDetailsShown) {
        columnOptions.push("phone_number");
        columnOptions.push("email");
        columnOptions.push("address");
      }
      setColumns(columnOptions);
    };

    setInterfaceForPermissions();
  }, []);

  useEffect(() => {
    const loadClients = async () => {
      setIsLoading(true);

      let filters: any = {};
      filters.vlot_practitioner_id =
        AuthenticationHelper.getPersonalDetails()?.legacy_info?.vlot_practitioner_id;
      if (search) {
        filters.search = search;
      }

      const result = await api.getClients(
        filters,
        limit,
        offset,
        userFieldToSortKey(sortBy),
        sortOrder
      );
      if (result.status === "OK") {
        setClients(result.result);
        setTotal(result.meta_data.result_set.total);
        setError("");
      } else {
        setClients([]);
        setTotal(0);
        setError(`${result.status} (${result.code}): ${result.message}`);
      }
      setIsLoading(false);
    };

    loadClients();
  }, [sortOrder, sortBy, limit, offset, search]);

  const resultCountInfo = () =>
    total === 0
      ? txt.uf("generic.found_no_x", txt.get("clients.page_title"))
      : txt.uf(
          "generic.showing_x_of_y_found_z",
          limit === 0
            ? txt.get("generic.all")
            : `${offset + 1}-${Math.min(total, offset + limit)}`,
          total,
          txt.get("clients.page_title")
        ) + ".";

  const limitOffsetToPage = (limit: number, offset: number) => {
    //pages in EUI are zero based
    const page = limit > 0 ? Math.max(0, offset / limit) : 0;
    return page;
  };

  const pagination: any = {
    pageIndex: limitOffsetToPage(limit, offset),
    pageSize: limit,
    totalItemCount: total,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showPerPageOptions: true,
  };

  const sorting: any = {
    sort: {
      field: sortBy,
      direction: sortOrder,
    },
    enableAllColumns: false,
    // readOnly: false,
  };

  const userFieldToSortKey = (field: string) => {
    switch (field) {
      default:
        return field;
    }
  };

  const onClientsChange = ({ page = {} as any, sort = {} as any }) => {
    if (page.size) {
      const newLimit = page.size;
      const newOffset = Math.max(0, page.index * page.size);
      if (limit !== newLimit) setLimit(newLimit);
      if (offset !== newOffset) setOffset(newOffset);
    }
    if (sort.field) {
      setSortBy(sort.field);
      setSortOrder(sort.direction ?? "asc");
    }
  };

  const getColumns = () => {
    console.log("getColumns", columns);
    let columnSettings: any[] = [];
    for (let i = 0; i < columns.length; i++) {
      const column: string = columns[i];
      switch (column) {
        case "id":
          columnSettings.push({
            name: txt.get("generic.id"),
            field: "id",
            sortable: api.clientIsSortableBy(userFieldToSortKey("id")),
          });
          break;
        case "client_code":
          columnSettings.push({
            name: txt.get("clients.client_code"),
            field: "client_code",
            sortable: api.clientIsSortableBy(userFieldToSortKey("client_code")),
            render: columnString,
          });
          break;
        case "birth_date":
          columnSettings.push({
            name: txt.get("clients.birth_date"),
            field: "birth_date",
            sortable: api.clientIsSortableBy(userFieldToSortKey("birth_date")),
            render: columnString,
          });
          break;
        case "full_name":
        case "formal_name":
          columnSettings.push({
            name: txt.get("clients.name"),
            field: "first_name",
            sortable: api.clientIsSortableBy(userFieldToSortKey("first_name")),
            render: (first_name: string, client: Client) =>
              canOpenClientPage ? (
                <MMLink href={`/clients/${client.id}`}>
                  {clientName(client, true)}
                </MMLink>
              ) : (
                clientName(client, true)
              ),
          });
          break;
        case "phone_number":
          columnSettings.push({
            name: txt.get("clients.phone_number"),
            field: "phone_number",
            sortable: api.clientIsSortableBy(
              userFieldToSortKey("phone_number")
            ),
            render: columnString,
          });
          break;
        case "email":
          columnSettings.push({
            name: txt.get("clients.email"),
            field: "email",
            sortable: api.clientIsSortableBy(userFieldToSortKey("email")),
            render: columnString,
          });
          break;
        case "address":
          columnSettings.push({
            name: txt.get("clients.address"),
            field: "postal_code",
            sortable: api.clientIsSortableBy(userFieldToSortKey("postal_code")),
            render: (postal_code: string, client: Client) => (
              <MMCell
                text={clientAddressLine(client)}
                subText={`${postal_code || ""} ${client.city || ""}`.trim()}
              />
            ),
          });
          break;
      }
    }

    return columnSettings;
  };

  const getRowProps = (clients: any) => {
    const { id } = clients;
    return {
      "data-id": `row-${id}`,
      onClick: canOpenClientPage
        ? (e: any) => {
            if (
              e.target.tagName !== "BUTTON" &&
              e.target.tagName !== "INPUT" &&
              e.target.tagName !== "A"
            ) {
              navigate(`/clients/${id}`);
            }
          }
        : undefined,
    };
  };

  return (
    <Fragment>
      <EuiFlexGroup alignItems="flexStart">
        <EuiFlexItem grow={false}>
          <EuiFieldSearch
            compressed={true}
            placeholder={txt.get("generic.search")}
            value={searchFieldValue}
            isLoading={isLoading}
            isClearable={!isLoading}
            contentEditable={!isLoading}
            onChange={(event: any) => onSearchChange(event)}
            aria-label={txt.get("generic.search")}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup alignItems="flexEnd">
        <EuiFlexItem>
          <EuiText textAlign="right" size="xs">
            {resultCountInfo()}
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="s" />
      <EuiHorizontalRule margin="none" style={{ height: 1 }} />
      <EuiBasicTable
        loading={isLoading}
        tableLayout="auto"
        itemId="id"
        items={clients}
        columns={getColumns()}
        pagination={pagination}
        sorting={sorting}
        rowProps={getRowProps}
        noItemsMessage={
          error
            ? error
            : txt.uf("generic.found_no_x", txt.get("clients.page_title"))
        }
        onChange={onClientsChange}
      />
    </Fragment>
  );
}

export default MMClientsList;
