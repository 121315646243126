import {
  View,
  Document,
  PDFViewer,
  Image,
  Text,
  Page,
} from "@react-pdf/renderer";
import { useEffect, useState } from "react";

export interface MMPdfPreviewProps {
  file: any;
  width?: number;
  height?: number;
}

function MMPdfImagePreview(props: MMPdfPreviewProps) {
  const [file, setFile] = useState<any>(props.file);

  useEffect(() => {
    console.log("MMPdfImagePreview", props.width, props.height, props.file);
    setFile(props.file);
  }, [props.file]);

  return file ? (
    <PDFViewer height={"100%"}>
      <Document>
        <Page
          size={
            props.width && props.height
              ? [props.width, props.height]
              : undefined
          }
        >
          <Image src={file} />
        </Page>
      </Document>
    </PDFViewer>
  ) : (
    <></>
  );
}

export default MMPdfImagePreview;
