import { EuiBadge, EuiLink, EuiLoadingSpinner, EuiText } from "@elastic/eui";
import { useState, useEffect, Fragment } from "react";
import txt from "helpers/text-helper";
import ManoXAPIHelper from "api/manox-api-helper";
import {
  handDescription,
  ScanSummary,
  THUMBNAILS_PREFERRED,
} from "store/data/scan/scan";
import MMSecureImage from "components/layouts/image/secure-image";
import StringHelper from "helpers/string-helper";
import DateHelper from "helpers/date-helper";
import {
  processingRunQualityDescription,
  processingRunQualityToHealth,
} from "store/data/scan/processing-run";
import { Order } from "store/data/order/order";
import AuthenticationHelper from "helpers/authentication-helper";

export interface MMOrderScanProps {
  order: Order;
  scan: ScanSummary;
  isScan3DVisible?: boolean;
  isLoading: boolean;
}

function MMOrderScan(props: MMOrderScanProps) {
  // console.log('MMOrderScansProps',props);
  const api = new ManoXAPIHelper();

  const [scanImages, setScanImages] = useState<any[]>([]);
  const [order] = useState(props.order);

  const loadScanImages = async () => {
    const result = await api.getScanAssets(
      props.scan.scan_id.toString(),
      "thumbnail"
    );

    if (result.result) {
      let imagesToShow: any[] = [];
      result.result.forEach((image: any) => {
        if (THUMBNAILS_PREFERRED.includes(image.ref)) {
          imagesToShow.push(image);
        }
      });
      setScanImages(imagesToShow);
    } else {
      setScanImages([]);
    }
  };

  useEffect(() => {
    loadScanImages();
  }, [props.scan]);

  const toQuality = (scan: ScanSummary) => {
    let result = <></>;

    if (scan.processing) {
      // if (scan.processing.status == ProcessingRunStatus.Failed) {

      // } else {
      result = (
        <Fragment>
          {props.isLoading ? (
            <EuiLoadingSpinner style={{ position: "relative", top: "4px" }} />
          ) : (
            <EuiBadge
              color={processingRunQualityToHealth(scan.processing.quality)}
            >
              {processingRunQualityDescription(scan.processing.quality)}
            </EuiBadge>
          )}
        </Fragment>
      );
      // }
    }

    return result;
  };

  return (
    <Fragment>
      {scanImages.length > 0 ? (
        <div
          style={{
            display: "block",
            position: "relative",
            opacity:
              order && props.scan.order_number === order.id.toString()
                ? 1.0
                : 0.5,
          }}
        >
          <EuiText size="xs">
            {AuthenticationHelper.isInternalUser() ? (
              <EuiLink href={`/scanning/scans/${props.scan.scan_id}`}>
                {order && props.scan.order_number === order.id.toString()
                  ? `${DateHelper.toDate(
                      props.scan.captured_at
                    )} ${handDescription(props.scan.client_hand)}`
                  : `${txt.get("scanning.scans.old_scan")}: ${DateHelper.toDate(
                      props.scan.captured_at
                    )} ${handDescription(props.scan.client_hand)}`}
              </EuiLink>
            ) : order && props.scan.order_number === order.id.toString() ? (
              `${DateHelper.toDate(props.scan.captured_at)} ${handDescription(
                props.scan.client_hand
              )}`
            ) : (
              `${txt.get("scanning.scans.old_scan")}: ${DateHelper.toDate(
                props.scan.captured_at
              )} ${handDescription(props.scan.client_hand)}`
            )}
            <br />
            {!props.scan.processable &&
            AuthenticationHelper.isInternalUser() ? (
              <EuiBadge color="warning">
                {txt.get("scanning.scans.scan.processing_waiting_result")}
              </EuiBadge>
            ) : (
              ""
            )}
          </EuiText>
          <div
            style={{
              position: "absolute",
              right: 0,
              top: "-9px",
              clear: "both",
            }}
          >
            {toQuality(props.scan)}
          </div>
          <div className="images-holder">
            {" "}
            {scanImages.map((image: any, i: number) => (
              <div
                key={`image-${i}-${image.ref}`}
                className="secure-image-holder"
              >
                <MMSecureImage
                  caption={StringHelper.zeroPad(image.ref, 2)}
                  alt={`Image ${image.ref} of Scan Id ${props.scan.scan_id}`}
                  url={image.url}
                />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <EuiText size="xs">
          <EuiLink href={`/scanning/scans/${props.scan.scan_id}`}>
            {txt.get(
              "scanning.scans.scan.no_previews_yet",
              props.scan.scan_id || ""
            )}
          </EuiLink>
        </EuiText>
      )}
    </Fragment>
  );
}

export default MMOrderScan;
