import {
  EuiComboBox,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  useIsWithinBreakpoints,
} from "@elastic/eui";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import {
  MMOrderLineFieldProps,
  MMOrderLineProductInput,
} from "../order-line-entry";
import { Order } from "store/data/order/order";
import AuthenticationHelper from "helpers/authentication-helper";

const toSelectedWorkflows = (selection: any) => {
  return selection && selection.workflowOverride
    ? [
        {
          label: selection.workflowOverride,
          value: selection.workflowOverride,
        },
      ]
    : [];
  //   return selection && selection.workflowOverrid
  //     ? selection.communicationReasons
  //         .split(",")
  //         .map((reason: string) => ({ label: reason }))
  //     : [];
};

function MMOrderWorkflowOverride(props: MMOrderLineFieldProps) {
  const isMobile: boolean = useIsWithinBreakpoints(["xs", "s"]);
  const [order] = useState<Order | null>(props.order || null);

  const [workflows] = useState<any[]>(props.workflows || []);
  const [workflowOptions, setWorkflowOptions] = useState<any[]>([]);

  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isLoading] = useState<boolean>(false);
  const [selectedWorkflows, setSelectedWorkflows] = useState<any[]>(
    toSelectedWorkflows(props.selection)
  );

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      setIsVisible(
        await AuthenticationHelper.hasPermission(["orders#comment"])
      );
    };

    setInterfaceForPermissions();
  }, []);
  useEffect(() => {
    setSelectedWorkflows(toSelectedWorkflows(props.selection));
  }, [props.selection]);

  const onWorkflowsChange = (workflows: any) => {
    if (props.onChange) {
      console.log("onWorkflowsChange", workflows);
      props.onChange({
        ...(props.selection || {}),
        workflowOverride:
          workflows && workflows.length > 0 ? workflows[0].value : "",
      } as MMOrderLineProductInput);
    }
  };

  useEffect(() => {
    const result = workflows.map((workflow: any) => ({
      value: workflow,
      label: workflow,
    }));
    setWorkflowOptions(result);
  }, [workflows]);

  return (
    <EuiFlexGroup gutterSize="xs">
      <EuiFlexItem>
        <EuiFormRow
          className="product-input"
          display="rowCompressed"
          label={props.showTitle ? txt.get("admin.workflows.name") : ""}
          style={!isVisible ? { display: "none" } : {}}
          helpText={`Change worklow from '${props.selection?.variation?.workflow}'`}
        >
          <EuiComboBox
            className={selectedWorkflows.length > 0 ? `option-selected` : ""}
            style={isMobile ? {} : {}}
            color="accent"
            isLoading={isLoading}
            singleSelection={{ asPlainText: true }}
            compressed={true}
            placeholder={"Change " + txt.get("admin.workflows.name")}
            options={workflowOptions}
            selectedOptions={selectedWorkflows}
            onChange={(selected) => onWorkflowsChange(selected)}
          />
        </EuiFormRow>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
}

export default MMOrderWorkflowOverride;
