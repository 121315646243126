import {
  EuiBadge,
  EuiBadgeGroup,
  EuiBasicTable,
  EuiButton,
  EuiCodeBlock,
  EuiComment,
  EuiCommentList,
  EuiDescriptionList,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiHorizontalRule,
  EuiIcon,
  EuiLoadingElastic,
  EuiProgress,
  EuiSpacer,
  EuiSwitch,
  EuiText,
  useIsWithinBreakpoints,
} from "@elastic/eui";
import { Fragment, useEffect, useState } from "react";

import ManoXAPIHelper from "api/manox-api-helper";
import {
  handDescription,
  Scan,
  ScanStatus,
  scanStatusDescription,
  scanToHealth,
  THUMBNAILS_PREFERRED,
  THUMBNAILS_TOTAL,
} from "store/data/scan/scan";
import DateHelper from "helpers/date-helper";
import MetaInfoHelper from "helpers/meta-info-helper";
import StringHelper from "helpers/string-helper";
import MMSecureImage from "components/layouts/image/secure-image";
import {
  ProcessingRunQuality,
  processingRunQualityCompare,
  processingRunQualityDescription,
  processingRunQualityToHealth,
  ProcessingRunStatus,
} from "store/data/scan/processing-run";
import MMScan3D from "./scan-3d";
import AuthenticationHelper from "helpers/authentication-helper";
import txt from "helpers/text-helper";
import FileUploadHelper from "helpers/file-upload-helper";
import { Order } from "store/data/order/order";
import ConnectAPIHelper from "api/connect-api-helper";
import { viewName } from "store/data/location/location";
import { fullName } from "store/data/personal-details/personal-details";
import { ApiResponse, ApiResponseStatus } from "api/api-helper";

interface MMScanDetailProps {
  scan: Scan;
}

function MMScanDetail(props: MMScanDetailProps) {
  const { scan } = props;
  const scanId: any = scan.scan_id;

  const api: ManoXAPIHelper = new ManoXAPIHelper();
  const connectApi: ConnectAPIHelper = new ConnectAPIHelper();

  const [scanEvents, setScanEvents] = useState([]);
  const [scanImages, setScanImages] = useState<any>([]);
  const [scan3D, setScan3D] = useState(null);
  const [error, setError] = useState("");
  const [order, setOrder] = useState<Order>();
  const [isLoading, setIsLoading] = useState(true);
  const [showAllImages, setShowAllImages] = useState(false);
  const [processingRun, setProcessingRun] = useState(null);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [showProcessingRunDetails, setShowProcessingRunDetails] =
    useState<boolean>(false);
  const isSmallScreen = useIsWithinBreakpoints(["m", "xs", "s"]);
  const isBigScreen = useIsWithinBreakpoints(["xl"]);
  const isMobileScreen = useIsWithinBreakpoints(["xs"]);

  useEffect(() => {
    MetaInfoHelper.setTitle(`Scan ${scan.name}`);
  }, [scan]);

  useEffect(() => {
    const setInterfaceForPermissions = async () => {
      setShowProcessingRunDetails(
        await AuthenticationHelper.hasPermission("processing#read_all")
        // && AuthenticationHelper.isInternalUser()
      );
    };
    if (!scanId) {
      setError("no scan ID given");
    } else {
      const loadScanDetails = async () => {
        const eventResult = await api.getScanEvents(scanId);
        setScanEvents(eventResult.result);

        const assetsResult = await api.getScanAssets(scanId, "thumbnail");
        setScanImages(assetsResult.result);
        setIsLoading(false);
      };

      loadScanDetails();

      if (scan.order_number && !isNaN(parseInt(scan.order_number))) {
        const loadOrder = async (orderId: number) => {
          const result: ApiResponse = await connectApi.getOrders(
            {
              ids: [orderId],
            },
            1,
            0
          );
          if (
            result.status === ApiResponseStatus.OK &&
            result.result &&
            result.result.length > 0
          ) {
            setOrder(result.result[0]);
          } else {
            setOrder(undefined);
          }
        };
        loadOrder(parseInt(scan.order_number));
      }
    }
    setInterfaceForPermissions();
  }, []);

  useEffect(() => {
    if (scanId) {
      const loadScan3D = async () => {
        const runId: string = getBestProcessingRunId();
        if (runId) {
          const result = await api.getProcessingRunAssets(scanId, runId);
          setScan3D(result.result);
        } else {
          console.log("no eligible run id found to get scan for");
        }
      };

      loadScan3D();
    }
  }, [scan]);

  const showProcessingRun = async (processingRunId: number) => {
    const canRetrieveProcessingRuns: boolean =
      await AuthenticationHelper.hasPermission("processing#read_all");
    if (!canRetrieveProcessingRuns) return;

    const processingRun = await api.getProcessingRun(
      scan.scan_id.toString(),
      processingRunId.toString()
    );
    setProcessingRun(processingRun);
  };

  const getBestProcessingRun = (): any => {
    let result: any = null;
    if (scan && scan.processing) {
      const runs: any = scan.processing;
      let bestRun: any = null;
      for (let i = 0; i < runs.length; i++) {
        const run = runs[i];
        if (run.status == ProcessingRunStatus.Finished) {
          if (
            !bestRun ||
            processingRunQualityCompare(run.status, bestRun.status) >= 0
          ) {
            bestRun = run;
          }
        }
      }

      if (bestRun) {
        result = bestRun;
      } else {
        console.log("no best run");
      }
    }
    return result;
  };

  const getBestProcessingRunQuality = (): string => {
    let run = getBestProcessingRun();
    return run ? run.quality : "";
  };

  const getBestProcessingRunId = (): string => {
    let run = getBestProcessingRun();
    return run ? run.id : "";
  };

  const scanAssetsToProps = (scan3DAssets: any) => {
    let model: string = "";
    let material: string = "";
    let texture: string = "";
    scan3DAssets.forEach((asset: any) => {
      if (asset.type === "model") {
        model = asset.url;
      } else if (asset.type === "material") {
        material = asset.url;
      } else if (asset.type === "texture") {
        texture = asset.url;
      }
    });
    return { model, material, texture };
  };

  const scanAssetsToDownloadUrl = (scan3DAssets: any) => {
    let archive: string = "";
    scan3DAssets.forEach((asset: any) => {
      // if (asset.type === "texture") {
      //   archive = asset.url;
      // }
      if (asset.type === "archive") {
        archive = asset.url;
      }
    });
    return archive;
  };

  const toHealthColor = (scanEventType: string) => {
    return scanEventType.includes("failed")
      ? "danger"
      : scanEventType.includes("pending") || scanEventType.includes("queued")
        ? "warning"
        : scanEventType.includes("finished")
          ? "success"
          : "plain";
  };

  const toScanEventDescription = (scanEventType: any) => {
    let result = scanEventType
      .split(".")
      .reverse()
      .join(" ")
      .replace("manox", "")
      .trim();
    return result;
  };

  const renderScanEvents = () => {
    return (
      <EuiCommentList gutterSize="m">
        {scanEvents.map((scanEvent: any) => (
          <EuiComment
            key={`k${scanEvent.id}`}
            username={scanEvent.scanner.name}
            timelineAvatarAriaLabel={scanEvent.scanner.name}
            timelineAvatar="dot"
            event={toScanEventDescription(scanEvent.type)}
            timestamp={`at ${DateHelper.toDateTime(scanEvent.created_at)}`}
            eventColor={toHealthColor(scanEvent.type)}
          />
        ))}
      </EuiCommentList>
    );
  };

  const renderProcessingRunDetails = () => {
    return processingRun ? (
      <EuiFlyout
        className="processing-run"
        onClose={() => {
          setProcessingRun(null);
        }}
      >
        <EuiCodeBlock language="json">
          {JSON.stringify(processingRun, null, 2)}
        </EuiCodeBlock>
      </EuiFlyout>
    ) : (
      <></>
    );
  };

  const renderProcessingRuns = () => {
    const processingColumns = [
      { name: "Id", field: "id", width: "50" },
      {
        name: "Result/Status",
        render: (run: any) => {
          return (
            <EuiBadgeGroup>
              <EuiBadge>
                {StringHelper.ucfirst(run.status ?? "") || "N/A"}
              </EuiBadge>
              <EuiBadge>
                {StringHelper.ucfirst(run.result ?? "") || "N/A"}
              </EuiBadge>
            </EuiBadgeGroup>
          );
        },
      },
      {
        name: "Start/End",
        render: (run: any) => {
          return (
            <Fragment>
              <EuiText size="xs">
                <p
                  style={{
                    padding: 0,
                    margin: 0,
                    borderBottomWidth: "1px",
                    borderBottom: "solid",
                    borderBottomColor: "#ddd",
                  }}
                >
                  {run.created_at ? DateHelper.tight(run.created_at) : "N/A"}
                </p>
                <p style={{ padding: 0, margin: 0 }}>
                  {run.end ? DateHelper.tight(run.end) : "N/A"}
                </p>
              </EuiText>
            </Fragment>
          );
        },
      },
    ] as any;
    const runs: any = scan.processing;

    return (
      <EuiBasicTable
        style={{ maxWidth: "380px" }}
        itemId="id"
        items={runs}
        columns={processingColumns}
        rowProps={(processingRun: any) => {
          const { id } = processingRun;
          if (showProcessingRunDetails) {
            return {
              "data-id": `row-${id}`,
              onClick: (e: any) => {
                showProcessingRun(id);
              },
            };
          }
          return {
            "data-id": `row-${id}`,
          };
        }}
        noItemsMessage="Not processing"
      />
    );
  };

  const renderScanImages = () => {
    if (scanImages !== undefined) {
      let imagesReffed: any = {};
      for (let i = 0; i < scanImages.length; i++) {
        imagesReffed[scanImages[i].ref] = scanImages[i];
      }
      let images: any = [];
      let imageSlots: number[] = showAllImages
        ? Array.from(Array(THUMBNAILS_TOTAL))
        : THUMBNAILS_PREFERRED;

      images = imageSlots.map((value, index) =>
        imagesReffed[value ?? index + 1]
          ? imagesReffed[value ?? index + 1]
          : undefined
      );

      return (
        <EuiFlexGrid
          direction={showAllImages ? "column" : "row"}
          responsive={false}
          columns={isSmallScreen ? 2 : 4}
        >
          {images.map((image: any, i: number) => (
            <EuiFlexItem
              className="secure-image-holder"
              key={`k${i}`}
              style={{
                alignItems: "center",
                justifyContent: "center",
                minWidth: isMobileScreen ? "auto" : 160,
                minHeight: isMobileScreen ? "auto" : 120,
              }}
            >
              {image && image.url ? (
                <div className="scan-position-overlay">
                  <MMSecureImage
                    caption={StringHelper.zeroPad(image.ref, 2)}
                    alt={`Image ${image.ref} of Scan Id ${scan.scan_id}`}
                    url={image.url}
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 5,
                    justifyContent: "center",
                    height: 120,
                    minWidth: 160,
                    minHeight: 120,
                    backgroundColor: "#ffefef",
                  }}
                >
                  <EuiIcon size="m" color="#999" type="image" />
                  <EuiText color="#999" size="xs" className="na-text">
                    N/A
                  </EuiText>
                </div>
              )}
            </EuiFlexItem>
          ))}
        </EuiFlexGrid>
      );
    } else {
      return <EuiText>No Images</EuiText>;
    }
  };

  const triggerDownloadScan = async (url: string) => {
    setIsDownloading(true);
    const image = await api.getAssetByUrl(url, false);
    const fileAsUrl: string = URL.createObjectURL(image);

    FileUploadHelper.triggerDownloadFile(fileAsUrl, `${scan.name}.zip`);
    setIsDownloading(false);
  };

  const renderDownloadScan = (scan3DAssets: any) => {
    let downloadUrl: string = scan3DAssets
      ? scanAssetsToDownloadUrl(scan3DAssets)
      : "";
    return (
      <EuiButton
        isLoading={isDownloading}
        color="accent"
        aria-label={txt.get("scanning.scans.scan.download_scan")}
        iconType="download"
        size="s"
        isDisabled={!downloadUrl}
        onClick={(a: any) => triggerDownloadScan(downloadUrl)}
      >
        {txt.get("scanning.scans.scan.download_scan")}
      </EuiButton>
    );
  };
  const toLocationInfo = (order?: Order) => {
    if (!order) return "-";

    let result: string = "";
    if (order.organisation && order.location) {
      result = `${order.organisation.name}
${viewName(order.location)}`;
    } else if (order.organisation) {
      result = order.organisation.name;
    } else if (order.location) {
      result = viewName(order.location);
    }
    return result;
  };
  return (
    <Fragment>
      <EuiFlexGroup>
        <Fragment>{renderProcessingRunDetails()}</Fragment>
        <EuiFlexItem>
          <Fragment>
            <EuiText size="m">
              <h2>
                <span>{txt.get("scanning.scans.name")}</span>
                <sup
                  style={{
                    fontWeight: "normal",
                    fontSize: "0.5em",
                    verticalAlign: "top",
                  }}
                ></sup>
              </h2>
            </EuiText>
            <EuiSpacer size="m" />
            {isLoading ? (
              <EuiLoadingElastic />
            ) : (
              <EuiDescriptionList
                compressed={true}
                type={isBigScreen || isMobileScreen ? "column" : "row"}
                // textStyle="reverse"
                listItems={[
                  {
                    title: txt.get("generic.status"),
                    description: (
                      <EuiBadge color={scanToHealth(scan.status)}>
                        {scanStatusDescription(scan.status)}
                      </EuiBadge>
                    ),
                  },
                  {
                    title: txt.get("scanning.scans.scan.quality"),
                    description: (
                      <EuiBadge
                        color={processingRunQualityToHealth(
                          getBestProcessingRunQuality() as ProcessingRunQuality
                        )}
                      >
                        {processingRunQualityDescription(
                          getBestProcessingRunQuality() as ProcessingRunQuality
                        )}
                      </EuiBadge>
                    ),
                  },
                  {
                    title: txt.get("scanning.scans.scan.captured_at"),
                    description: scan.captured_at
                      ? DateHelper.toDateTime(scan.captured_at)
                      : "-",
                  },
                  {
                    title: txt.get("orders.order.hand"),
                    description: scan.client_hand
                      ? handDescription(scan.client_hand)
                      : "-",
                  },
                  {
                    title: txt.get("orders.order.client_code"),
                    description: scan.client_number ? scan.client_number : "-",
                  },
                  {
                    title: txt.get("orders.order.id"),
                    description: scan.order_number ? scan.order_number : "-",
                  },
                  {
                    title: txt.get("scanning.scans.scan.captured_at"),
                    description: scan.captured_at
                      ? DateHelper.toDateTime(scan.captured_at)
                      : "-",
                  },
                  {
                    title: txt.get("scanning.scans.scan.images"),
                    description: `${scan.images_actual ?? "?"} out of ${
                      scan.images_expected
                    }`,
                  },
                  {
                    title: txt.get("scanning.scans.scan.name"),
                    description: scan.scanner?.name ?? "-",
                  },
                  {
                    title: txt.get("scanning.scanners.location"),
                    description: toLocationInfo(order),
                  },
                  {
                    title: txt.get("scanning.scanners.practitioner"),
                    description:
                      order && order.personal_details
                        ? fullName(order.personal_details)
                        : "-",
                  },
                  {
                    title: txt.get("scanning.scans.scan.description"),
                    description: scan.scanner?.description ?? "-",
                  },
                ]}
                style={{ maxWidth: "400px" }}
              />
            )}
          </Fragment>
          <EuiSpacer size="xl" />
          <Fragment>
            <EuiText size="m">
              <h2>{txt.get("scanning.scans.scan.processing_results")}</h2>
            </EuiText>
            <EuiSpacer size="m" />
            {scan.processable ? (
              scan.processing ? (
                renderProcessingRuns()
              ) : (
                <EuiText>
                  {txt.get("scanning.scans.scan.nothing_to_process")}
                </EuiText>
              )
            ) : (
              <EuiText>
                {txt.get("scanning.scans.scan.not_processable")}
              </EuiText>
            )}
          </Fragment>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText size="m">
            <h2>{`${txt.get("scanning.scans.scan.threed")} & ${txt.get(
              "scanning.scans.scan.images"
            )}`}</h2>
            {scan3D &&
            scan.processable &&
            scan.processing &&
            scan.processing.length > 0 ? (
              <Fragment>
                <MMScan3D {...scanAssetsToProps(scan3D)} />
              </Fragment>
            ) : (
              <Fragment>
                <EuiSpacer size="s" />
                <EuiHorizontalRule size="half" />
                {scan.processable &&
                scan.processing &&
                scan.processing.length > 0 ? (
                  <EuiText textAlign="center" size="s">
                    {txt.get("scanning.scans.scan.processing_waiting_quality")}
                  </EuiText>
                ) : (
                  <EuiText textAlign="center" size="s">
                    {txt.get("scanning.scans.scan.processing_waiting_result")}
                  </EuiText>
                )}
                <EuiHorizontalRule size="half" />
              </Fragment>
            )}
            <EuiSpacer size="xl" />
            {isLoading ? (
              <EuiProgress size="xs" color="accent" />
            ) : scan.images_actual ? (
              <EuiFlexGrid>
                <EuiFlexItem>
                  <EuiFlexGroup
                    justifyContent="spaceBetween"
                    alignItems="center"
                  >
                    <EuiFlexItem grow={false}>
                      {scan.processable &&
                      scan.processing &&
                      scan.processing.length > 0 ? (
                        renderDownloadScan(scan3D)
                      ) : (
                        <></>
                      )}
                    </EuiFlexItem>
                    <EuiFlexItem
                      style={{ display: "flex", alignItems: "flex-end" }}
                      grow={false}
                    >
                      <EuiSwitch
                        compressed={true}
                        checked={showAllImages}
                        onChange={() => setShowAllImages(!showAllImages)}
                        label={txt.get("generic.show_all")}
                      />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
                {renderScanImages()}
              </EuiFlexGrid>
            ) : (
              <Fragment>
                <EuiSpacer size="s" />
                <EuiHorizontalRule size="half" />
                <EuiText textAlign="center" size="s">
                  {txt.get("scanning.scans.scan.no_images")}
                </EuiText>
                <EuiHorizontalRule size="half" />
              </Fragment>
            )}
          </EuiText>
          <EuiSpacer size="m" />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText size="m">
            <h2>{txt.get("scanning.scans.events")}</h2>
          </EuiText>
          <EuiSpacer size="m" />
          {!scanEvents ? (
            <EuiProgress size="xs" color="accent" />
          ) : (
            renderScanEvents()
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiHorizontalRule margin="xl" style={{ height: 1 }} />
    </Fragment>
  );
}

export default MMScanDetail;
