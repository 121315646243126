import {
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText,
  EuiTitle,
  useIsWithinBreakpoints,
} from "@elastic/eui";
import MMProductCard from "./product-card";
import { snakeCase } from "lodash";
import { Fragment, useEffect, useState } from "react";
import APIHelper from "api/api-helper";

export type OnProductSelect = (product: any, selected: boolean) => void;

export interface MMProductCardsProps {
  products: any[];
  selectable?: boolean;
  onSelect?: OnProductSelect;
  api?: APIHelper;
  columns?: 1 | 2 | 3 | 4;
}

function MMProductCards(props: MMProductCardsProps) {
  const isMobileScreen = useIsWithinBreakpoints(["xs"]);
  const isSmallScreen = useIsWithinBreakpoints(["s", "m", "l"]);
  const isMediumScreen = useIsWithinBreakpoints(["xl"]);

  const [columns, setColumns] = useState<1 | 2 | 3 | 4>(4);

  useEffect(() => {
    if (props.columns) {
      setColumns(props.columns);
    } else {
      setColumns(
        isMobileScreen ? 1 : isSmallScreen ? 2 : isMediumScreen ? 3 : 4
      );
    }
  }, [props.columns]);
  return (
    <EuiFlexGroup
      direction="column"
      style={{
        maxWidth: "1000px",
        marginBottom: "25px",
        marginRight: "25px",
      }}
    >
      {props.products.map((category: any, i: number) => (
        <EuiFlexItem key={`c-${snakeCase(category.data_category)}`}>
          {i > 0 ? <EuiSpacer /> : <></>}
          <EuiTitle size="xs">
            <EuiText color="grey">{category.label}</EuiText>
          </EuiTitle>
          <EuiSpacer />
          <EuiFlexGrid gutterSize="xl" columns={columns}>
            {category.options.map((product: any, j: number) => (
              <EuiFlexItem
                key={`c-${snakeCase(category.data_category)}-${i}-p-${snakeCase(
                  product.label
                )}-${j}`}
              >
                <MMProductCard
                  product={product}
                  api={props.api}
                  onSelect={props.onSelect}
                />
              </EuiFlexItem>
            ))}
          </EuiFlexGrid>
        </EuiFlexItem>
      ))}
    </EuiFlexGroup>
  );
}

export default MMProductCards;
