import { File } from "../file/file";

export const FITTING_EMPTY: Fitting = {
  id: null,
  order_line_id: 0,
  fitting_scores: [],
  instructed: true,
};

export interface Fitting {
  id: number | null;
  order_line_id: number;
  is_fitting?: boolean;
  reason_unfit?: string;
  notes?: string;
  instructed: boolean;
  files?: File[];
  fitting_scores: FittingScore[];
}

export interface FittingScore {
  id?: number;
  fitting_id?: number;
  attribute: string;
  score: number;
}
