import {
  EuiBasicTable,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiModal,
} from "@elastic/eui";
import { Fragment, useEffect, useState } from "react";
import txt from "helpers/text-helper";
import ConnectAPIHelper from "api/connect-api-helper";
import { useDispatch, useSelector } from "react-redux";
import MMSecureImage from "components/layouts/image/secure-image";
import { File } from "store/data/file/file";
import {
  AlertConfirmActionState,
  confirmAsk,
  confirmGet,
} from "store/components/alert/confirm";
import { ApiResponse } from "api/api-helper";
import FileUploadHelper from "helpers/file-upload-helper";
import MMPdfPreview from "components/orders/pdf/pdf-preview";
import DateHelper from "helpers/date-helper";

interface MMDeleteFile {
  (fileId: number): void;
}

export interface MMFilesProps {
  files: File[];
  onDelete?: MMDeleteFile;
  detailedPreview?: boolean;
  previewAll?: boolean;
  downloadAll?: boolean;
  tinyView?: boolean;
}

function MMFiles(props: MMFilesProps) {
  const api = new ConnectAPIHelper();
  const dispatch = useDispatch();
  const [files, setFiles] = useState<File[]>([]);
  const [previewAllFile, setPreviewAllFile] = useState<any>();

  useEffect(() => {
    setFiles(props.files);
  }, [props.files]);
  const [isLoading, setIsLoading] = useState(false);

  const attachmentName = (fileId: string) => {
    console.log("attachmentName", fileId, files);
    const result: any = files.find((file: any) => file.id === fileId);
    return result.name;
  };

  const handleDeleteConfirm = async (fileId: any) => {
    dispatch(
      confirmAsk(
        `${txt.uf("generic.delete_x", txt.get("orders.order.attachment"))}.`,
        txt.get(
          "orders.order.attachments.delete_confirm",
          attachmentName(fileId)
        ),
        "delete_attachment",
        { fileId }
      )
    );
  };

  const alertConfirm = useSelector(confirmGet);

  useEffect(() => {
    if (
      alertConfirm.actionState === AlertConfirmActionState.Perform &&
      alertConfirm.actionKey === "delete_attachment"
    ) {
      props.onDelete
        ? props.onDelete(parseInt(alertConfirm.actionData.fileId))
        : console.log("no onDelete in confirm");
    }
  }, [alertConfirm]);

  const handleDownloadAll = async () => {
    setIsLoading(true);
    let fileIds: number[] = files.map((file: File) => file.id);
    const mergedPdf: Blob | string = await api.getMergedFiles(fileIds);
    if (mergedPdf instanceof Blob) {
      const fileAsUrl: string = URL.createObjectURL(mergedPdf);
      FileUploadHelper.triggerDownloadFile(
        fileAsUrl,
        `merged-${DateHelper.toSortable()}`
      );
    }
    setIsLoading(false);
  };

  const handlePreviewAll = async () => {
    setIsLoading(true);
    let fileIds: number[] = files.map((file: File) => file.id);
    const mergedPdf: Blob = await api.getMergedFiles(fileIds);
    const fileAsUrl: string = URL.createObjectURL(mergedPdf);

    setPreviewAllFile(fileAsUrl);
    setIsLoading(false);
  };

  const renderPreviewAll = () =>
    previewAllFile ? (
      <EuiModal
        style={{
          backgroundColor: "#fffffff",
          maxWidth: "90vw",
          maxHeight: "80vh",
          width: "90vw",
          height: "90vh",
        }}
        onClose={(e: any) => setPreviewAllFile(undefined)}
        initialFocus="[name=popswitch]"
        className="image-preview"
      >
        <MMPdfPreview file={previewAllFile} />
      </EuiModal>
    ) : (
      <></>
    );

  const columns = (fileTitle?: string) => {
    const result: any[] = [
      {
        name: fileTitle ?? "",
        truncateText: true,
        width: "100%",
        render: (file: File) => (
          <MMSecureImage
            filename={file.name}
            openInNewWindow={
              !["image/png", "image/jpeg"].includes(file.mime_type)
            }
            mimeType={file.mime_type}
            thumbnailUrl={file.thumbnail_url}
            url={file.url}
            api={api}
            detailedPreview={props.detailedPreview}
          />
        ),
      },
    ];
    if (props.onDelete) {
      result.push({
        field: "",
        name: "",
        width: "40px",
        actions: [
          {
            name: txt.get("generic.delete"),
            description: "",
            type: "icon",
            icon: "cross",
            onClick: (file: any) => {
              handleDeleteConfirm(file.id);
            },
          },
        ],
      });
    }
    return result;
  };

  return props.tinyView ? (
    <EuiFlexGroup
      gutterSize="none"
      justifyContent="center"
      alignItems="center"
      style={{ backgroundColor: "#f0f0f099", width: "44px", height: "44px" }}
      onClick={
        props.previewAll
          ? (e: any) => {
              handlePreviewAll();
            }
          : undefined
      }
    >
      {files && files.length > 0 ? (
        <MMSecureImage
          filename={files[0].name}
          openInNewWindow={
            !["image/png", "image/jpeg"].includes(files[0].mime_type)
          }
          mimeType={files[0].mime_type}
          thumbnailUrl={files[0].thumbnail_url}
          url={files[0].url}
          api={api}
          detailedPreview={!props.previewAll && props.detailedPreview}
        />
      ) : (
        <></>
      )}
    </EuiFlexGroup>
  ) : (
    <Fragment>
      <EuiBasicTable
        itemId="id"
        className="table-no-header"
        items={files}
        columns={columns()}
        noItemsMessage={txt.uf(
          "generic.found_no_x",
          txt.get("orders.order.attachments.name")
        )}
      />
      <EuiFlexGroup
        justifyContent="flexEnd"
        gutterSize="xs"
        style={{
          // display: "inline-block",
          maxWidth: "100%",
        }}
      >
        {props.downloadAll ? (
          <EuiButtonIcon
            onClick={(e: any) => {
              handleDownloadAll();
            }}
            iconType="download"
            size="s"
            aria-label={txt.get("orders.order.attachments.download_all")}
          />
        ) : (
          <></>
        )}{" "}
        {props.previewAll ? (
          <EuiButtonIcon
            onClick={(e: any) => {
              handlePreviewAll();
            }}
            iconType="eye"
            size="s"
            aria-label={txt.get("orders.order.attachments.preview_all")}
          />
        ) : (
          <></>
        )}
      </EuiFlexGroup>{" "}
      {renderPreviewAll()}
    </Fragment>
  );
}

export default MMFiles;
