import ConnectAPIHelper from "api/connect-api-helper";
import APIHelper, { ApiResponse, ApiResponseStatus } from "api/api-helper";
import { fileOpen } from "browser-fs-access";
import AuthenticationHelper from "./authentication-helper";

export interface FileResult {
  file?: File;
  content: string;
}

export enum FileType {
  Text = "text/*",
  Image = "image/*",
  Pdf = "application/pdf",
  Docx = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  Xlsx = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
}

class FileUploadHelper {
  accessToken: string = AuthenticationHelper.getToken();

  api: APIHelper = new ConnectAPIHelper();

  setApi(api: APIHelper) {
    this.api = api;
  }

  fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result as string;
        const base64String = result.replace("data:", "").replace(/^.+,/, "");
        resolve(base64String);
      };
      reader.onerror = () => {
        reject("error reading file");
      };
      reader.readAsDataURL(file);
    });
  }

  async select(
    fileSelectionInfo: any,
    multiple: boolean = false,
    types?: FileType[]
  ): Promise<FileResult[] | string> {
    //ask the file
    let options: any = {};
    options.mimeTypes =
      types && types.length > 0 ? types : Object.values(FileType);
    options.multiple = multiple;
    options.capture = "environment";

    try {
      if (fileSelectionInfo) fileSelectionInfo("selecting");
      let files: any = await fileOpen(options);
      if (files && !Array.isArray(files)) {
        files = [files];
      }
      if (files && files.length > 0 && fileSelectionInfo)
        fileSelectionInfo("uploading");

      let result: FileResult[] = [];
      for (let i = 0; i < files.length; i++) {
        const file: File = files[i];
        result.push({ file, content: await this.fileToBase64(file) });
      }
      return result;
    } catch (error) {
      return "";
    }
  }

  async upload(
    uploadPath: string,
    uploadKey: string,
    uploadContent: string,
    options?: any
  ): Promise<any | string> {
    try {
      let content = uploadContent;
      let path: string = uploadPath;
      let params: any = {};
      let body: any = {};
      body[uploadKey] = content;
      body = { ...body, ...(options ?? {}) };

      console.log(body);

      this.api.setAccessToken(this.accessToken);

      const result: ApiResponse = await this.api.post(path, params, body);

      return new Promise((resolve, reject) => {
        if (result.status === ApiResponseStatus.OK) {
          return resolve(result.result);
        } else {
          return reject(result.message);
        }
      });
    } catch (error) {
      return "";
    }
  }

  async selectFiles(
    fileSelectionInfo: any,
    multiple: boolean = true,
    types?: FileType[]
  ): Promise<FileResult[] | string> {
    let fileResult: FileResult[] | string = await this.select(
      fileSelectionInfo,
      multiple,
      types
    );
    return fileResult;
  }

  uploadFiles(
    uploadPath: string,
    uploadKey: string,
    fileResults: FileResult[],
    options?: any
  ): Promise<any | string>[] {
    try {
      if (typeof fileResults == "string") {
        return [];
      }
      return fileResults.map((fileResult: FileResult) => {
        const filename = fileResult.file?.name;
        const file_type = fileResult.file?.type;
        return this.upload(uploadPath, uploadKey, fileResult.content, {
          filename,
          file_type,
          ...options,
        });
      });
    } catch (error) {
      return [];
    }
  }

  async uploadFile(
    uploadPath: string,
    uploadKey: string,
    fileSelectionInfo: any,
    options?: any
  ): Promise<any | string> {
    try {
      let fileResult: FileResult[] | string = await this.select(
        fileSelectionInfo
      );
      if (typeof fileResult == "string") {
        return "";
      }
      const filename = fileResult.length > 0 ? fileResult[0].file?.name : "";
      const file_type = fileResult.length > 0 ? fileResult[0].file?.type : "";
      options = { filename, file_type, ...options };
      return this.upload(
        uploadPath,
        uploadKey,
        fileResult.length > 0 ? fileResult[0].content : "",
        options
      );
    } catch (error) {
      return "";
    }
  }

  async triggerDownloadFileUrl(fileUrl: string, fileName: string) {
    const fileAsUrl = await this.downloadFileUrl(fileUrl);
    FileUploadHelper.triggerDownloadFile(fileAsUrl, fileName);
  }

  async downloadFileUrl(fileUrl: string) {
    const imageSrc = await this.api.getAssetByUrl(fileUrl, false);
    const fileAsUrl = URL.createObjectURL(imageSrc);
    return fileAsUrl;
  }

  static triggerDownloadFile(fileAsUrl: string, fileName: string) {
    const a = document.createElement("a");
    a.href = fileAsUrl;
    a.target = "_blank";
    a.download = fileName;
    a.click();
  }
}

export default FileUploadHelper;
