import {
  CriteriaWithPagination,
  EuiBasicTableColumn,
  EuiButtonIcon,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiInMemoryTable,
  EuiPanel,
  EuiSpacer,
  EuiText,
} from "@elastic/eui";
import ConnectAPIHelper from "api/connect-api-helper";
import MMCell from "components/layouts/table/cell";
import MMColumnFormatted from "components/layouts/table/column-formatted";
import MMMemoryTable from "components/layouts/table/memory-table";
import MMTitle from "components/layouts/title/title";
import DateHelper from "helpers/date-helper";
import txt from "helpers/text-helper";
import { useEffect, useState } from "react";
import { Client } from "store/data/client/client";
import { formalName } from "store/data/personal-details/personal-details";
import { renderCurrentProductionStep } from "store/data/production/production";

import { Workflow } from "store/data/production/workflow";
import { Hand, handDescription } from "store/data/scan/scan";

export interface MMProductionInfoProps {
  client: Client | null;
  appointments: any[];
  isEditable?: boolean;
  isHeaderShown?: boolean;
}

function MMAppointmentInfo(props: MMProductionInfoProps) {
  const api = new ConnectAPIHelper();

  const [client, setClient] = useState<Client>();
  const [appointments, setAppointments] = useState<any[]>([]);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isHeaderShown, setIsHeaderShown] = useState<boolean>(false);

  useEffect(() => {
    setIsEditable(!!props.isEditable);
    if (props.client) {
      setClient(props.client);
    } else {
      setClient(undefined);
    }
    if (props.appointments) {
      setAppointments(props.appointments);
    } else {
      setAppointments([]);
    }
  }, [props.appointments, props.client, props.isEditable, props.isHeaderShown]);

  const getColumns = (): Array<EuiBasicTableColumn<any>> => [
    {
      name: "",
      field: "appointment",
      render: (appointment: string) => <MMCell size="xs" text={appointment} />,
    },
    {
      name: "",

      field: "vlot_data.behandelaar",
      render: (practitioner_name: string, appointment: any) => (
        <MMCell
          align="center"
          size="xs"
          text={practitioner_name}
          subText={appointment.vlot_data?.locatie?.omschrijving || undefined}
        />
      ),
    },
    {
      name: "",
      field: "start",
      render: (start: Date, appointment: any) => (
        <MMCell
          align="center"
          size="xs"
          text={DateHelper.toDate(start)}
          subText={`${DateHelper.toTime(appointment.start)}`}
          // subText={`${DateHelper.toTime(appointment.start)}${
          //   appointment.end
          //     ? `, ${DateHelper.durationWorded(
          //         appointment.start,
          //         appointment.end
          //       )}`
          //     : ""
          // }`}
        />
      ),
    },
  ];

  return client ? (
    <EuiFlexGrid className="subtle-editing">
      <EuiFlexItem>
        <MMTitle
          text={txt.get("clients.communication_info.appointments.name")}
        />
        <EuiFlexGroup justifyContent="spaceBetween">
          <EuiText
            size="xs"
            style={{
              whiteSpace: "nowrap",
              fontWeight: "600",
              position: "relative",
              top: "14px",
              paddingBottom: "6px",
            }}
          >
            {txt.get("clients.communication_info.appointments.details")}
          </EuiText>
          <EuiButtonIcon
            aria-label="add"
            style={{
              whiteSpace: "nowrap",
              fontWeight: "600",
              position: "relative",
              top: "14px",
              paddingBottom: "6px",
            }}
            iconType={"plus"}
          />
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiPanel className="subtle-panel">
        <EuiFlexItem>
          <MMMemoryTable items={appointments} columns={getColumns()} />
        </EuiFlexItem>
      </EuiPanel>
    </EuiFlexGrid>
  ) : (
    <></>
  );
}

export default MMAppointmentInfo;
