class StringHelper {
  private static locale(lang?: string) {
    return lang === "de"
      ? "de-DE"
      : lang === "nl"
      ? "nl-NL"
      : lang === "en"
      ? "en-IN"
      : "nl-NL";
  }

  static ucwords(value: string): string {
    return value
      .split(" ")
      .map((word) => StringHelper.ucfirst(word))
      .join(" ");
  }

  static ucfirst(value: string): string {
    let result = value.toLowerCase();
    if (result.length > 1) {
      result = result.substring(0, 1).toUpperCase() + result.substring(1);
    } else if (result.length > 0) {
      result = result.toUpperCase();
    } else {
      result = value;
    }
    return result;
  }

  static upfirst(value: string): string {
    let result = value;
    if (result.length > 1) {
      result = result.substring(0, 1).toUpperCase() + result.substring(1);
    } else if (result.length > 0) {
      result = result.toUpperCase();
    } else {
      result = value;
    }
    return result;
  }
  static lofirst(value: string): string {
    let result = value;
    if (result.length > 1) {
      result = result.substring(0, 1).toLowerCase() + result.substring(1);
    } else if (result.length > 0) {
      result = result.toLowerCase();
    } else {
      result = value;
    }
    return result;
  }

  static endsWith(value: string, endValue: string) {
    return (
      value.lastIndexOf(endValue) > -1 &&
      value.length - value.lastIndexOf(endValue) === endValue.length
    );
  }

  static zeroPad(value: number | string, padLength: number = 2) {
    let result = (value + "").padStart(padLength, "0");
    return result;
  }

  static decimal(
    value: number | string,
    digits: number = 2,
    lang: string = "nl"
  ) {
    if (digits === 0) {
      return parseInt(Math.round(parseFloat(value + "")) + "");
    }
    let result = new Intl.NumberFormat(StringHelper.locale(lang), {
      style: "decimal",
      minimumFractionDigits: digits,
      maximumFractionDigits: digits,
    }).format(parseFloat(value + ""));
    return result;
  }
  static percentage(
    value: number | string,

    lang: string = "nl"
  ) {
    let result = new Intl.NumberFormat(StringHelper.locale(lang), {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(parseFloat(value + ""));
    return result;
  }

  static currency(
    value: number | string,
    currency: string = "EUR",
    lang: string = "nl"
  ) {
    let result = new Intl.NumberFormat(StringHelper.locale(lang), {
      style: "currency",
      currency,
    }).format(parseFloat(value + ""));

    // const pow: number = Math.max(1, Math.pow(10, decimals));
    // let decimalled = Math.round(parseFloat(value + "") * pow);
    // let padLength = (decimalled + "").length + 1;
    // let result =
    //   currencyString +
    //   " " +
    //   (decimalled / pow + "")
    //     .padEnd(padLength, "0")
    //     .replace(".", decimalSeparator);
    return result;
  }

  static toBase64(value: string): string {
    return Buffer.from(value).toString("base64");
  }

  static fromBase64(value: string): string {
    return Buffer.from(value, "base64").toString();
  }

  static slugify(value: string): string {
    return value
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, "")
      .replace(/[\s_-]+/g, "-")
      .replace(/^-+|-+$/g, "");
  }

  static capitalize(value: string): string {
    const result = this.snakify(value)
      .split("_")
      .map((word: string) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
      .join(" ");
    return result;
  }
  static snakify(value: string): string {
    const result = value
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, "")
      .replace(/[^\w\s,]/g, "")
      .replace(/[^\w\s.]/g, "")
      .replace(/[\s--]+/g, "_")
      .replace(/[\s -]+/g, "_")
      .replace(/^-+|-+$/g, "");

    return result;
  }

  static arrayUnique(values: string[], caseSensitive: boolean = false) {
    let uniqueValues = Object.fromEntries(
      values.map((s) => [caseSensitive ? s : s.toLowerCase(), s])
    );
    return Object.values(uniqueValues);
  }
}

export default StringHelper;
