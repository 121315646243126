import React, { Component, RefObject } from 'react';
import {Navigate, Link } from 'react-router-dom';

import MMPage from 'components/layouts/page/page';
import MMPopover, {MMPopoverType} from 'components/layouts/popover/popover';

import { connect, ConnectedProps } from "react-redux";
import { User, userClear, userSave } from "store/data/user/user";
import { EuiLoadingSpinner, EuiSpacer, EuiText } from '@elastic/eui';

import AuthenticationHelper from 'helpers/authentication-helper';
import txt from 'helpers/text-helper';

const storeMap = () => ({
    user: AuthenticationHelper.getUserInfo(),
    isLoggedIn: AuthenticationHelper.isAuthenticated(),
});

const storeDispatch = { userClear, userSave };
const storeConnectedComponent = connect(storeMap,storeDispatch);
type StoreProps = ConnectedProps<typeof storeConnectedComponent>;

enum MMLoginStatus {
    LoggedOut,
    LoggingIn,
    LoggedIn,
    InvalidCredentials,
};

interface MMLoginProps extends StoreProps {
    location?:string;
}

interface MMLoginState {
    status: MMLoginStatus;
    message?: string;
}

class MMLogin extends Component<MMLoginProps,MMLoginState> {

    private loginButtonRef: RefObject<HTMLDivElement>;
    
    constructor(props: any) {
        super(props);     
        console.log('login want to redirect to',props.location);
        this._onLoginSuccess = this._onLoginSuccess.bind(this);
        this._onLoginFailure = this._onLoginFailure.bind(this);
        this._onPopoverDone = this._onPopoverDone.bind(this);
        this._onLoginClick = this._onLoginClick.bind(this);

        this.loginButtonRef = React.createRef();
        this.state = {
            status:MMLoginStatus.LoggedOut
        };

    }    

    componentDidMount() {
        AuthenticationHelper.promptForLogin(this.props.location ?? '/');
    }

    _onLoginSuccess(user:User) {
        console.log('_onLoginSuccess',user);
        this.setState({status:MMLoginStatus.LoggedIn});
    }

    _onLoginFailure(message:string) {
        console.log('_onLoginFailure',message);
        this.setState({status:MMLoginStatus.InvalidCredentials,message});
    }

    _onPopoverDone() {
        console.log('_onPopoverDone');
        this.setState({status:MMLoginStatus.LoggedOut,message:''})
    }

    _onLoginClick() {
        console.log('_onLoginClick');
        AuthenticationHelper.promptForLogin('/');
        this.setState({status:MMLoginStatus.LoggingIn,message:'logging in...'})
    }

    _getStatusClass(): string {
        let result = '';
        switch(this.state.status) {
            case MMLoginStatus.LoggedOut :
                result = 'logged-out';
                break;
            case MMLoginStatus.LoggingIn :
                result = 'logging-in';
                break;
            case MMLoginStatus.LoggedIn :
                result = 'logged-in';
                break;
            case MMLoginStatus.InvalidCredentials :
                result = 'invalid-credentials';
                break;
        }
        return result;
    }
    
    render(): JSX.Element {
        return (
            <MMPage title={txt.get('login.login')} className={this._getStatusClass()}>
                { this.state.message
                  ?
                  <MMPopover title={txt.get('login.login')} content={this.state.message} type={MMPopoverType.Alert} onDone={this._onPopoverDone}/>
                  :
                  null
                }
                { this.props.isLoggedIn
                  ?
                    <Navigate to="/" replace={true} />
                  :
                    <EuiText>
                        <EuiLoadingSpinner/> {txt.get('login.redirecting')}
                    </EuiText>
               }
               <EuiSpacer/>
                <Link to="/">{txt.get('generic.back_to',txt.get('dashboard.page_title'))}</Link>
            </MMPage>
        );
    }
}


  
export default storeConnectedComponent(MMLogin);
