import { Order, OrderLine } from "store/data/order/order";
import {
  Page,
  Text,
  View,
  Document,
  PDFViewer,
  Font,
  Image,
} from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import fontFuturaPT from "./assets/fonts/futura-pt.ttf";
import fontFuturaPTBold from "./assets/fonts/futura-pt-bold.ttf";
import {
  Addressee,
  addressee,
  formattedAddressParts,
} from "store/data/adress/address";
import { A4_H_5, A4_P_S, A4_W_10, style } from "./pdf-params";
import txt from "helpers/text-helper";
import DateHelper from "helpers/date-helper";
import StringHelper from "helpers/string-helper";
import {
  OrderCostingType,
  costingTotal,
  toCostType,
} from "store/data/costing/costing";
import {
  IndicationGrouped,
  IndicationSide,
  indicationsGrouped,
} from "store/data/indication/indication";
import { handDescription } from "store/data/scan/scan";
import { Client } from "store/data/client/client";
import { UzoviCode } from "store/data/insurer/insurer";
import { formalName } from "store/data/personal-details/personal-details";
import {
  isNight,
  isRepeat,
  isSpare,
  toAGPHDescription,
  toAPGHForOrderLine,
} from "store/data/contract/contract";

const VAT_LOW: number = 0.09;
const VAT_HIGH: number = 0.21;
const VAT_ZERO: number = 0;

// 21 - aanschaf hulpmiddel in eigendom
// 23 - reparatie hulpmiddel in eigendom
// 24 - aanpassingen hulpmiddel in eigendom

export interface MMPdfQuotationProps {
  order: Order;
  client: Client;
  uzoviCode?: UzoviCode;
  insuranceDetails?: any;
}

function MMPdfQuotation(props: MMPdfQuotationProps) {
  const [order, setOrder] = useState<Order>(props.order);
  const [client, setClient] = useState<Client>(props.client);
  const [uzoviCode, setUzoviCode] = useState<UzoviCode | undefined>(
    props.uzoviCode
  );

  const [insuranceDetails, setInsuranceDetails] = useState<any>(
    props.insuranceDetails
  );

  useEffect(() => {
    setOrder(props.order);
    setClient(props.client);
    setInsuranceDetails(props.insuranceDetails);
    setUzoviCode(props.uzoviCode);
  }, [props.order, props.client, props.insuranceDetails, props.uzoviCode]);

  Font.register({
    family: "futura-pt",

    fonts: [{ src: fontFuturaPT }, { src: fontFuturaPTBold, fontWeight: 600 }],
  });

  const styles = style({
    font: {
      fontFamily: "futura-pt",
      fontSize: "10pt",
    },
    title: {
      fontWeight: 600,
      fontSize: "14pt",
    },
    subtitle: {
      fontWeight: 600,
      fontSize: "10pt",
    },
    small: {
      fontSize: "9pt",
    },
    soft: {
      color: "#777777",
    },
    logo: {
      width: "120pt",
      height: "18pt",
      objectFit: "contain",
    },
    logoFooter: {
      width: "80pt",
      height: "12pt",
      objectFit: "contain",
    },
    addressPaneHolder: {
      height: A4_H_5,
      width: A4_W_10,
      padding: A4_P_S,
      justifyContent: "center",
      // borderWidth: 1,
    },
    addressPane: {
      // flexGrow: 1,
    },
    p: {
      padding: `${A4_P_S} 0pt`,
    },
  });

  const personalAddress = (client: Client) => {
    return (
      <View style={styles.addressPane}>
        <Text style={{ color: "#909090" }}>
          {txt.get("pdfs.quotation.to")}:
        </Text>
        <Text>{formalName(client)}</Text>
        <Text>
          {formattedAddressParts(client, [
            "street_name",
            "street_number",
            "street_number_suffix",
          ])}
        </Text>
        <Text>{formattedAddressParts(client, ["postal_area", "city"])}</Text>
      </View>
    );
  };
  const insurerAddress = (uzoviCode: UzoviCode) => {
    let uzoviCodeAddressee: Addressee | null = addressee(
      uzoviCode,
      "financial"
    );

    return (
      <View style={styles.addressPane}>
        <Text style={{ color: "#909090" }}>
          {txt.get("pdfs.quotation.to")}:
        </Text>
        {uzoviCode.insurer?.name ? (
          <Text>{uzoviCode.insurer.name}</Text>
        ) : (
          <></>
        )}
        {uzoviCode.name ? <Text>{uzoviCode.name}</Text> : <></>}

        {uzoviCodeAddressee ? (
          <Text>
            {formattedAddressParts(uzoviCodeAddressee.address, [
              "street_name",
              "street_number",
              "street_number_suffix",
            ])}
          </Text>
        ) : (
          <></>
        )}

        {uzoviCodeAddressee ? (
          <Text>
            {formattedAddressParts(uzoviCodeAddressee.address, [
              "postal_area",
              "city",
            ])}
          </Text>
        ) : (
          <></>
        )}
        {uzoviCodeAddressee ? (
          <Text>
            {formattedAddressParts(uzoviCodeAddressee.address, ["country"])}
          </Text>
        ) : (
          <></>
        )}
      </View>
    );
  };
  const header = (title: string, withLogo: boolean = true) => (
    <View fixed={true} style={styles.header}>
      <View style={styles.columns}>
        <View style={styles.section_8}>
          {withLogo ? (
            <Image style={styles.logo} src="/pdf-assets/logo-manometric.png" />
          ) : (
            <></>
          )}
        </View>
        <View style={styles.section_4}>
          <Text style={{ ...styles.title, textAlign: "right" }}>{title}</Text>
        </View>
      </View>
    </View>
  );

  const footer = () => (
    <View fixed={true} style={[styles.footer, styles.small, styles.soft]}>
      <View
        style={{
          ...styles.columns,
          borderTopWidth: "0.25pt",
          borderTopColor: "#909090",

          alignItems: "flex-end",
        }}
      >
        <View style={styles.section_3}>
          {/* <Text style={styles.small}>{txt.get('brand.Manometric B.V.')}</Text> */}
          <Text>{txt.get("brand.address_line_1")}</Text>
          <Text>{txt.get("brand.address_line_2")}</Text>
        </View>
        <View style={styles.section_3}>
          <Text>{txt.get("brand.phone")}</Text>
          <Text>{txt.get("brand.email")}</Text>
        </View>
        <View style={styles.section_3}>
          <View style={{ flexDirection: "row" }}>
            <View style={{ width: "24pt" }}>
              <Text>{txt.get("pdfs.footer.agb_code")}:</Text>
            </View>
            <View>
              <Text>{txt.get("brand.agb_code")}</Text>
            </View>
          </View>
          <View style={{ flexDirection: "row" }}>
            <View style={{ width: "24pt" }}>
              <Text>{txt.get("pdfs.footer.coc_number")}:</Text>
            </View>
            <View>
              <Text>{txt.get("brand.coc_number")}</Text>
            </View>
          </View>
        </View>

        <View style={styles.section_3}>
          <View
            style={{
              flexGrow: 1,
              alignItems: "flex-end",
              justifyContent: "center",
              height: "26pt",
            }}
          >
            <Image
              style={styles.logoFooter}
              src="/pdf-assets/logo-manometric-grey.png"
            />
          </View>
        </View>
      </View>
      <View style={{ alignItems: "flex-end", paddingRight: A4_P_S }}>
        <Text
          style={styles.small}
          render={({ pageNumber, totalPages }) => `${pageNumber}/${totalPages}`}
        />
      </View>
    </View>
  );

  const page = (body: any) => (
    <Page size="A4" style={[styles.page, styles.font]}>
      {header(txt.up("pdfs.quotation.name"))}
      <View style={styles.body}>{body}</View>
      {footer()}
    </Page>
  );

  const productDetails = (orderLines: OrderLine[]) => (
    <View style={{ paddingTop: "10pt" }}>
      <Text style={styles.subtitle}>{txt.get("orders.order.products")}</Text>
      {orderLines.map((orderLine: OrderLine) => (
        <View>
          <Text>
            {orderLine.product_selection.type} (
            {handDescription(orderLine.hand as string).toLowerCase()})
            {orderLine.digits && orderLine.digits.length > 0
              ? `
${txt.get("pdfs.quotation.fingers")}: ${orderLine.digits.join(", ")}`
              : ""}
            {/* {orderLine.product_selection.size &&
            !["n/a"].includes(orderLine.product_selection.size)
              ? `${orderLine.product_selection.size
                  .toString()
                  .replace(/ /g, "")} `
              : ""}
            {orderLine.product_selection.color} */}
          </Text>
          <View style={[styles.columns, styles.soft]}>
            <View
              style={[
                styles.section_3,
                { paddingLeft: "0pt", paddingTop: "0pt" },
              ]}
            >
              <Text>
                {txt.get("pdfs.quotation.use")}:{" "}
                {isNight(orderLine)
                  ? txt.get("orders.order.use_option.pdf_quotation.night")
                  : !isNight(orderLine) && isRepeat(orderLine)
                    ? txt.get("pdfs.quotation.repeat_product")
                    : isSpare(orderLine)
                      ? `, ${txt.get("pdfs.quotation.spare_product")}`
                      : txt.get("orders.order.use_option.pdf_quotation.day")}
              </Text>
            </View>
          </View>
        </View>
      ))}
    </View>
  );
  const orderLines = (orderLines: OrderLine[]) => (
    <View style={styles.table}>
      <View style={{ ...styles.tableHeader, ...styles.columns }}>
        <View style={styles.section_2}>
          <Text>{txt.get("pdfs.quotation.code")}</Text>
          <Text style={styles.soft}>{txt.get("pdfs.quotation.product")}</Text>
        </View>
        <View style={styles.section_6}>
          <Text>{txt.get("pdfs.quotation.description")}</Text>
          <Text style={styles.soft}>
            {txt.get("pdfs.quotation.cost_codes")}
          </Text>
        </View>
        <View style={styles.section_2}>
          <Text style={{ textAlign: "right" }}>
            {txt.get("pdfs.quotation.amount")}
          </Text>
          <Text style={[{ textAlign: "right" }, styles.soft]}>
            {txt.lo("pdfs.quotation.vat_inclusive")}{" "}
            {txt.lo("pdfs.quotation.vat")}
          </Text>
        </View>
        <View style={styles.section_2}>
          <Text style={{ textAlign: "right" }}>
            {txt.get("pdfs.quotation.sub_total")}
          </Text>
          <Text style={[{ textAlign: "right" }, styles.soft]}>
            {txt.lo("pdfs.quotation.vat_inclusive")}{" "}
            {txt.lo("pdfs.quotation.vat")}
          </Text>
        </View>
      </View>
      {orderLines.map((orderLine: OrderLine, i: number) => (
        <View>
          <View
            style={[
              orderLine.costing?.amount_effort
                ? styles.tableRowBetween
                : styles.tableRow,
              styles.columns,
              styles.small,
            ]}
          >
            <View
              style={[
                styles.section_2,
                orderLine.costing?.amount_effort
                  ? { paddingBottom: "0pt" }
                  : {},
              ]}
            >
              <View>
                <Text>
                  {order.costing?.type === OrderCostingType.InvoicePrivate
                    ? orderLine.code
                    : orderLine.costing?.contract?.insurer_product_code}
                </Text>
              </View>
              <View>
                <Text style={[styles.small, styles.soft]}>
                  {order.costing?.type === OrderCostingType.InvoicePrivate
                    ? ""
                    : orderLine.costing?.contract
                        ?.product_declaration_description}
                </Text>
              </View>
              {orderLine.costing?.amount_effort ? (
                <View>
                  <Text>
                    {order.costing?.type === OrderCostingType.InvoicePrivate
                      ? ""
                      : orderLine.costing?.contract?.hourly_code}
                  </Text>
                </View>
              ) : (
                <></>
              )}
            </View>
            <View
              style={[
                styles.section_6,
                orderLine.costing?.amount_effort
                  ? { paddingBottom: "0pt" }
                  : {},
              ]}
            >
              <View>
                <Text>
                  {order.costing?.type === OrderCostingType.InvoicePrivate
                    ? orderLine.product_selection.type.toUpperCase()
                    : orderLine.costing?.description
                    ? orderLine.costing?.description?.toUpperCase()
                    : orderLine.costing?.contract?.invoice_line_description?.toUpperCase()}
                  {" - "}
                  {handDescription(
                    orderLine.hand?.toString() || ""
                  ).toUpperCase()}
                  {orderLine.digits && orderLine.digits.length > 0
                    ? ` (${orderLine.digits.join(",")})`
                    : ""}
                </Text>
              </View>
              <View>
                {order.costing?.type === OrderCostingType.InvoicePrivate ? (
                  <Text style={[styles.small, styles.soft]}></Text>
                ) : (
                  <Text style={[styles.small, styles.soft]}>
                    {txt.up("pdfs.quotation.gph")}:{" "}
                    {orderLine.costing?.contract?.product_declaration_code}
                    {orderLine.costing?.contract
                      ?.product_declaration_additional_code1
                      ? `, ${txt.up(
                          "pdfs.quotation.a_gph_1"
                        )}: ${toAPGHForOrderLine(orderLine)}`
                      : ""}
                    {orderLine.costing?.contract
                      ?.product_declaration_additional_code2
                      ? `, ${txt.up("pdfs.quotation.a_gph_2")}: ${
                          orderLine.costing?.contract
                            ?.product_declaration_additional_code2
                        }`
                      : ""}
                    , {txt.up("pdfs.quotation.cost_type")}:
                    {toCostType(orderLine)}
                  </Text>
                )}
              </View>
            </View>
            <View
              style={[
                styles.section_2,
                { justifyContent: "center" },
                orderLine.costing?.amount_effort
                  ? { paddingBottom: "0pt" }
                  : {},
              ]}
            >
              <Text style={{ textAlign: "right" }}>
                {orderLine.costing?.amount
                  ? StringHelper.currency(orderLine.costing?.amount)
                  : "-"}
              </Text>
            </View>
            <View
              style={[
                styles.section_2,
                { justifyContent: "center" },
                orderLine.costing?.amount_effort
                  ? { paddingBottom: "0pt" }
                  : {},
              ]}
            >
              <Text style={{ textAlign: "right" }}>
                {orderLine.costing?.amount
                  ? StringHelper.currency(
                      orderLine.costing?.amount +
                        (orderLine.costing?.amount_effort || 0)
                    )
                  : "-"}
              </Text>
            </View>
          </View>
          {orderLine.costing?.amount_effort ? (
            <View>
              <View style={[styles.tableRow, styles.columns, styles.small]}>
                <View style={styles.section_2}>
                  {orderLine.costing?.amount_effort ? (
                    <View>
                      <Text>
                        {i === 0
                          ? orderLine.costing?.contract
                              ?.measuring_fee_first_code
                          : orderLine.costing?.contract
                              ?.measuring_fee_consecutive_code}
                      </Text>
                    </View>
                  ) : (
                    <></>
                  )}
                </View>
                <View style={styles.section_6}>
                  <View>
                    <Text>
                      {txt.up("pdfs.quotation.measure")}
                      {/* {order.costing?.type === OrderCostingType.InvoicePrivate
                        ? txt.up("pdfs.quotation.measure")
                        : orderLine.costing?.description_effort?.toUpperCase()} */}
                    </Text>
                  </View>
                </View>
                <View style={{ ...styles.section_2, justifyContent: "center" }}>
                  <Text style={{ textAlign: "right" }}>
                    {orderLine.costing?.amount_effort
                      ? StringHelper.currency(orderLine.costing?.amount_effort)
                      : "-"}
                  </Text>
                </View>
                <View style={{ ...styles.section_2, justifyContent: "center" }}>
                  <Text style={{ textAlign: "right" }}></Text>
                </View>
              </View>
            </View>
          ) : (
            <></>
          )}
        </View>
      ))}
      <View style={[styles.tableFooter, styles.columns]}>
        <View style={{ ...styles.section_10, alignItems: "flex-end" }}>
          <Text style={{ textAlign: "right" }}>
            {txt.get("pdfs.quotation.total")}{" "}
            {txt.lo("pdfs.quotation.vat_exclusive")}{" "}
            {txt.lo("pdfs.quotation.vat")} :
          </Text>
          <Text style={{ textAlign: "right" }}>
            {txt.lo("pdfs.quotation.vat")} ({StringHelper.percentage(VAT_LOW)}):
          </Text>
          <Text style={{ textAlign: "right" }}>
            {txt.get("pdfs.quotation.total")}{" "}
            {txt.lo("pdfs.quotation.vat_inclusive")}{" "}
            {txt.lo("pdfs.quotation.vat")}:
          </Text>
        </View>
        <View style={styles.section_2}>
          <Text style={{ textAlign: "right" }}>
            {StringHelper.currency(costingTotal(orderLines, VAT_LOW))}
          </Text>
          <Text style={{ textAlign: "right" }}>
            {StringHelper.currency(costingTotal(orderLines, VAT_LOW, true))}
          </Text>
          <Text style={{ textAlign: "right" }}>
            {StringHelper.currency(costingTotal(orderLines))}
          </Text>
        </View>
      </View>
    </View>
  );

  const markdownToPdfText = (value: string) => {
    let lines: string[] = value.split("\n");
    return lines.map((line: string) => (
      <View>
        <Text style={line.search(/\*\*/) >= 0 ? { fontWeight: "bold" } : {}}>
          {line.replace(/\*\*/g, "\n")}
        </Text>
      </View>
    ));
    // .RegexReplace('\*\*([^*]+)\*\*','<b>$1</b>')

    // return value.replace(/\*\*([^*]+)\*\*/g,"value);
  };

  return (
    <PDFViewer height={"100%"}>
      <Document>
        {page(
          <View>
            <View style={styles.addressPaneHolder}>
              {order.costing?.type === OrderCostingType.InvoicePrivate
                ? personalAddress(client)
                : uzoviCode
                ? insurerAddress(uzoviCode)
                : "-"}
            </View>
            <View style={{ ...styles.columns, padding: `${A4_P_S}` }}>
              <View style={{ ...styles.section_2, padding: "0pt" }}>
                <Text>{txt.get("pdfs.quotation.name")}:</Text>
                <Text>{txt.get("pdfs.quotation.date")}:</Text>
                {order.costing?.type === OrderCostingType.InvoicePrivate ? (
                  <></>
                ) : (
                  <Text>{txt.get("pdfs.quotation.our_reference")}:</Text>
                )}
              </View>
              <View style={{ ...styles.section_4, padding: "0pt" }}>
                <Text>{order.costing?.quotation?.code || "PRO FORMA"}</Text>
                <Text>
                  {DateHelper.toDateFullYear(
                    order.referral?.intake_at || undefined
                  )}
                </Text>
                {order.costing?.type === OrderCostingType.InvoicePrivate ? (
                  <></>
                ) : (
                  <Text>
                    {txt.get("pdfs.quotation.agb_code")}{" "}
                    {txt.get("brand.agb_code")}{" "}
                  </Text>
                )}
              </View>
            </View>
            <View style={styles.columns}>
              <View style={styles.section_6}>
                <View style={styles.columns}>
                  <View
                    style={{
                      ...styles.section_2,
                      paddingLeft: "0pt",
                      paddingTop: "0pt",
                    }}
                  >
                    <Text>{txt.get("pdfs.quotation.regarding")}:</Text>
                  </View>
                  {order.costing?.type === OrderCostingType.InvoicePrivate ? (
                    <View
                      style={{
                        ...styles.section_4,
                        paddingLeft: "0pt",
                        paddingTop: "0pt",
                      }}
                    >
                      <Text>
                        {order.costing?.notes ||
                          txt.get("pdfs.quotation.private")}
                      </Text>
                    </View>
                  ) : (
                    <View
                      style={{
                        ...styles.section_4,
                        paddingLeft: "0pt",
                        paddingTop: "0pt",
                      }}
                    >
                      <Text>{formalName(client)}</Text>
                      <Text>
                        {formattedAddressParts(client, [
                          "street_name",
                          "street_number",
                          "street_number_suffix",
                        ])}
                      </Text>
                      <Text>
                        {formattedAddressParts(client, ["postal_area", "city"])}
                      </Text>
                    </View>
                  )}
                </View>
              </View>

              <View style={styles.section_6}>
                <View style={styles.columns}>
                  <View
                    style={{
                      ...styles.section_3,
                      paddingLeft: "0pt",
                      paddingTop: "0pt",
                    }}
                  >
                    <Text>{txt.get("pdfs.quotation.client_code")}:</Text>
                    {order.costing?.type === OrderCostingType.InvoicePrivate ? (
                      <></>
                    ) : (
                      <Text>{txt.get("pdfs.quotation.birth_date")}:</Text>
                    )}

                    {order.costing?.type === OrderCostingType.InvoicePrivate ? (
                      <></>
                    ) : (
                      <Text>
                        {txt.get("pdfs.quotation.citizen_service_number")}:
                      </Text>
                    )}
                    {order.costing?.type === OrderCostingType.InvoicePrivate ? (
                      <></>
                    ) : (
                      <Text>
                        {txt.get(
                          "pdfs.quotation.insurance_registration_number"
                        )}
                        :
                      </Text>
                    )}
                  </View>
                  <View
                    style={{
                      ...styles.section_3,
                      paddingLeft: "0pt",
                      paddingTop: "0pt",
                    }}
                  >
                    <Text>{order.client_code}</Text>
                    {order.costing?.type === OrderCostingType.InvoicePrivate ? (
                      <></>
                    ) : (
                      <Text>
                        {client.birth_date
                          ? DateHelper.toDateFullYear(client.birth_date)
                          : "-"}
                      </Text>
                    )}
                    {order.costing?.type === OrderCostingType.InvoicePrivate ? (
                      <></>
                    ) : (
                      <Text>{client.bsn || "-"}</Text>
                    )}
                    {order.costing?.type === OrderCostingType.InvoicePrivate ? (
                      <></>
                    ) : (
                      <Text>{insuranceDetails.registration_number || "-"}</Text>
                    )}
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.spacer} />
            {orderLines(order.order_lines)}
            <View style={styles.spacer} />
            <View break style={styles.columns}>
              <View style={styles.section_9}>
                <Text style={[styles.title]}>
                  {txt.get("pdfs.quotation.rationale")}
                </Text>
                <View>{markdownToPdfText(order.costing?.rationale || "")}</View>
              </View>
              <View
                style={[
                  styles.section_3,
                  { borderLeft: "0.5pt", borderLeftColor: "#777777" },
                ]}
              >
                <Text style={[styles.subtitle, { paddingTop: "5pt" }]}>
                  {txt.get("pdfs.quotation.indication")}
                </Text>
                <Text>
                  {order.referral?.indications
                    ? indicationsGrouped(order.referral.indications).map(
                        (indication: IndicationGrouped) => (
                          <Text>
                            {indication.indication}{" "}
                            {indication.sides
                              .map((side: IndicationSide) =>
                                handDescription(side)
                              )
                              .join(" & ")
                              .toLowerCase()}
                          </Text>
                        )
                      )
                    : "-"}
                </Text>
                <Text style={[styles.subtitle, { paddingTop: "10pt" }]}>
                  {txt.get("pdfs.quotation.referrer")}
                </Text>
                <Text>
                  {order.referral?.referrer?.person
                    ? formalName(order.referral.referrer.person)
                    : ""}
                </Text>
                {order.referral?.referrer?.agb_code ? (
                  <Text style={styles.soft}>
                    {txt.get("pdfs.quotation.agb_code")}{" "}
                    {order.referral?.referrer?.agb_code}
                  </Text>
                ) : (
                  <></>
                )}
                <Text>
                  {order.referral?.referrer?.organisation
                    ? order.referral.referrer.organisation.name
                    : order.referral?.referrer?.alternative_organisation
                    ? order.referral?.referrer?.alternative_organisation
                    : ""}
                </Text>
                {order.referral?.referrer?.organisation_agb_code ? (
                  <Text style={styles.soft}>
                    {txt.get("pdfs.quotation.agb_code")}{" "}
                    {order.referral?.referrer?.organisation_agb_code}
                  </Text>
                ) : (
                  <></>
                )}

                {productDetails(order.order_lines)}
              </View>
            </View>
          </View>
        )}
      </Document>
    </PDFViewer>
  );
}

export default MMPdfQuotation;
