import { v4 as uuidv4 } from 'uuid';

class RandomHelper {

    static cssid() {
        const result = 'id' + uuidv4();
        return result;
    }
  
  }
  
  export default RandomHelper ;
  