import txt from "helpers/text-helper";

export enum Sex {
  Male = "MALE",
  Female = "FEMALE",
  Unknown = "UNKNOWN",
}

export interface Client {
  id: number | null;
  client_code: number | null;
  bsn: string;
  initials: string;
  infix: string;
  first_name: string;
  last_name: string;
  sex: Sex;
  birth_date: Date;
  email: string;
  phone_number: string;
  postal_code: string; //todo, align with postal_area of address
  street_name: string;
  street_number: string;
  street_number_suffix: string;
  city: string;
  country: string;
  vlot_id: number | null;
  vlot_client_code: number | null;
  vlot_info?: any;
}

export const clientAddressLine = (client: Client) => {
  let result = "";
  if (client.street_name) {
    result += client.street_name + " ";
  }
  if (client.street_number) {
    result += client.street_number + " ";
  }
  if (client.street_number_suffix) {
    result += client.street_number_suffix + " ";
  }
  result = result.trim();

  return result;
};

export const clientAddressing = (client: Client) => {
  let result: string = "";
  if (client.sex) {
    switch (client.sex) {
      case Sex.Male:
        result = txt.get("clients.sex.addressing_short_male");
        break;
      case Sex.Female:
        result = txt.get("clients.sex.addressing_short_female");
        break;
    }
  }
  return result;
};
export const clientName = (client: Client, withAdressing: boolean = false) => {
  let result: string = "";
  if (withAdressing) {
    result += clientAddressing(client);
    if (result) {
      result += " ";
    }
  }
  if (client.initials) {
    result += client.initials + " ";
  } else if (client.first_name) {
    result += client.first_name + " ";
  }

  if (client.infix) {
    result += client.infix + " ";
  }

  if (client.last_name) {
    result += client.last_name;
  }
  result = result.trim();
  return result;
};
